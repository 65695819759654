@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  .label {
    display: flex;

    .hint {
      display: inline-flex;
      margin-left: 0.45em;
      height: 15px;
      width: 15px;
    }
  }

  .list {
    @include tiny-scrollbar();
    @include error-border;

    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 10px;
    overflow: auto;

    @include make-responsive(
      'padding',
      (
        xxl: 20px,
        xxxl: 30px,
      ),
      30px
    );

    &.empty {
      border-color: $gray-blue;
    }

    .item {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .placeholder {
    height: 100%;

    .image {
      @include make-responsive(
        'margin',
        (
          xxl: 35px,
          xxxl: 55px,
        ),
        55px
      );
    }
  }
}

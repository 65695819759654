@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1;
  flex: 1;
  margin-bottom: 40px;
}

.header {
  margin-bottom: 5px;
}

.closeButton {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  user-select: none;
  z-index: 100;

  @include max-w(xs) {
    top: 20px;
    right: 20px;
  }
}

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1;
  flex: 1;

  @include max-w(xs) {
    max-width: calc(100% - 20px);
  }
}

.title {
  @include make-responsive(
    'font-size',
    (
      xs: 32px,
      xxl: 48px,
    ),
    32px
  );

  text-align: center;
  margin: 1em auto 0.5em;
  font-weight: 600;
  color: $midnight-blue;
  text-align: center;

  @include max-w(xs) {
    margin: 40px auto 0.5em;
  }
}

.result {
  padding-bottom: 20px;
  color: $gray-suit;

  &:not(:first-of-type) {
    border-top: 1px solid $gray-blue;
    padding-top: 30px;

    @include max-w(xs) {
      padding-top: 20px;
    }
  }
}

.hint {
  font-size: 16px;
  color: $error-red;
  line-height: 1.15;

  @include max-w(xs) {
    max-width: inherit;
    width: 100%;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.status {
  .statusDot {
    @include make-responsive(
      'height',
      (
        xs: 8px,
        xxl: 10px,
        xxxl: 13px,
      ),
      13px
    );
    @include make-responsive(
      'width',
      (
        xs: 8px,
        xxl: 10px,
        xxxl: 13px,
      ),
      13px
    );

    background-color: $white;
    border-radius: 50%;
    display: inline-block;
  }

  .statusLabel {
    @include make-responsive(
      'margin-left',
      (
        xs: 5px,
        xxl: 10px,
      ),
      10px
    );
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;

  flex-flow: column;
  max-height: 100%;
  flex: 1;
}

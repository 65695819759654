.container {
  display: flex;
  align-items: center;
  width: 100%;

  button {
    flex: 1;

    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
}

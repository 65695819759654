@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;
  @include make-fluid('padding-right', 75px, 300px, get-breakpoint-value(xl), get-breakpoint-value(xxxl), 300px, px);

  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 50px;
}

@import '../../variables';
@import '../../styles/common/mixins';

.zapierContainer {
  display: flex;
  flex-flow: column;

  .label {
    @include primary-font(
      (
        xs: 22px,
        xxxl: 24px,
      )
    );

    margin-bottom: 20px;
    font-weight: 500;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include make-responsive(
    'padding',
    (
      xs: 20px,
      xxxl: 30px,
    ),
    30px
  );

  background: $white;
  border-radius: 10px;
  display: flex;
  flex-flow: column;

  @include max-w(xxl) {
    padding: 20px 30px;
  }

  @include max-w(xs) {
    padding: 20px 15px;
  }

  &.inline {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    padding: 30px 20px;
    border: 0.5px solid #bbc2d3;
    border-radius: 10px;

    .top,
    .middle,
    .bottom {
      margin: unset;
    }
  }
}

.label {
  @include make-responsive(
    'font-size',
    (
      xxl: 14px,
      xxxl: 16px,
    ),
    21px
  );

  @include make-responsive(
    'right',
    (
      xs: 16px,
      xxxl: 20px,
    ),
    21px
  );

  position: absolute;
  top: 0;
  transform: translateY(-50%);
  padding: 0 10px;
  color: $gray-suit;
  background-color: $white;
  line-height: 1.15;

  @include max-w(xxl) {
    padding: 0 7px;
  }
}

.top {
  display: flex;
  align-items: center;
}

.middle {
  display: flex;
  align-items: center;

  @include make-fluid('margin-top', 15px, 27px, get-breakpoint-value(md), get-breakpoint-value(xxl), 27px, px);

  @include max-w(xs) {
    margin-top: 5px;
  }
}

.bottom {
  display: flex;
  align-items: center;

  @include make-fluid('margin-top', 15px, 40px, get-breakpoint-value(md), get-breakpoint-value(xxl), 40px, px);

  @include max-w(xs) {
    margin-top: 5px;
  }
}

.btn {
  cursor: pointer;
  outline: none;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.formWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  @include heading-font();

  margin-bottom: 10px;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: row wrap;
  margin-bottom: 10px;

  &.errorBorder {
    @include error-border;
  }
}

.input {
  max-width: 49%;
  margin: 10px 0;

  &.fullWidth {
    max-width: inherit;
  }

  @include max-w(xs) {
    max-width: 100%;
  }
}

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;

  @include max-w(xs) {
    overflow-y: auto;
    padding: 0;
  }
}

.cancelButton {
  background-color: transparent;
  color: RGB($default-accent-color);

  &:hover {
    box-shadow: none;
    opacity: 0.7;
  }
}

.inputField {
  border: 1px solid $gray-suit;
}

.fullWidth {
  width: 100%;
  margin: 20px 0;
}

.errorBorder {
  @include error-border;
}

.selectContainer {
  width: 100%;
}

.statusWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.toggle {
  margin-left: 15px;
}

.toggleWrapper {
  display: flex;
  align-items: center;
}

.toggleTitle {
  margin: 15px 5px;
  position: relative;
  top: 10px;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  flex-flow: column;
  align-self: stretch;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.label {
  flex-flow: row;
  display: flex;
}

.hint {
  margin-left: 10px;
}

.datePicker {
  height: 100%;

  &.loading {
    opacity: 0.7;
    pointer-events: none;
  }

  & span button {
    color: rgbCl($global-accent-color);
  }

  & svg path {
    fill: rgbCl($global-accent-color);
  }
}

.dateInput {
  right: 70%;

  @include max-w(xs) {
    right: 100%;
  }
}

.spinner {
  position: relative;
  background: none;
  margin-left: 5px;
}

@import '../../variables';
@import '../../styles/common/mixins';

$padding: 12px;
$item-max-width: 243px;

.container {
  @include container;

  flex-flow: column;
  max-height: 100%;

  @include max-w(xs) {
    padding: 0;
  }
}

.header {
  @include fluidRow;

  flex-grow: 0;
  position: sticky;
  top: 0;
  margin-bottom: calc(40px - 0.5em);
  padding: 0 10px 0.5em;
  z-index: 10;
  background: $bg-color;

  @include min-w(get-breakpoint-value(xs) + 1) {
    padding: 0 15px 0.5em;
  }

  @include max-w(xs) {
    box-shadow: $element-shadow;
    margin-bottom: 0;
  }

  @include max-w(xxs) {
    margin-bottom: calc(35px - 0.2em);
    padding-bottom: 0.2em;
  }
}

.list {
  @include fluidRow;
  @include tiny-scrollbar($width: 1rem);

  overflow-y: auto;
  padding-top: 3px;

  @include max-w(xs) {
    padding: 20px;
  }
}

.pagination {
  @include make-responsive(
    'margin-top',
    (
      xs: 20px,
      xxl: 30px,
    ),
    30px
  );

  margin-right: calc(var(--grid-item-margin) / 2);
}

.filter {
  display: flex;
  margin-right: 10px;
  gap: 5px;

  @include max-w(xs) {
    padding-top: 10px;
    flex-wrap: wrap;
  }

  .dropdownLabel {
    @include secondary-font();
  }

  .dropdownFilter {
    position: relative;
    margin-right: 15px;

    @include max-w(xs) {
      margin-top: 10px;
    }

    .dropdownContent {
      width: 335px;

      @include max-w(md) {
        top: 140%;
        width: 280px;
      }

      @include max-w(xs) {
        transform: unset;
      }
    }
  }
}

.commonFilters {
  display: flex;
}

.controls {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  & > :not(:last-of-type) {
    @include make-responsive(
      'margin-right',
      (
        xxl: 10px,
        xxxl: 20px,
      ),
      20px
    );
  }
}

.item {
  @include min-w(get-breakpoint-value(xs) + 1) {
    @include fluidCell;
  }

  @include max-w(xs) {
    width: 100%;
  }

  .wide {
    max-width: $item-max-width;

    button {
      @include max-w(xs) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .itemWrapper {
    max-width: $item-max-width;

    .icon {
      @include max-w(xs) {
        width: #{$mobile-item-icon-width};
        margin-bottom: 20px;
        margin-right: 20px;
      }
    }

    .itemCountWrapper {
      transition: all 0.25s ease;
    }

    .countComponent {
      @include max-w(xxl) {
        border-top-left-radius: 20px;
      }
    }

    .tooltipContainer {
      @include max-w(xxl) {
        top: 10px;
        right: 10px;
      }

      @include max-w(xxl) {
        top: 5px;
        right: 5px;
      }
    }

    &:hover {
      .icon {
        transform: translate(0, -3px);
      }

      .itemCountWrapper {
        top: -2px;
      }
    }
  }
}

.noItems {
  .label {
    @include make-responsive(
      'font-size',
      (
        xs: 14px,
        xxl: 18px,
        xxxl: 21px,
      ),
      21px
    );

    line-height: 1.15;
    color: $gray-suit;
  }
}

.noItems {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .label {
    @include make-responsive(
      'font-size',
      (
        xs: 14px,
        xxl: 18px,
        xxxl: 21px,
      ),
      21px
    );

    line-height: 1.15;
    color: $gray-suit;
  }

  .placeholder {
    @include make-responsive(
      'width',
      (
        xs: 281px,
        xxl: 460px,
        xxxl: 600px,
      ),
      600px
    );

    height: 100%;
    margin: 0 auto;
  }
}

.editActionButton {
  font-size: 16px;
  border-radius: 6px;
  padding: 10px 12px;
  width: unset;
}

.deleteActionButton {
  font-size: 16px;
  background-color: $error-red;
  border-radius: 6px;
  padding: 10px 12px;
  width: unset;
}

.searchByFilterContainer {
  padding: 0 10px 0 0;
  margin-left: -10px;

  @include max-w(md) {
    padding: 0;
    margin-left: 0;
    width: 100%;
    max-width: unset;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.closeBtn {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  @include max-w(xxl) {
    top: -10px;
    right: 20px;
  }

  @include max-w(xs) {
    top: -5px;
    right: 15px;
  }

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  svg {
    width: 64px;
    height: 58px;
  }

  h2 {
    @include primary-font(
      (
        xs: 26px,
        xxl: 36px,
      ),
      36px
    );
    font-weight: 600;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .delete {
    background-color: $error-red;
    box-shadow: none;
    width: 192px;
    height: 58px;
    border-radius: 5px;
  }
}

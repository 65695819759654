@import '../../variables';
@import '../../styles/common/mixins';

.listWrapper {
  display: flex;
  flex-flow: column;
  width: 100%;

  & > :not(:last-of-type) {
    margin-bottom: 10px;
  }

  .orgSummaryList {
    @include tiny-scrollbar;

    overflow: auto;
  }
}

.controls {
  display: grid;
  grid-template-columns: 9.3fr 5.75fr 3fr 6.7fr;

  .columnLabel {
    @include primary-font;

    color: #95979b;
  }

  .filter {
    color: $ebony;
  }
}

.iconContainer {
  display: flex;
  align-items: center;

  margin-left: 0;
  margin-right: 10px;

  padding: 6px;
  border: 1px solid $ebony;
  border-radius: 50%;

  > svg {
    margin-right: 0 !important;
  }

  &:hover {
    border-color: RGB($global-accent-color);
    box-shadow: 0 5px 10px rgbCl($global-accent-color, 0.2);

    > svg {
      fill: RGB($global-accent-color);
    }
  }
}

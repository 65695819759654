@import '../../variables';
@import '../../styles/common/mixins';

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  user-select: none;

  svg {
    margin-left: 10px;
  }
}

.dropdownLabel {
  @include primary-font();

  width: max-content;
  color: $gray-suit;

  @include max-w(get-breakpoint-value(xs) + 1) {
    max-width: 280px;
  }
}

.dropdownContent {
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 100%;
}

.closed {
  display: none;
}

@import '../../styles/common/mixins';
@import '../../variables';

.container {
  @include clearlist;
  @include tiny-horizontal-scrollbar(4px);

  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  flex-wrap: nowrap;
  min-height: 4rem;

  @include max-w(xxl) {
    gap: 2rem;
    justify-content: space-between;
  }

  @include max-w(xl) {
    gap: unset;
  }

  @include max-w(xs) {
    padding: 1em 0;
  }
}

.step {
  position: relative;
  counter-increment: steps;
  display: flex;

  &:before {
    transition: all 0.25s ease;
    content: '';
    position: relative;
    top: 50%;
    width: 9vw;
    transform: translateY(-50%);
    height: 1px;
    background: #c2c6cf;

    @include min-w(get-breakpoint-value(md) + 1) {
      width: 4vw;
    }

    @include max-w(xxl) {
      content: none;
    }

    @include max-w(xs) {
      content: none;
    }
  }

  &:first-child:before {
    content: none;
  }
}

.label {
  @include secondary-font;

  line-height: 1;
  align-self: center;
  position: relative;
  z-index: 10;
  background: $bg-color;
  padding-right: 10px;
  display: flex;
  align-items: center;
  color: $ebony;

  &:focus,
  &:active,
  &:visited {
    color: $ebony;
  }

  @include min-w(get-breakpoint-value(md) + 1) {
    white-space: nowrap;
  }

  &:before {
    @include secondary-font;

    content: counter(steps);
    background: #c2c6cf;
    border: none;
    margin-right: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
    z-index: 1;
    transition: all 0.25s ease;

    @include min-w(get-breakpoint-value(xs) + 1) {
      margin-right: 10px;
      min-width: 40px;
      min-height: 40px;
      max-width: 40px;
      max-height: 40px;
    }
  }
}

.current {
  .label {
    cursor: default;
  }
}

.active {
  .label:before,
  &:before {
    background: rgbCl($global-accent-color);
  }
}

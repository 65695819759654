@import '../../../variables';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  transition: all 2.5s ease;

  .message {
    color: $tangerine;
  }

  &.error {
    .message {
      color: $error-red;
    }

    svg path {
      fill: $error-red;
    }
  }
}

@import '../../variables';

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $modal-overlay;
}

.nonClickable {
  cursor: default;
}

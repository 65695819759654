@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: row;

  @include make-responsive(
    'gap',
    (
      xxl: 15px,
      xxxl: 20px,
    ),
    20px,
    px
  );

  &.row {
    flex-direction: row;
    align-items: center;

    .toggle {
      margin-right: 70px;
    }
  }
}

.toggle {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.field {
  display: flex;
  flex-direction: row;
}

.input {
  width: 100%;
  border: 1px solid transparent;

  & input {
    padding: 12px;
  }

  &.isCopyable {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & + svg {
    color: #9297a3;
  }

  &.success {
    border: 1px solid #63dd7e;

    &.isCopyable {
      border-right: none;
    }

    & + svg {
      color: #63dd7e;
    }
  }

  &.error {
    @include error-border;
  }
}

.copyButton {
  width: 72px;
  border-radius: 0 10px 10px 0;

  @include max-w(xs) {
    height: 56px;
  }

  &.success {
    border: 1px solid #63dd7e;
    border-left: none;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.controls {
  display: flex;
  justify-content: flex-end;

  .continueButton {
    @include max-w(xs) {
      max-width: 100%;
    }
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.mainInformation {
  display: flex;
  flex-direction: row;

  @include max-w(md) {
    flex-direction: column;
  }

  .formData {
    display: flex;
    flex-direction: column;
    flex: 1 1 66%;
    margin-right: 50px;

    @include max-w(md) {
      flex: 1 1 100%;
      margin: 0;
    }

    .information {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }

    .inputContainer {
      flex: 1 1 50%;
      max-width: calc(50% - 15px);
      margin: 10px 0;
      display: flex;
      flex-flow: column;

      &.departmentContainer {
        flex: unset;
        margin-top: 0;
      }

      @include max-w(md) {
        flex: 1 1 100%;
        max-width: inherit;
      }
    }

    .editorContainer {
      padding: 15px 0 20px;

      @include max-w(xs) {
        padding: 10px 0;
      }
    }

    .textareaContainer {
      padding: 15px 0 20px;

      @include make-responsive(
        'height',
        (
          xxl: 315px,
          xxxl: 465px,
        ),
        465px,
        px
      );

      @include max-w(xs) {
        height: 420px;
        padding: 10px 0;
      }
    }

    .tinyEditor {
      @include make-responsive(
        'height',
        (
          xxl: 280px,
          xxxl: 430px,
        ),
        430px,
        px
      );

      @include max-w(xs) {
        height: 395px;
      }
    }
  }

  .tagsContainer {
    flex: 1 1 33%;

    @include max-w(md) {
      flex: 1 1 100%;
      margin: 20px 0;
    }
  }
}

.typeLabel {
  @include primary-font(
    (
      xxl: 18px,
      xxxl: 24px,
    )
  );

  @include make-responsive(
    'margin-bottom',
    (
      xxl: 15px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  font-weight: 500;

  @include max-w(xs) {
    margin-bottom: 20px;
  }
}

.label {
  @include label;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.left {
}

.title {
  @include heading-font();

  color: $ebony;
  cursor: pointer;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right {
}

// 3rd party
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-tabs/style/react-tabs.scss';
@import '~react-datepicker/dist/react-datepicker.css';

@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';

@import './variables';

@import './styles/common/reset';
@import './styles/common/mixins';
@import './styles/common/animations';

html {
  * {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  background: $bg-color;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $ebony;
}

#root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  // Global variables
  :root {
    --accent-color: $default-accent-color;
    --sidebar-color: $default-sidebar-color;
    --sidebar-text-color: $default-sidebar-text-color;
    --org-rect-logo: $default-org-rect-logo;
    --org-square-logo: $default-org-square-logo;
  }
}

button {
  @include reset-button;
  font-family: $ff-gilroy;
}

input {
  font-family: $ff-gilroy;
}

a {
  text-decoration: none;
}

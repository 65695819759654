@import '../../variables';
@import '../../styles/common/mixins';

.container {
  padding: 50px 70px;
  display: flex;
  flex-flow: column;
  flex: 1;
  align-items: flex-start;
  position: relative;
  max-width: 100%;

  @include max-w(xxl) {
    padding: 35px 30px 50px;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 30px 55px 50px 140px;
  }

  @include max-w(xs) {
    padding: 10px;
  }
}

.tabs {
  @include make-responsive(
    'max-width',
    (
      md: 630px,
      xxl: 900px,
      xxxl: 1060px,
    ),
    650px
  );
  @include make-responsive(
    'min-width',
    (
      md: 630px,
      xxl: 900px,
      xxxl: 1060px,
    ),
    650px
  );
  @include max-w(md) {
    max-width: 100%;
    min-width: 100%;
  }
}

.filters {
  @include make-responsive(
    'margin-bottom',
    (
      xs: 10px,
      xxl: 14px,
      xxxl: 20px,
    ),
    20px
  );

  display: flex;
  z-index: 10;

  @include max-w(lg) {
    flex-flow: column;
  }

  @include max-w(xs) {
    flex-flow: column;
  }
}

.adminFilters {
  display: flex;

  @include max-w(lg) {
    margin-bottom: 10px;
  }

  @include max-w(xs) {
    flex-direction: column;
    padding-right: 10px;

    > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & > * {
    @include make-responsive(
      'margin-right',
      (
        xs: 20px,
        xxl: 30px,
        xxxl: 33px,
      ),
      33px
    );
  }
}

.list {
  @include tiny-scrollbar(8px);

  overflow-y: auto;
  max-height: 100%;
  padding-right: 12px;
  display: flex;
  flex-flow: column;
  width: 100%;

  @include max-w(md) {
    padding-right: 10px;
  }
}

.card {
  @include make-responsive(
    'margin-top',
    (
      xxl: 10px,
      xxxl: 20px,
    ),
    10px
  );

  @include max-w(xl) {
    flex-flow: column;
    align-self: flex-start;
  }

  @include max-w(xs) {
    align-self: stretch;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.control {
  width: auto;
  max-width: 117px;
  font-size: 16px;
  white-space: nowrap;
  padding: 10px 12px;

  @include max-w(xs) {
    font-size: 14px;
  }

  .optional {
    margin-left: 0.25em;

    @include max-w(md) {
      display: none;
    }
  }
}

.disable {
  background-color: $error-red;

  @include min-w(xs) {
    min-width: 140px;
  }

  &.isLoading {
    background: lighten($error-red, 35%);

    rect {
      fill: transparent !important;
    }
  }

  .disableLoader {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.mobile {
  &:not(.viewButton) {
    padding: 0;
    width: auto;
    height: auto;
  }

  &.viewButton {
    padding: 9px 10px 7px;
  }
}

.pagination {
  padding: 10px 0;

  @include make-responsive(
    'margin-right',
    (
      xs: 10px,
      xxl: 20px,
      xxxl: 26px,
    ),
    21px
  );
}

.loader {
  z-index: 80;
  background: $bg-color;
  border-radius: 0 10px 10px 10px;
}

.dropdownFilter {
  position: relative;

  @include max-w(lg) {
    margin-right: 30px;
  }

  .dropdownLabel {
    @include make-responsive(
      'font-size',
      (
        xs: 14px,
        xxl: 18px,
        xxxl: 21px,
      ),
      21px
    );

    display: flex;
    align-items: center;

    :global(.departmentsName) {
      @include cropped-text;

      display: inline-block;
      max-width: 240px;
      white-space: nowrap;
    }
  }

  .dropdownContent {
    width: 335px;
    transform: translateX(-30%);

    :global(.reactSelectContainer) {
      padding: 0;
      filter: none;
    }

    :global(.reactSelect__menu) {
      position: relative;
      padding: 0;
      margin-top: 5px;
    }

    @include max-w(md) {
      transform: unset;
      top: 140%;
      width: 280px;
    }
  }
}

.deptFilterContainer {
  padding: 10px;
  background-color: $white;
  box-shadow: 0 8px 20px $shady;
  filter: drop-shadow(0px 4px 4px rgba($black, 0.25));
  border-radius: 10px;

  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  .controls {
    display: flex;
    justify-content: space-between;

    > button {
      max-width: calc(50% - 5px);
    }
  }
}

@import '../../../styles/common/mixins';
@import '../../../variables';

.nav {
  @include tiny-scrollbar();

  &::-webkit-scrollbar-thumb {
    background-color: rgbCl($global-sidebar-text-color, 0.1);

    &:hover {
      background-color: rgbCl($global-sidebar-text-color, 0.3);
    }
  }

  overflow: auto;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include tiny-scrollbar;

  height: 100%;
  width: 100%;

  @include max-w(xxl) {
    margin-bottom: 20px;
    padding-right: 0;
    width: 100%;
  }
}

.content {
  @include tiny-scrollbar;
  @include primary-font();

  display: flex;
  flex-direction: column;
  max-height: 185px;
  width: 100%;
  overflow: auto;
  background: $white;
  border-radius: 10px;
  padding: 20px 20px 22px 30px;

  @include max-w(xs) {
    height: 145px;
  }
}

.labelContainer {
  padding-bottom: 6px;
  color: $gray-suit;
}

.title {
  @include secondary-font(
    (
      xxl: 12px,
      xxxl: 14px,
    )
  );

  margin: 15px 0;
  color: $gray-suit;
}

.note {
  margin-top: 15px;
}

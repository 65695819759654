@import '../../variables';
@import '../../styles/common/mixins';

.editableTable {
  position: relative;
  display: flex;
  flex-flow: column;

  @include max-w(xs) {
    padding-bottom: 10px;
  }
}

.addButton {
  margin-left: auto;

  @include max-w(xs) {
    margin-bottom: 10px;
    max-width: unset;
  }
}

.list {
  @include tiny-scrollbar();
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 10px;
}

.head,
.row {
  & > * {
    flex: 1;

    @include min-w(map-get($breakpoints, xs) + 1) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.head {
  @include primary-font();

  display: flex;
  align-items: center;
  padding: 1em 1em;
  border-bottom: 1px solid $gray-blue;
  text-transform: uppercase;
  color: $gray-suit;

  &:last-of-type {
    border-bottom: none;
  }

  @include max-w(xs) {
    display: none;
  }
}

.row {
  @include primary-font();

  display: flex;
  align-items: center;
  position: relative;
  padding-right: 1em;
  padding-left: 1em;
  border-bottom: 1px solid $gray-blue;

  &:last-of-type {
    border-bottom: none;
  }

  & > * {
    &:not(.cellWithHeader),
    &.cellWithHeader > :last-child {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    &.cellWithHeader > :first-child {
      margin-bottom: 5px;
    }

    @include max-w(map-get($breakpoints, xs)) {
      &:first-child {
        order: -2;
      }
      &:last-child {
        flex-grow: 0;
        flex-basis: 28%;
        order: -1;
      }
      &:not(:first-child):not(:last-child) {
        flex-basis: 100%;
      }
    }
  }

  @include max-w(map-get($breakpoints, xs)) {
    flex-wrap: wrap;
    padding: 10px 15px;
  }

  .cellWithHeader {
    display: flex;
    flex-direction: column;
  }

  & > div,
  & > input {
    @include primary-font();

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
}

.rowPlaceholder {
  @include primary-font();

  padding: 1.62em 2em;

  &:not(:last-of-type) {
    border-bottom: 1px solid $gray-blue;
  }

  @include max-w(xs) {
    line-height: 2;
  }
}

.disabled {
  pointer-events: none;
  filter: grayscale(50%) opacity(0.6);
  user-select: none;
}

.placeholder {
  display: flex;
  flex-direction: column;
}

.placeholderTitle {
  @include primary-font();

  color: $gray-light;
  padding: 1em 20px;

  @include max-w(xs) {
    padding: 10px 15px;
  }
}

.imgPlaceholder {
  align-self: center;
  max-width: 90%;
  margin: 5%;
}

.savedMessage {
  @include primary-font();

  width: max-content;
  background-color: white;
  transform: translateX(33px);
  color: $ebony;
  display: flex;
  position: relative;
  align-items: center;

  @include max-w(map-get($breakpoints, 'xxl')) {
    transform: translateX(27px);
  }

  @include max-w(xs) {
    transform: translateX(32px);
  }

  svg {
    margin-top: -5px;
    margin-right: 10px;
    height: 13px;
    width: 13px;

    @include max-w(xs) {
      margin-top: -3px;
    }
  }
}

.integrationItem {
  display: flex;
  align-items: center;
  filter: grayscale(1);
  cursor: pointer;
  transition: all 0.5s ease;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.active {
  filter: grayscale(0);
  transition: all 0.5s ease;
}

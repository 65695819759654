@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.label {
  @include primary-font();

  margin-bottom: 0.45em;
  color: $gray-light;
}

.icon {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

@import '../../variables';
@import '../../styles/common/mixins';

.downloadBtnContainer {
  display: inline-block;
  @include max-w(xs) {
    flex: 1;
  }
}

.downloadBtn {
  width: fit-content;
  padding: 12px 24px;
  font-size: 12px;
  border-radius: 5px;

  @include max-w(xl) {
    width: 100%;
    margin: 0 auto;
  }
}

.closeBtn {
  position: absolute;
  top: -7px;
  right: -10px;
  width: 7px;
  height: 7px;
  cursor: pointer;

  &.red {
    color: $error-red;
  }

  &.green {
    color: $success-green;
  }
}

.message {
  position: relative;
  flex: 1;
  border-radius: 10px;
  padding: 9px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  gap: 12px;
  min-width: 275px;

  &.success {
    border: 1px solid $success-green;
  }

  &.failed {
    @include error-border;

    justify-content: space-between;

    @include max-w(xs) {
      flex-wrap: wrap;
    }
  }
}

.failedInfo {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  span {
    color: $error-red;
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

.App {
  overflow: hidden;
  min-height: 50vh;
  max-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;

  @include max-w(xs) {
    flex-direction: column;
  }
}

.main {
  @include tiny-scrollbar;

  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;

  .content {
    @include tiny-scrollbar;

    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }

  .contentLoader {
    z-index: 10;
    top: 0;
    bottom: 0;
    background-color: $bg-color;
  }
}

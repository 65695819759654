@import '../../variables';
@import '../../styles/common/mixins';

.container {
  display: flex;
  flex-flow: column;
  word-break: break-word;
  white-space: normal;
}

.button {
  cursor: pointer;
}

.content {
  display: flex;
}

.title {
  @include primary-font();

  margin-bottom: 21px;
}

.details {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.icon {
  max-width: 57px;
  min-width: 57px;
  margin-right: 15px;

  @include max-w(xs) {
    max-width: 47px;
    min-width: 47px;
    margin-right: 10px;
  }
}

.name {
  @include primary-font();

  color: $ebony;
}

.description {
  font-size: 16px;
  color: $gray-suit;

  @include max-w(xs) {
    font-size: 14px;
  }
}

.action {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container();
  flex-direction: row;

  @include min-w(get-breakpoint-value(lg) + 1) {
    > * {
      max-width: 80%;
    }
  }

  @include min-w(get-breakpoint-value(xl) + 1) {
    > * {
      max-width: 70%;
    }
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    > * {
      max-width: 60%;
    }
  }

  @include max-w(lg) {
    flex-direction: column;
    max-width: 570px;
  }

  @include max-w(xs) {
    padding: 10px;
    padding-bottom: 40px;
  }
}

.form {
  @include make-responsive(
    'max-width',
    (
      xxl: 580px,
      xxxl: 750px,
    ),
    750px,
    px
  );

  @include make-responsive(
    'margin-right',
    (
      xs: 30px,
      xxl: 40px,
      xxxl: 50px,
    ),
    50px,
    px
  );

  display: flex;
  flex-direction: column;

  @include max-w(lg) {
    order: 2;
    margin-right: 0;
    max-width: none;
  }
}

.sob {
  @include make-responsive(
    'max-width',
    (
      xxl: 358px,
      xxxl: 486px,
    ),
    486px,
    px
  );

  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  @include max-w(lg) {
    order: 1;
    margin-bottom: 20px;
    max-width: none;
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.copyButton {
  font-size: 16px;
  min-width: auto;

  @include max-w(xs) {
    max-width: 32px;
    min-width: 32px;
    max-height: 32px;
    min-height: 32px;
  }
}

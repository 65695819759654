@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column;

  > *:not(:first-child) {
    margin-top: 5px;
  }
}

.mskuDetails {
  background: $white;
  padding: 5px;
  border-radius: 5px;

  .mskuIcon {
    outline: none;
    width: 100%;
    height: 100%;
    fill: $gray-blue;

    &:hover {
      fill: rgbCl($global-accent-color);
    }

    @include max-w(xs) {
      padding: 0;
      margin: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .alert {
    fill: $error-red;
    color: $error-red !important;
  }
}

.multipleOptionsDetailsModal {
  width: 600px;
  height: 650px;
}

.scalable {
  width: 26px;
  height: 26px;
  transition: all 0.25s ease;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    width: 30px;
    height: 30px;
  }
}

.hiddenIconTooltip {
  .hiddenInfo {
    padding: 5px;
    border-radius: 5px;
    background: $white;
  }
}

.preorderDetails {
  background: $white;
  padding: 5px;
  border-radius: 5px;

  .preorderIcon {
    cursor: default;
    outline: none;
    width: 100%;
    height: 100%;

    @include max-w(xs) {
      padding: 0;
      margin: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

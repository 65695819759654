@import '../../../variables';
@import '../../../styles/common/mixins';

.mobileSideNav {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.15;
  color: rgbCl($global-sidebar-text-color);
}

.header {
  height: $mobile-nav-height;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgbCl($global-sidebar-color);

  .sidebarLogo {
    position: relative;
    width: 150px;
    height: 70px;
    margin-left: 20px;
    background-image: $global-org-rect-logo;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  img {
    height: auto;
    width: 190px;
    padding: 20px;

    @include max-w(xs) {
      width: 160px;
    }
  }

  .controls {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 20px;
    }

    svg {
      display: block;

      > * {
        fill: rgbCl($global-sidebar-text-color);
      }
    }

    .features {
      display: flex;

      & > *:not(:last-child) {
        margin-right: 20px;
      }

      .icon {
        path {
          fill: rgbCl($global-sidebar-text-color);
        }
        height: 20px;
        width: 20px;
      }
    }

    .burgerWrapper {
      position: relative;

      .alert {
        position: absolute;
        top: -5px;
        left: -5px;
        width: 10px;
        height: 10px;
        border: 1px solid $white;
        border-radius: 50%;
      }

      svg.alert path {
        fill: rgbCl($white);
      }

      svg.alert circle {
        fill: rgbCl($error-red);
      }
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 25px 0;
  overflow-y: auto;
  background-color: rgbCl($global-sidebar-color);

  svg,
  img {
    height: 20px;
    width: 20px;
  }

  .top {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 15px;
    padding-left: 14px;
    color: rgbCl($global-sidebar-text-color);

    button {
      color: rgbCl($global-sidebar-text-color);
    }

    svg path {
      fill: rgbCl($global-sidebar-text-color);
    }

    .userInformation {
      flex-direction: column;
      margin-bottom: 10px;

      .avatar {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        border-color: rgbCl($global-sidebar-text-color);
        border-width: 3px;
      }

      span {
        @include cropped-text(1);
      }
    }

    .featureBudgetsList {
      width: 100%;

      & > :not(:last-of-type) {
        margin-bottom: 10px;
      }

      &:empty {
        display: none;
      }
    }

    .userBudget {
      margin-bottom: 10px;
    }
  }

  .lowInventoryBtn {
    margin-top: 10px;
  }

  .closeBtn {
    height: 13px;
    width: 13px;

    position: absolute;
    top: 15px;
    right: 15px;
  }

  .controls {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 30px;
    margin: 10px;
    padding: 10px;

    .logOut {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

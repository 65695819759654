@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 30px;
  height: 30px;
  background-color: $white;
  cursor: default;
  transition: box-shadow 0.25s ease;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  }

  @include max-w(xs) {
    margin: 0;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include tiny-scrollbar;

  padding-right: 10px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start !important;
  margin: 15px 0;

  @include max-w(xxl) {
    margin-bottom: 20px;
    padding-right: 0;
    width: 100%;
  }
}

.content {
  @include tiny-scrollbar;
  @include primary-font();

  display: flex;
  flex-direction: column;
  height: 185px;
  width: 100%;
  overflow: auto;
  background: $white;
  border-radius: 10px;
  padding: 20px 20px 22px 30px;

  @include max-w(xs) {
    height: 145px;
  }
}

.labelContainer {
  padding-bottom: 6px;
  color: $gray-suit;
}

.text {
  color: $gray-suit;
  font-size: 14px;
  margin-top: 10px;
}

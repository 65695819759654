@import '../../../../variables';
@import '../../../../styles/common/mixins';

.toggle {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  transition: all 0.4s ease;

  &.activate {
    background-color: $budget-green;
  }
  &.deactivate {
    background-color: $error-red;
  }

  label {
    &.switch {
      width: 16px;
      height: 8px;
      margin: 0 auto;

      input {
        &:not(:checked) + .slider {
          &:before {
            background-color: $error-red;
            transform: translate(-60%, -50%) !important;
          }
        }

        &:checked + .slider {
          cursor: default;

          &:before {
            background-color: $budget-green;
            transform: translate(60%, -50%) !important;
          }
        }
      }
    }
  }

  .slider {
    height: 8px;
    min-width: 16px;
    max-width: 16px;
    background: $white !important;

    &:before {
      height: 6px;
      width: 6px;
      left: calc(50% - 3px);
      bottom: 1px;
    }
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.inventory {
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  width: 43%;
  max-width: 666px;

  &.inventoryBorder {
    .inventoryLabel {
      margin-bottom: 10px;
    }

    .inventoryList {
      @include make-responsive(
        'padding',
        (
          xs: 10px,
          xxl: 20px,
          xxxl: 30px,
        ),
        40px,
        px
      );
      border: 0.5px solid $gray-blue;
      border-radius: 10px;
    }
  }

  @include max-w(lg) {
    width: unset;
    max-width: unset;
  }

  @include max-w(xs) {
    padding: 0;
  }
}

.inventoryLabel {
  @include label();

  @include max-w(xs) {
    position: relative;
  }
}

.inventoryList {
  @include tiny-scrollbar;

  display: flex;
  flex-direction: column;
  max-height: 470px;
  margin-bottom: 30px;
  overflow-y: auto;

  @include max-w(xs) {
    margin-left: -5px;
    margin-right: -5px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 10px;

    @include max-w(xs) {
      padding: 5px;
    }

    &.selected {
      border: 1px solid $budget-green;
    }

    @include max-w(xs) {
      align-self: flex-start;
    }

    &.selectItem {
      transition: background 0.25s ease;

      &:hover {
        background: $gray-blue-light;
      }

      @include max-w(xs) {
        &:hover {
          background: none;
        }
      }
    }

    &.separated {
      border-radius: 0;
      border-top: 1px solid $gray-blue;

      &:last-of-type {
        border-bottom: 1px solid $gray-blue;
      }

      @include max-w(xs) {
        &:hover {
          background: none;
        }
      }
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .addBtn {
      @include make-fluid('padding', 5px, 8px, get-breakpoint-value(xs), get-breakpoint-value(xl), 8px, px);
      @include make-responsive(
        'font-size',
        (
          xs: 14px,
          xxl: 16px,
        ),
        16px
      );
      border-radius: 6px;
      width: auto;
      margin-left: auto;
    }
  }

  @include max-w(xs) {
    @include tiny-horizontal-scrollbar();

    flex-direction: row;
    height: unset;
    overflow-y: unset;
    overflow-x: auto;
  }
}

.summarySize {
  height: 490px;
}

.campaignIcon {
  > div:first-child {
    width: 60%;
    height: 60%;
  }

  .preview {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 10px;
    height: 10px;

    @include max-w(xs) {
      padding: 0;
      margin: unset;
    }
  }
}

.multipleOptionsDetailsModal {
  width: 600px;
  height: 650px;
}

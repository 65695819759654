@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1;
  flex: 1;
}

.header {
  margin-bottom: 5px;
}

.closeButton {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  user-select: none;
  z-index: 100;

  @include max-w(xs) {
    top: 20px;
    right: 20px;
  }
}

.content {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1;
  flex: 1;

  @include max-w(xs) {
    max-width: calc(100% - 20px);
  }
}

.title {
  @include make-responsive(
    'font-size',
    (
      xs: 32px,
      xxl: 48px,
    ),
    32px
  );

  text-align: center;
  font-weight: 600;
  margin: 1em auto 0.5em;

  @include max-w(xs) {
    margin: 40px auto 0.5em;
  }
}

.info {
  @include primary-font;

  text-align: center;

  strong {
    font-weight: 600;
  }
}

.actions {
  display: flex;
  align-self: stretch;
  justify-content: center;
  margin-top: 40px;

  @include max-w(xs) {
    margin-top: 30px;
  }
}

.btn {
  justify-content: center;
  align-self: stretch;
}

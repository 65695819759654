@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  width: 100%;

  &:nth-of-type(2n + 1) {
    .totalPercentage {
      background: $orange-sun;
    }
  }

  &:nth-of-type(2n + 2) {
    .totalPercentage {
      background: $deep-sky-blue;
    }
  }

  &:nth-of-type(2n + 3) {
    .totalPercentage {
      background: $budget-green;
    }
  }
}

.description {
  @include primary-font();

  display: flex;
  flex-flow: column;
  max-width: 11.5em;
  min-width: 11.5em;
  margin-right: 30px;

  @include max-w(xxl) {
    margin-right: 20px;
  }
}

.label {
  margin-bottom: 5px;
}

.totalCount {
  font-weight: 600;
}

.automatedCount {
  @include make-responsive(
    'font-size',
    (
      xxl: 14px,
      xxxl: 18px,
    ),
    18px
  );

  margin-top: 5px;
  display: flex;

  &:before {
    content: '';
    background: $budget-green;
    border-radius: 50%;
    min-width: 10px;
    max-width: 10px;
    height: 10px;
    align-self: center;
    margin-right: 5px;
  }
}

.graph {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 44px;
  align-self: center;
}

.totalPercentage {
  width: 100%;
  background: gray;
  display: flex;
  justify-content: flex-end;
}

.automatedPercentage {
  background: $budget-green;
}

.percentage {
  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xxl: 18px,
      xxxl: 24px,
    ),
    18px
  );

  font-weight: 600;
  align-self: center;
  margin-left: 10px;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.phoneInput {
  background: $white;
  border-radius: 10px;
  padding: 22px 10px;
  transition: all 0.25s ease;

  &.errorBorder {
    @include error-border;
  }
}

.countrySelect {
  @include primary-font();

  option {
    padding: 10px 0;
  }
}

.input {
  @include primary-font();
  @include make-responsive(
    'max-height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );

  border: none;
  color: $ebony;

  &:focus {
    border: none;
    outline: none;
  }

  &:disabled {
    color: rgba(43, 47, 56, 0.2);
  }
}

.error {
  @include secondary-font(
    (
      xxl: 12px,
      xxxl: 16px,
    )
  );

  position: absolute;
  top: calc(100% + 5px);
  line-height: 1.15;
  color: $error-red;
}

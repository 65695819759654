@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 100%;
  background: $bg-color;
  padding: 0 8px 0 0;

  @include max-landscape-h(xs) {
    padding: 15px 0 10px;
  }

  @include max-w(xs) {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 30px 0 20px 30px;
  margin-bottom: 0;

  @include max-w(xs) {
    padding: 20px 0 20px 20px;
  }

  .title {
    @include heading-font(
      (
        xs: 22px,
        xxl: 26px,
        xxxl: 28px,
      )
    );
  }
}

.modalContent {
  @include tiny-scrollbar;

  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 30px 0 30px;
  margin: 0 0 140px 0;
  overflow-y: auto;
  background-color: $bg-color;

  @include max-w(xs) {
    padding: 0 20px 0 20px;
    margin: 0 0 120px 0;
  }
}

.back {
  margin-right: 25px;
}

.footer {
  position: fixed;
  bottom: 0;
  padding: 30px 0;
  background: $bg-color;
  display: flex;
  justify-content: center;

  @include max-w(xs) {
    padding: 20px 0;
  }
}

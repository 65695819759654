@import '../../variables';
@import '../../styles/common/mixins';

:root {
  --grid-users-per-row: 3;
  --grid-users-margin: 1.5vw;

  @include max-w(xxxl) {
    --grid-users-per-row: 2;
  }
  @include max-w(xl) {
    --grid-users-per-row: 1;
  }
}

.dropdownContent {
  min-width: 335px;

  @include max-w(md) {
    right: 0;
  }

  @include max-w(xs) {
    right: unset;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.header {
  @include container;

  display: flex;
  margin-bottom: 20px;
  padding-bottom: 0;

  @include max-w(xl) {
    padding-bottom: 0;
  }

  @include max-w(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include max-w(xs) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.filter {
  display: flex;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-bottom: 10px;
  }

  @include max-w(xs) {
    flex-flow: column;

    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  & > * {
    @include make-responsive(
      'margin-right',
      (
        xs: 20px,
        xxl: 30px,
        xxxl: 33px,
      ),
      33px
    );
  }
}

.addButton {
  margin-left: auto;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-left: 0;
    max-width: 100%;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    margin-right: 15px;
  }
}

.listWrapper {
  @include tiny-scrollbar();

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 9px;
  }

  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.list {
  @include container;
  @include fluidRow($margin: var(--grid-users-margin));

  padding-top: 0;

  @include max-w(xs) {
    right: 0;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 0 8px 50px 130px;
  }
}

.card {
  @include fluidCell($perRow: var(--grid-users-per-row), $margin: var(--grid-users-margin));

  @include max-w(xl) {
    max-width: 620px;
  }
}

.top {
  @include max-w(xs) {
    flex-flow: row wrap;
  }
}

.avatar {
  @include make-fluid('margin-right', 10px, 15px, get-breakpoint-value(md), get-breakpoint-value(xl), 20px, px);
  @include make-fluid('max-width', 40px, 55px, get-breakpoint-value(xs), get-breakpoint-value(xl), 55px, px);
  @include make-fluid('min-width', 40px, 55px, get-breakpoint-value(xs), get-breakpoint-value(xl), 55px, px);
  @include make-fluid('max-height', 40px, 55px, get-breakpoint-value(xs), get-breakpoint-value(xl), 55px, px);
  @include make-fluid('min-height', 40px, 55px, get-breakpoint-value(xs), get-breakpoint-value(xl), 55px, px);

  @include max-w(xs) {
    margin-bottom: 10px;
  }
}

.userInfo {
  display: flex;
  flex-flow: column;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
}

.roleDeptInfo {
  display: flex;
  flex-shrink: 0;
  flex-flow: column;
  white-space: nowrap;
  margin-left: auto;
  text-align: right;
  max-width: 40%;

  @include max-w(xs) {
    text-align: left;
    margin-top: 10px;
    width: 100%;
  }
}

.username {
  @include card-title-font();

  text-overflow: ellipsis;
  overflow: hidden;

  @include max-w(md) {
    margin-bottom: 2px;
  }
}

.email {
  @include primary-font;
  text-overflow: ellipsis;
  overflow: hidden;
}

.department {
  @include primary-font;
  @include cropped-text;

  white-space: nowrap;
  color: $gray-suit;
}

.role {
  @include primary-font;
}

.integrationBar {
  display: flex;
  align-items: center;
}

.integrationItem {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  cursor: default;
}

.budget {
  margin-left: auto;
}

.controls {
  @include max-w(xs) {
    justify-content: flex-end;
  }

  .checkBox {
    margin-left: auto;
  }
}

.actionBtn {
  @include primary-font;
  @include hover-underline($gray-light, 2px);

  color: $gray-light;

  &:not(:last-of-type) {
    margin-right: 15px;

    @include max-w(xs) {
      margin-right: 10px;
    }
  }

  &.delete {
    @include hover-underline($error-red, 2px);

    color: $error-red;
  }
}

.dropdownLabel {
  @include make-responsive(
    'font-size',
    (
      xs: 14px,
      xxl: 18px,
      xxxl: 21px,
    ),
    21px
  );
}

@import '../../variables';

.container {
  border-radius: 5px;
  padding: 20px;
  display: flex;
  gap: 20px;
  border-width: 1px;
  border-style: solid;
}

.icon {
  transform: scale(1.6);
  display: flex;
  align-items: center;
}

.text {
  font-size: 16px;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.btn:global {
  @include back-btn;
  width: 15px;
  height: 15px;

  @include max-w(xs) {
    width: 12px;
    height: 12px;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    height: 20px;
    left: 3px;
    overflow: hidden;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.flippableBudgetBar {
  display: flex;
  align-items: center;
  min-width: 95px;
  width: 100%;
  height: 24px;
  position: relative;
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transform-style: preserve-3d;
  transform-origin: center center;
}

.flip {
  transform: rotate3d(0, 1, 0, 180deg);
}

.top {
  background: red;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: rotateX(0);
  transform-origin: top center;
  border-radius: 5px;
}

.bottom {
  position: absolute;
  top: 0;
  left: -1px;
  width: 103%;
  height: 100%;
  transform: rotateY(-180deg);
  transform-origin: bottom center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border-radius: 3px;
}

.progress {
  background: $budget-green;
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 3px;
}

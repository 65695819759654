@import '../../../../variables';
@import '../../../../styles/common/mixins';

.inputInner {
  transition: box-shadow 0.25s ease;
  display: flex;
  box-shadow: $transparent-shadow;
  background-color: $white;
  border-radius: 10px;
  align-items: center;

  &:hover {
    box-shadow: $input-shadow;

    :global(input) {
      box-shadow: none !important;
    }
  }
}

.amount {
  height: 100%;
  padding-left: 10px;

  :global(input) {
    max-height: 30px;
  }
}

@import '../../../../../../variables';
@import '../../../../../../styles/common/mixins';

.controlsContainer {
  z-index: 12;
  padding: 5px 0;
}

.selectBtn {
  @include make-responsive(
    'width',
    (
      xs: 101px,
      xxl: 111px,
      xxxl: 137px,
    ),
    137px
  );

  margin-left: auto;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.toggle {
  @include button-shadow;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  }

  cursor: pointer;
  position: absolute;
  top: 100px;
  right: 0;
  transform: translateX(50%);
  background: rgbCl($global-accent-color);
  border-radius: 50%;
  transition: all 0.25s ease-in;
  width: 35px;
  height: 35px;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 30px;
    height: 30px;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 2px;
    background: $white;
    transform-origin: 0 center;
    transition: all 0.25s ease;
  }

  &:hover {
    transform: translateX(45%);

    &:before,
    &:after {
      width: 12px;
    }
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-49%, 0%) rotate(-45deg);
  }

  &.collapsed {
    transform: translateX(50%) rotate(180deg);

    &:hover {
      transform: translateX(55%) rotate(180deg);
    }
  }
}

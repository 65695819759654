@import '../../../variables';
@import '../../../styles/common/mixins';

.rowControls {
  display: flex;
  margin-left: auto;
  position: relative;
}

.controlButton {
  @include primary-font();

  &.save + .cancel,
  &.edit + .delete {
    margin-left: 30px;

    @include max-w(map-get($breakpoints, xxl)) {
      margin-left: 10px;
    }
  }

  user-select: none;
  color: $gray-light;

  &.save {
    color: $budget-green;
  }

  &.delete {
    color: $error-red;
  }

  @include max-w(map-get($breakpoints, 'xs')) {
    width: 32px;
    height: 32px;
  }
}

.savedMessage {
  @include primary-font();

  width: max-content;
  background-color: white;
  transform: translateX(26px);
  color: $ebony;
  display: flex;
  position: relative;
  align-items: center;

  @include max-w(map-get($breakpoints, 'xs')) {
    transform: translateX(32px);
  }

  svg {
    margin-top: -5px;
    margin-right: 10px;
    height: 13px;
    width: 13px;

    @include max-w(map-get($breakpoints, 'xs')) {
      margin-top: -3px;
    }
  }
}

.submittedIcon {
  width: 20px;
  height: 20px;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.sidenav {
  width: 100%;
  height: 100%;
  background: $ebony;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .logo {
    width: 100%;
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.textareaContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: box-shadow 0.25s ease;
  box-shadow: 0 0 0 transparent;

  &:hover .textarea:read-write {
    box-shadow: $input-shadow;
  }
}

.textareaWrapper {
  position: relative;
  display: flex;
  flex: auto;

  .textarea {
    @include primary-font();
    @include tiny-scrollbar;

    background: $white;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1em;
    max-width: 100%;
    width: 100%;
    font-size: 21px;
    resize: none;
    flex: auto;
    font-family: $ff-gilroy;
    border: 0;
    transition: all 0.25s ease;

    &:focus {
      outline: 0;
    }

    &:disabled {
      background: lighten($bg-color, 2.5%);
      color: rgba(43, 47, 56, 0.2);

      &::placeholder {
        opacity: 0.2;
      }
    }

    &:read-only {
      &:focus {
        border: none;
      }
    }

    @include max-w(xs) {
      font-size: 18px;
      padding: 30px 15px;
    }
  }

  .counter {
    @include primary-font();

    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    font-weight: bold;
    display: flex;
    justify-content: center;
    background: $white;
    padding: 0.25em;
    border-radius: 0.5em;
    line-height: 1;
    color: $gray-suit;

    @include max-w(xs) {
      bottom: 0;
      right: 0;
    }

    .max {
      font-weight: 400;
    }

    .warning,
    .warning ~ .delimiter {
      color: $orange-sun;
    }

    .error,
    .error ~ .delimiter {
      color: $error-red;
    }
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include secondary-font();

  @include max-w(xs) {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
  }
}

.label {
  margin-right: 0.625em;
  color: $gray-suit;

  @include max-w(xs) {
    margin-right: 0;
    margin-bottom: 0.3em;
  }
}

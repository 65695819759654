@import '../../variables';

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 15px 0;

  & svg {
    width: 20px;
    height: 20px;
  }
}

.text {
  color: $gray-suit;
  margin-left: 10px;
}

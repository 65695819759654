@import '../../../variables';
@import '../../../styles/common/mixins';

.formWrapper {
  @include primary-font();

  padding: 10px;
  display: flex;
  flex-flow: column;
  flex: 1;

  @include min-w(get-breakpoint-value(xs) + 1) {
    padding: 0 45px;
  }
}

.title {
  @include heading-font();

  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.row {
  display: flex;
  gap: 10px;
  margin-bottom: 1.75em;
}

.errorMessage {
  @include micro-font();

  color: $error-red;
  position: absolute;
  top: 100%;
  margin-top: 0.5em;
}

.select {
  position: relative;
  width: 100%;

  :global {
    .reactSelect__control {
      border: 1px solid $gray-table;

      &:hover {
        border-color: $gray-table;
      }
    }
  }

  &.error {
    :global {
      .reactSelect__control {
        @include error-border;
      }
    }
  }
}

.input {
  border: 1px solid $gray-table;
}

.switch {
  margin-right: 5px;
  height: 50px;
  border: 5px solid $gray-lighter;
  border-radius: 3px;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.controlsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controlsGroup {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.deleteButton,
.cancelButton {
  font-size: 18px;
  margin: 0 25px;
  padding: 10px;
}

.deleteButton {
  color: $error-red;
  text-decoration: underline;
}

.saveButton {
  width: 90px;
}

.status {
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
  flex-flow: row wrap;
  column-gap: 5px;
}

.statusLabel {
  width: 100%;
}

.bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.tooltip {
  margin-left: 5px;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.navHeader {
  cursor: pointer;
  position: relative;

  .logo {
    width: 100%;

    .expandedLogo {
      position: relative;
      width: 100%;
      height: 70px;
      background-image: $global-org-rect-logo;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .collapsedLogo {
      width: 100%;
      height: 100%;
      background-image: $global-org-square-logo;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &.expanded {
    padding: 0 20px;
    margin-top: 20px;
  }

  &.collapsed {
    .dummy {
      padding-top: 100%;
    }

    .logo {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    background-color: rgbCl($global-sidebar-color);
  }
}

.poweredByContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: -50px;
  right: 60px;

  .text {
    font-size: 15px;
    color: rgbCl($global-sidebar-text-color);
  }

  .image {
    max-height: 40px;
    max-width: 50px;
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

.integration {
  @include container();

  flex-direction: column;

  > div:not(:first-of-type) {
    margin-top: 20px;

    @include max-w(xs) {
      margin-top: 10px;
    }
  }
}

.zapierContainer {
  @include make-fluid('padding-right', 75px, 300px, get-breakpoint-value(xl), get-breakpoint-value(xxxl), 300px, px);

  flex: 1;
  padding: 0;
}

.listRow {
  @include fluidRow();

  height: fit-content;
}

.listCell {
  @include max-w(xs) {
    width: 320px;
    margin: 0 auto 20px;
  }

  @include min-w(get-breakpoint-value(xs) + 1) {
    @include fluidCell();
  }
}

.noIntegrations {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;

  @include max-w(xs) {
    padding: 10px;
  }

  .label {
    @include label();
  }

  .imgWrapper {
    flex: 1;
    display: flex;
    justify-content: center;

    img {
      width: 50%;
      height: 100%;

      @include max-w(xs) {
        width: 100%;
      }
    }
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .infoIcon {
    margin-left: 6px;
  }

  @include max-w(get-breakpoint-value(xxl)) {
    align-items: flex-start;
    flex-direction: column;
  }

  &.readOnly {
    pointer-events: none;
  }
}

.dropdownContent {
  z-index: 101;
  min-width: 300px;

  :global(.reactSelect__option) {
    @include secondary-font();
  }

  :global(.reactSelect__single-value) {
    @include secondary-font();
  }
}

.checkboxContainer {
  @include min-w(get-breakpoint-value(xxl) + 1) {
    span:first-of-type {
      margin-top: 0;
      margin-bottom: 0;
    }

    span:last-of-type {
      top: 40%;
    }
  }
}

.controlsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @include max-w(get-breakpoint-value(xxl)) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.intervalsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .label {
    color: $gray-suit;

    @include secondary-font();
  }

  .daysContainer {
    display: flex;
    gap: 5px;

    .day {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $solitude;
      color: $gray-suit;
      font-weight: 400;
      user-select: none;

      &.selected {
        color: $white;
        background: $deep-sky-blue;
      }

      &:hover {
        cursor: pointer;
      }

      @include make-responsive(
        'font-size',
        (
          xs: 14px,
          xxxl: 18px,
        ),
        18px
      );

      @include max-w(get-breakpoint-value(md)) {
        width: 30px;
        height: 30px;
      }
    }

    @include max-w(get-breakpoint-value(md)) {
      gap: 15px;
    }
  }
}

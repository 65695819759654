@import '../../../variables';
@import '../../../styles/common/mixins';

.shippingForm {
  flex: 1;
}

.inputWrapper {
  @include tiny-scrollbar(6px);

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: row wrap;
  padding-bottom: 20px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.input {
  max-width: 49%;
  margin: 10px 0;

  @include max-w(xs) {
    max-width: 100%;
  }

  &.fullWidth {
    max-width: inherit;
  }
}

.inputField {
  border: 1px solid $gray-suit;
}

.fullWidth {
  width: 100%;
}

.errorBorder {
  @include error-border;
}

.countrySelectContainer {
  @include max-h(750px) {
    :global(.reactSelect__menu) {
      top: 0;
      margin-top: -5px;
      transform: translateY(-100%);
      filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25));
    }
  }
}

.countrySelect {
  position: static;
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.feedbackContainer {
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include max-w(xs) {
    padding: 30px 20px;
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .title {
    font-size: 26px;
    line-height: 1.3;
    font-weight: 700;
  }

  .description {
    @include primary-font();
  }

  .textarea {
    padding: 22px 30px;
    border: 1px solid $gray-suit;
    height: 265px;

    &:focus {
      border-color: $ebony;
    }
  }

  .submit {
    margin-left: auto;
  }
}

.closeBtn {
  position: absolute;
  top: -10px;
  right: 0;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  display: flex;
}

.budgetLeft,
.budgetDelimiter {
  @include primary-font;

  color: $ebony;

  &.budgetLow {
    color: $orange-sun;
  }

  &.outOfBudget {
    color: $error-red;
  }
}

.budgetAvailable {
  @include primary-font;

  color: $budget-green;
}

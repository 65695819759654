@import '../../../variables';
@import '../../../styles/common/mixins';

.zapierKeyForm {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  gap: 2rem;

  @include max-w(md) {
    gap: 1rem;
  }

  .copyBtn {
    align-self: flex-end;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 60px;
    margin: 0 0 0 auto;
    padding: 0;

    @include make-responsive(
      'height',
      (
        xs: 56px,
        md: 50px,
        xxl: 50px,
        xxxl: 64px,
      ),
      64px,
      px
    );
  }

  .input {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    svg {
      right: 15px;
    }

    &.noValue {
      input {
        cursor: default;
      }
    }
  }

  .inputContainer {
    flex: 1 1 33%;
    max-width: calc(33% - 15px);
    margin-bottom: 30px;
    display: flex;
    flex-flow: row;

    ::placeholder {
      color: black;
      opacity: 1;
    }

    @include max-w(md) {
      flex: 1 1 100%;
      max-width: inherit;
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }
    }

    &.button {
      max-width: 170px;

      @include max-w(md) {
        max-width: 100%;
      }

      @include make-responsive(
        'max-height',
        (
          xs: 56px,
          xxl: 50px,
          xxxl: 64px,
        ),
        64px,
        px
      );
    }
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.menuItem {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: rgbCl($global-sidebar-text-color);
  justify-content: flex-start;
}

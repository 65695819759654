@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include secondary-font();

  display: inline;
  height: fit-content;
  width: fit-content;
  max-width: unset;
  text-decoration: underline 0.1em rgba(255, 255, 255, 0);
  transition: text-decoration-color 0.25s ease;

  &:hover {
    &:not(:disabled) {
      text-decoration-color: $gray-suit;
    }

    &::after {
      content: none;
    }
  }
}

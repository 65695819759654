@import '../../variables';
@import '../../styles/common/mixins';

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
  align-items: stretch;
  z-index: 1000;

  @include max-w(xs) {
    z-index: 1100;
  }

  &:global(.integration-warning) {
    @include make-responsive(
      'max-width',
      (
        xxl: 555px,
        xxxl: 700px,
      ),
      700px
    );

    height: auto;

    @include max-landscape-h(xs) {
      max-height: calc(100vh - 60px);
      max-width: calc(100vw - 20px);
    }

    @include max-w(xs) {
      max-height: calc(100vh - 60px);
      max-width: calc(100vw - 20px);
    }
  }

  &:global(.common-modal) {
    max-width: 700px;
    height: auto;
  }

  &:global(.image-library) {
    max-height: 600px;
    height: auto;
    overflow-y: auto;

    @include min-w(xl) {
      max-height: 550px;
    }

    @include max-landscape-h(xs) {
      max-height: calc(100vh - 60px);
      max-width: calc(100vw - 20px);
    }

    @include max-w(xs) {
      max-height: calc(100vh - 60px);
      max-width: calc(100vw - 20px);
    }
  }
}

.default {
  overflow: hidden;
  max-height: 80vh;
  max-width: 1050px;
  height: 100%;
  width: 100%;
  background-color: $bg-color;
  border-radius: 20px;

  @include max-w(xl) {
    max-width: 85vw;
  }

  @include max-w(md) {
    max-height: calc(100vh - 30px);
    max-width: calc(100vw - 30px);
    border-radius: 10px;
  }

  @include max-w(xs) {
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
  }
}

.reset-password {
  width: auto;
  height: auto;
  max-width: inherit;
  max-height: inherit;
  min-width: inherit;
  min-height: inherit;
}

.box-details {
  max-height: 95vh;
  min-height: 600px;

  @include max-w(md) {
    max-height: calc(100vh - 30px);
    max-width: calc(100vw - 30px);
    border-radius: 10px;
  }

  @include max-w(xs) {
    max-height: calc(100vh - 20px);
    max-width: 100vw;
  }

  @include max-landscape-h(xs) {
    min-height: inherit;
  }
}

.rocket {
  max-width: 700px;
  background-color: $white;
  color: $ebony;

  @include max-w(xs) {
    max-height: calc(100vh - 60px);
    max-width: calc(100vw - 20px);
  }
}

.download-success {
  max-width: 700px;
  max-height: 323px;

  @include max-w(md) {
    max-width: calc(100vw - 40px);
    max-height: 250px;
  }

  @include max-w(xs) {
    max-width: calc(100vw - 20px);
  }
}

.low-inventory-items {
  width: 600px;
  display: flex;
  flex-flow: column;

  @include max-landscape-h(xs) {
    overflow-y: auto;
  }
}

.remove-pii {
  width: 650px;
  height: unset;
  overflow: unset;
}

.overlay {
  z-index: 90;
}

.required {
  cursor: default;
}

@import '../../variables';
@import '../../styles/common/mixins';

.itemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  .count {
    flex-shrink: 0;
  }

  .itemDetails {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  @include max-w(xs) {
    gap: 14px;
  }
}

.itemImageContainer {
  display: flex;
  align-items: center;
  position: relative;
  border: 0.5px solid $gray-blue;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 14px;
  width: 80px;
  height: 80px;
  margin-left: 13px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include max-w(xxl) {
    width: 65px;
    height: 65px;
    padding: 12px;
  }
}

.iconInner {
  width: 48px;
  height: 48px;

  .icon {
    width: 100%;
    height: 100%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  @include max-w(get-breakpoint-value(xs)) {
    width: 42px;
    height: 42px;
  }
}

.itemDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 20px;
  max-width: 70%;

  .itemInfoContainer {
    display: flex;
    flex-direction: column;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .itemName {
      @include primary-font();

      color: $ebony;
      padding-bottom: 5px;
      font-weight: 500;

      @include max-w(xs) {
        margin-left: 0;
      }
    }

    .itemOptionsContainer {
      display: flex;
      flex-wrap: wrap;

      .itemOption {
        width: 100%;
        display: flex;
        flex: 50%;
        padding-bottom: 5px;

        .optionName {
          color: $gray-suit;
          width: 60px;

          @include max-w(xs) {
            width: 80px;
          }
        }

        .optionValue {
          justify-content: flex-start;
          white-space: nowrap;

          @include cropped-text();
        }

        @include max-w(xs) {
          flex: 100%;
          font-size: 15px;
        }
      }
    }
  }

  @include max-w(xs) {
    max-width: 70%;
    margin-left: 10px;
  }
}

.itemCountContainer {
  display: flex;
  align-self: center;
}

.count {
  min-width: 40px;
}

.nestedItem {
  @include make-responsive(
    'padding-right',
    (
      xs: 10px,
      xxl: 15px,
      xxxl: 20px,
    ),
    20px
  );
}

.nameGroup {
  display: flex;
  flex-direction: column;
}

.preorderTitle {
  font-size: 14px;
  font-weight: 600;
  color: $budget-green;
}

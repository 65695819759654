@import '../../../variables';
@import '../../../styles/common/mixins';

.addButton {
  max-width: 40px;
  max-height: 40px;
  margin-left: auto;
  border-radius: 3px;

  @include max-w(map-get($breakpoints, xxl)) {
    max-width: 30px;
    height: 30px;
    padding: 0;
  }

  @include max-w(map-get($breakpoints, xs)) {
    max-width: 32px;
    height: 32px;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  background-color: $white;
  border-radius: 10px;
  min-height: 200px;
  max-width: 100%;
  padding: 25px;
  position: relative;

  &:not(.nested) {
    @include min-w(md) {
      margin: 15px 0;
      max-width: 450px;
    }

    @include min-w(lg) {
      max-width: 515px;
      margin: 15px 0;
    }

    @include min-w(xl) {
      margin: 25px 0 25px 0;
    }
  }

  &.nested {
    width: 100%;
  }
}

.loader {
  z-index: 1;
}

.title {
  @include secondary-font();

  font-weight: 600;
  color: $ebony;
  margin-bottom: 1em;
}

.addressLabel {
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: $gray-light;
}

.selector {
  border: 1px solid $gray-suit;
  border-radius: 10px;
  margin-bottom: 25px;
}

.toggle {
  height: 40px;
  margin-bottom: 25px;
}

.toggleSlider {
  border: 1px solid $gray-suit;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.addressBookBtn {
  min-width: 100%;
  margin-bottom: 25px;
  display: flex;

  .icon {
    max-width: 100%;
    max-height: 100%;
  }
}

.infoMessageWrapper {
  display: flex;
  align-items: center;

  & svg {
    width: 18px;
    height: 18px;
  }

  & p {
    margin-left: 10px;
  }
}

.warning {
  color: $orange-header;
}

.controls {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;

  & button {
    max-width: 100px;
    padding: 10px 30px;
  }
}

.cancelButton {
  background-color: transparent;
  color: RGB($default-accent-color);
  margin-right: 20px;

  &:hover {
    box-shadow: none;
    opacity: 0.7;
  }
}

.formTitle {
  @include secondary-font;

  font-weight: 500;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: rgbCl($global-accent-color);
    }

    &:checked ~ .checkmark:after {
      display: block;
    }

    &:disabled {
      ~ .checkmark {
        opacity: 0.5;
      }
    }
  }

  @include max-w(xs) {
    padding-left: 20px;
  }
}

.text {
  @include secondary-font();
}

.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 15px;
  width: 15px;
  background-color: $gray-blue;
  border-radius: 2px;

  @include max-w(xs) {
    height: 10px;
    width: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @include max-w(xs) {
      left: 3px;
      top: 1px;
      width: 2px;
      height: 5px;
    }
  }
}

.hint {
  margin-left: 5px;
}

.readOnly {
  pointer-events: none;
}

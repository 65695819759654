@import '../../variables';

.container {
  position: relative;
  display: flex;
  z-index: 1;
}

.link {
  text-decoration: underline;
  color: $ebony;
}

.toggle {
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $ebony;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  padding-top: 2px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  color: $white;
  cursor: default;

  &:hover {
    ~ .info {
      display: block;
    }
  }
}

.info {
  overflow: hidden;
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  padding: 1.2em 1.2em 2em;
  box-shadow: $deep-blurry-shadow;
  transition: all 0.25s ease;
  background: $bg-color;
}

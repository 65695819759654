@import '../../variables';
@import '../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;

  @include max-w(xs) {
    border: 1px solid $gray-blue;
    padding: 10px;
  }
}

.label {
  @include label();
}

.main {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-blue;
  border-radius: 10px;
  padding: 40px;

  @include make-responsive(
    'padding',
    (
      xxl: 30px,
      xxxl: 40px,
    ),
    40px,
    px
  );

  @include max-w(xs) {
    border: none;
    padding: 0;
  }
}

.toggleContainer {
  display: flex;
  flex-direction: column;

  @include max-w(xs) {
    flex-direction: row;
    align-items: center;
  }
}

.helperText {
  @include primary-font();

  font-weight: 500;
  display: flex;
  margin-bottom: 20px;

  @include max-w(xxl) {
    margin-bottom: 10px;
  }

  @include max-w(xs) {
    margin-bottom: 0;
    margin-right: 20px;
  }

  svg {
    margin-left: 10px;
    margin-top: 2px;

    @include max-w(xxl) {
      margin-top: -1px;
    }
  }
}

.toggle {
  position: relative;
}

.selector {
  @include make-responsive(
    'margin-top',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

@import '../../variables';
@import '../../styles/common/mixins';

$item-max-width: 243px;

.boxList {
  @include container;

  flex-flow: column;
  max-height: 100%;
  flex: 1;

  @include max-w(xs) {
    padding: 10px 0;
  }
}

.listRow {
  @include fluidRow;
  @include tiny-scrollbar($width: 1rem);

  overflow-y: auto;
  padding-top: 3px;

  @include max-w(xs) {
    padding: 20px 10px;
  }
}

.header {
  @include max-w(xs) {
    overflow-x: hidden;
    min-height: 62px;
    padding: 0 10px;
  }

  & > div {
    transition: transform 0.25s ease;
    transform: translateX(0);
  }

  &.enabled {
    & > div {
      transform: translateX(-100%);
    }
  }
}

.headerAdditionalContent {
  display: flex;
  align-items: center;
  gap: 10px;

  .suggestGiftBtn {
    padding: 15px 30px;
    border-radius: 50px;
    background: linear-gradient(92.88deg, #00aeff 6.93%, #ad00ff 99.66%);
    box-shadow: 0 0 20px 0 #002c6e33;
  }

  .reloadButton {
    margin-right: 20px;
    width: 52px;
    height: 52px;

    @include max-w(xxl) {
      width: 40px;
      height: 40px;
    }

    @include max-w(xs) {
      margin-right: 30px;

      &.pygEnabled {
        transform: translateX(100%);
        margin-right: 0;
      }
    }

    .reloadIcon {
      flex-shrink: 0;
      height: 20px;
      width: 20px;

      @include max-w(xxl) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.itemWrapper {
  @include fluidCell;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: $item-max-width;
  }

  &.disabled {
    &:hover .item {
      pointer-events: none;
    }

    .item {
      opacity: 0.5;
    }

    &:not(.loading) .countComponent {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  &.loading {
    .tooltipContainer {
      pointer-events: none;
      opacity: 0.5;
    }

    .countComponent {
      opacity: 1;
    }
  }

  .item {
    @include max-w(xs) {
      max-width: unset;
    }

    @include min-w(get-breakpoint-value(xxl) + 1) {
      max-width: unset;
    }
  }

  .itemCountWrapper {
    transition: all 0.25s ease;
    width: calc(100% - 2px);

    @include min-w(get-breakpoint-value(xxl) + 1) {
      width: calc(100% - 15px);
    }
  }

  .countComponent {
    transition: 0.3s ease;
  }

  .tooltipContainer {
    transition: 0.3s ease;
    height: auto;
    @include max-w(xxl) {
      top: 10px;
      right: 10px;
    }

    @include max-w(xxl) {
      top: 5px;
      right: 5px;
    }
  }

  .previewBox {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .previewContent {
    margin-bottom: 10px;
  }

  .icon {
    @include max-w(xxl) {
      border-radius: 10px;
    }

    @include max-w(xs) {
      margin-bottom: 20px;
      max-width: unset;
      max-height: unset;
    }
  }
}

.pygBtnWrapper {
  @include max-w(xs) {
    width: 100%;
    height: unset;
  }

  .btnText {
    width: 0;
    transition: 0.3s ease;

    @include max-w(xs) {
      transition: unset;
      width: 100%;
    }
  }

  .btnIcon {
    fill: $white;
    width: 100%;
    transition: 0.3s ease;

    @include make-responsive(
      'height',
      (
        xxl: 15.5px,
        xxxl: 20px,
      ),
      20px
    );

    @include max-w(xs) {
      width: 0;
    }
  }

  &:hover {
    .btnIcon {
      width: 0;
    }

    .btnText {
      width: 100%;
    }
  }
}

.pygBtn {
  transition: 0.3s ease;
  height: 100%;
  box-shadow: 0 0 20px rgba($midnight-blue, 0.2);
  overflow: hidden;

  @include make-responsive(
    'width',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px
  );

  @include make-responsive(
    'height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px
  );

  @include max-w(xs) {
    width: 262px;
    height: 56px;
    box-shadow: none;
    margin: auto;
  }

  &:hover {
    @include min-w(get-breakpoint-value(xs) + 1) {
      @include make-responsive(
        'width',
        (
          xxl: 232px,
          xxxl: 298px,
        ),
        298px
      );
    }
  }
}

.pagination {
  @include make-responsive(
    'margin-top',
    (
      xs: 20px,
      xxl: 30px,
    ),
    30px
  );

  margin-right: calc(var(--grid-item-margin) / 2);
}

.filter {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
  gap: 5px;

  @include max-w(xs) {
    padding-top: 10px;
    flex-wrap: wrap;
  }

  @include max-w(md) {
    margin-bottom: 15px;
  }

  .dropdownLabel {
    @include secondary-font();
  }

  .dropdownFilter {
    position: relative;
    margin-right: 15px;

    @include max-w(xs) {
      margin-top: 10px;
    }

    .dropdownContent {
      width: 335px;
      z-index: 2;

      @include max-w(md) {
        top: 140%;
        width: 280px;
      }

      @include max-w(xs) {
        transform: unset;
      }
    }
  }

  .searchByFilterContainer {
    margin-bottom: 10px;
  }

  .commonFilters {
    display: flex;
  }
}

.searchByFilterContainer {
  padding: 0 10px 0 0;
  margin-left: -10px;

  @include max-w(md) {
    padding: 0;
    margin-left: 0;
    width: 100%;
    max-width: unset;
  }
}

.wrapper {
  width: 100%;

  @include max-w(xs) {
    box-shadow: $element-shadow;
    padding: 0 10px;
  }

  @include min-w(get-breakpoint-value(xs) + 1) {
    padding: 0 15px 0.5em;
    margin-bottom: calc(40px - 0.5em);
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

.userBudget {
  position: relative;
  display: flex;
  font-weight: 500;
  align-items: baseline;
  margin-right: 50px;

  @include max-w(lg) {
    margin-right: 20px;
  }
}

.current {
  @include card-title-font();
}

.available {
  @include secondary-font();

  white-space: pre-wrap;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background: $bg-color;
  padding: 60px 0;

  @include max-w(md) {
    padding: 30px 0;
  }

  @include max-landscape-h(xs) {
    padding: 15px 0 10px;
  }
}

.header,
.content,
.footer {
  padding: 0 50px;
}

.content {
  @include tiny-scrollbar;

  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-flow: column;

  .opportunitiesWrapper {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 10px;

    .opportunities {
      padding: 10px;

      .item {
        @include primary-font();

        width: 100%;
        padding: 20px;
        justify-content: space-between;
        border-radius: 10px;

        &.selected,
        &:hover {
          background-color: $bg-color;
        }
      }
    }
  }
}

.back {
  margin-right: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  align-self: center;
  width: 100%;
}

@import '../../variables';
@import '../../styles/common/mixins';

@mixin small-loader-size($size) {
  width: unset;
  height: unset;

  &:after :global {
    width: $size;
    height: $size;
    border-width: 3px;
    margin: 0;
  }
}

.loader {
  position: absolute;
  z-index: 900;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;

  .spinner {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after :global {
      content: ' ';
      display: block;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 6px solid;
      border-color: rgbCl($global-sidebar-color) transparent rgbCl($global-accent-color) transparent;
      animation: spinner 1.2s linear infinite;
    }

    &.medium {
      @include small-loader-size(25px);
    }

    &.small {
      @include small-loader-size(20px);
    }

    &.tiny {
      @include small-loader-size(12px);
    }
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  padding-top: 10px;
}

.section {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  margin: 0 -10px;

  > * {
    padding: 0 10px;
  }

  &:not(:first-of-type) {
    margin-top: 20px;
  }

  @include max-w(xs) {
    margin: auto;

    > * {
      padding: unset;
    }
  }
}

.subtitle {
  @include primary-font(
    (
      xs: 18px,
      xl: 21px,
    )
  );

  width: 100%;
  font-weight: bold;
  margin-bottom: 1em;

  @include max-w(xs) {
    margin-bottom: 0.5em;
  }
}

.inputContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: 406px;
  margin-bottom: 20px;

  .switchItem {
    margin: 120px;
  }

  @include max-w(xxl) {
    max-width: 289px;
  }

  @include max-w(md) {
    max-width: inherit;
    align-self: stretch;
  }

  @include max-w(xs) {
    margin: 10px 0;
  }
}

.orgCount {
  display: flex;
  flex-direction: column;
}

.organizationsList {
  @include primary-font();
  @include make-responsive(
    'height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );

  display: flex;
  align-items: center;

  @include max-w(xs) {
    height: 56px;
  }
}

.tooltip {
  margin-left: 10px;

  @include max-w(xs) {
    margin-left: 5px;
  }
}

.errorBorder {
  @include error-border;
}

.resetPassword {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  max-width: unset;

  @include max-w(xs) {
    align-items: center;
    margin-top: 10px;

    .resetPasswordButton {
      max-width: 100%;
    }
  }
}

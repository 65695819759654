@import '../../variables';
@import '../../styles/common/mixins';

.addressSelect {
  border: 1px solid $gray-suit;
}

.controls {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  margin-top: 20px;

  @include max-w(xs) {
    padding: 0;
  }
}

.cancelButton {
  background-color: transparent;
  color: RGB($default-accent-color);

  &:hover {
    box-shadow: none;
    opacity: 0.7;
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 80;
  padding: 26px 0 15px;
  font-size: 12px;
  color: $gray-suit;
  box-shadow: 0 -4px 10px rgba(219, 222, 235, 0.8);

  @include max-w(xl) {
    padding: 10px 0;
  }

  .link {
    text-decoration: underline;
    color: $gray-suit;
  }
}

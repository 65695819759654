@import '../../../../variables';
@import '../../../../styles/common/mixins';

:root {
  --toggle-size: 30px;
  --slider-size: 73px;

  @include max-w(xs) {
    --toggle-size: 22px;
    --slider-size: 54px;
  }
}

.toggle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &.disabled {
    cursor: default;

    .switch {
      opacity: 0.5;
    }
  }

  &.inverse {
    input:checked + .slider {
      background-color: $white;

      &:before {
        background-color: rgbCl($global-accent-color);
      }

      &:after {
        color: $white;
      }
    }
  }

  &.compact {
    .slider {
      &:after {
        content: none !important;
      }
    }
  }
}

.toggleInner {
  flex: 1;
  display: flex;
  flex-flow: row;
  align-items: center;
}

.switch {
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;

  @include max-w(xs) {
    height: 30px;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;

    &:not(:checked) + .slider {
      &:before,
      &:after {
        transform: translate(55%, -50%);
      }
    }

    &:checked + .slider {
      cursor: pointer;
      background: rgbCl($global-accent-color);

      &:before,
      &:after {
        transform: translate(-55%, -50%);
      }

      &:before {
        background-color: $white;
      }

      &:after {
        color: $black;
        content: 'ON';
      }
    }

    &:disabled + .slider {
      cursor: not-allowed;

      :before {
        background-color: $gray-suit;
      }
    }

    &:disabled:checked + .slider {
      opacity: 0.7;
    }

    &:disabled:not(:checked) + .slider {
      &:before {
        opacity: 0.7;
      }
    }
  }
}

.slider {
  min-width: var(--slider-size);
  max-width: var(--slider-size);
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: $white;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-right: 5px;

  &:disabled {
    cursor: auto;
  }

  &:before,
  &:after {
    position: absolute;
    width: var(--toggle-size);
    left: calc(var(--slider-size) / 2 - var(--toggle-size) / 2);
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;

    @include max-w(xs) {
      bottom: 5px;
    }
  }

  &:before {
    content: '';
    background-color: $gray-suit;
    border-radius: 5px;
    height: var(--toggle-size);
  }

  &:after {
    content: 'OFF';
    color: $white;
    font-size: 12px;

    @include max-w(xs) {
      font-size: 9px;
    }
  }
}

.sliderInfo {
  @include primary-font();

  display: flex;
  align-items: center;
  position: relative;
  margin: 0 5px;
  font-weight: 500;
  align-self: stretch;

  .status {
    width: 1.8em;
    margin-top: 0.25em;
    text-align: center;
  }

  .text {
    text-align: center;
    margin-left: 10px;
  }
}

.tooltip {
  padding: 0 !important;
  border-radius: 10px !important;
  border: none !important;
  opacity: 1 !important;
  box-shadow: 0 4px 20px 0 #aeb1bc !important;

  &:after {
    border-top-color: #f4f5f9 !important;
  }
}

.tooltipIcon {
  margin-left: 5px;
}

.helpInfo {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 5px;

  &:hover .helpText {
    display: inherit;
  }

  @include max-w(xs) {
    svg {
      height: 10px;
      width: 10px;
    }
  }

  .helpText {
    transition: 0.2s ease;
    padding: 20px;
    color: $ebony;
    font-size: 16px;
    width: max-content;
    max-width: 350px;
    background: $bg-color;
    border-radius: 3px;
  }
}

@import '../../styles/common/mixins';

.wrapper {
  margin-left: 20px;
  max-width: 300px;
}

.name {
  @include primary-font();

  font-weight: 600;
}

.organization {
  @include cropped-text(1);
  @include secondary-font();
}

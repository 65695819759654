@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include secondary-font;

  font-weight: 600;
}

.label {
  font-weight: normal;
  color: $gray-suit;
  margin-right: 0.25em;
}

.count {
  font-weight: normal;
  display: inline-flex;
  color: $ebony;
  cursor: default;

  &:first-child {
    margin-left: 0.25em;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xxxl: 18px,
    ),
    18px
  );

  @include max-w(xs) {
    color: $white;
  }
}

.icons {
  flex-shrink: 0;
  position: relative;

  @include max-w(xs) {
    margin-right: 10px;
  }
}

.label {
  text-align: left;
  width: 100%;
}

.alert {
  position: absolute;
  height: 10px;
  width: 10px;
  right: -6px;
  bottom: 2px;
  border-radius: 50%;

  @include max-w(xs) {
    position: absolute;
    height: 10px;
    width: 10px;
    right: -5px;
    bottom: 3px;
    border: 1px solid $white;
    border-radius: 50%;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include make-responsive(
    '--item-icon-width',
    (
      xxl: 114px,
      xxxl: 120px,
    ),
    120px
  );

  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;

  .list {
    @include tiny-scrollbar();
    @include error-border;

    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 10px;
    overflow: auto;

    @include make-responsive(
      'padding',
      (
        xxl: 20px,
        xxxl: 30px,
      ),
      30px
    );

    &.empty {
      border-color: $gray-blue;
    }

    .itemWrapper {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .itemCountWrapper {
        width: var(--item-icon-width);
      }

      .iconInner {
        width: var(--item-icon-width);
      }

      .countContainer {
        border-radius: 10px;
      }

      .tooltipContainer {
        right: 7px;
        top: 5px;
      }
    }
  }

  .placeholder {
    height: 100%;

    .image {
      @include make-responsive(
        'margin',
        (
          xxl: 35px,
          xxxl: 55px,
        ),
        55px
      );
    }
  }
}

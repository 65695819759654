@import '../../variables';
@import '../../styles/common/mixins';

.flowPickerItem {
  @include squared-element(243px);

  flex-direction: column;
  color: $ebony;
  margin-bottom: 15px;

  .title {
    @include max-w(xl) {
      text-overflow: unset;
      white-space: unset;
    }
  }
}

.title {
  margin-bottom: 0.41665em;
}

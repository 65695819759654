@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.container {
  @include tiny-scrollbar(4px);

  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;
  overflow-y: auto;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    padding: 30px 0;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 474px;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.wrapper {
  padding: 0 50px;
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;

  @include max-w(xs) {
    padding: 50px;
  }

  .closeBtn {
    position: absolute;
    top: 0;
    right: 30px;
    width: 15px;
    height: 15px;
    transition: transform 0.25s ease;

    @include max-w(xxl) {
      right: 20px;
    }

    @include max-w(xs) {
      top: 25px;
      right: 15px;
    }

    &:hover {
      transform: scale(1.15);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      left: 50%;
      transform-origin: center;
      background: $gray-blue;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

// Default theme colors
// Default color values should be used in RGB
$default-accent-color: 95, 17, 234; // #5f11ea
$default-sidebar-color: 33, 33, 33; // #212121
$default-sidebar-text-color: 33, 33, 33; // #212121
$default-org-rect-logo: url('/assets/images/ie_rect_logo.svg');
$default-org-square-logo: url('/assets/images/ie_square_logo.svg');

// Colors
$black: #000000;
$white: #ffffff;
$ebony: #212121;
$text-ebony: #403c3c;
$orange-sun: #f9b218;
$orange-header: #faaa1d;
$midnight-blue: #002c6e;
$deep-purple: #5f11ea;
$deep-sky-blue: #00aeff;
$light-navy: #6f8bde;
$light-blue: #698dbc;
$salesforce-blue: #0092da;
$viking-blue: #41a7cc;
$aqua: #84dcc6;
$blush-pink: #e6607f;
$gmail-red: #d54a3d;
$hubspot-orange: #ff7a59;
$error-red: #ff0017;
$success-green: #4ec669;
$gray-blue: #bbc2d3;
$light-pink: #f3cece;

$gray-blue-light: #e4e5ea;
$gray-aero: #e4e8f1;
$gray-suit: #9197a5;
$gray-light: #95979b;
$shadow-gray: #aeb1bc;
$gray-ebony: #a3abbe;
$gray-lighter: #f6f6f6;
$gray-table: #cfcfcf;
$shady: #dbdeeb;
$selected-input-blue: #688dde;
$bg-color: #f6f6f6;
$bg-light-blue: #e4e7f0;
$cornflower-blue: #6a8fdc;
$modal-overlay: rgba(#111215, 0.4);
$budget-green: #38ca60;
$medium-purple: #957fd1;
$tangerine: #ff7a00;
$dark-overlay: rgba(#2c2d31, 0.5);
$solitude: #e2e5eb;
$outlook-blue: #0364b8;
$dark-gray-table: #545f71;
$dark-gray-table: #545f71;
$table-disabled: #c2c6cf;

// Shadows
$deep-blurry-shadow: 0 8px 20px $shady;
$tooltip-shadow: 0 4px 20px 0 rgba($shadow-gray, 0.5);
$element-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
$input-shadow: 0 10px 20px rgba($gray-suit, 0.1);
$mobile-floating-shadow: 0px -4px 10px rgba($shady, 0.8);
$transparent-shadow: 0 0 0 transparent;

// Fonts
$ff-gilroy: 'Gilroy', Arial, sans-serif;
$ff-cormorant: 'Cormorant', 'Arial Black', sans-serif;

// Elements
$squared-element-max-size: 243px;
$mobile-item-icon-width: 124px;

// Sidebars
$mobile-nav-height: 70px;
$nav-desktop-min-width: 74px;
$nav-desktop-min-widthXXL: 74px;
$nav-desktop-max-width: 300px;
$nav-mobile-min-width: 0px;
$nav-mobile-max-width: 259px;

$org-sidebar-min-width: 0px;
$org-sidebar-max-widthHD: 679px;
$org-sidebar-max-widthFullHD: 938px;
$org-sidebar-min-height: 0vh;
$org-sidebar-max-height: 100vh;

$automation-sidebar-min-width: 0px;
$automation-sidebar-max-widthHD: 700px;
$automation-sidebar-max-widthFullHD: 700px;
$automation-sidebar-min-height: 0vh;
$automation-sidebar-max-height: 100vh;

$box-sidebar-min-width: 0px;
$box-sidebar-min-height: 0vh;
$box-sidebar-max-height: 100vh;
$box-sidebar-max-widthHD: 649px;
$box-sidebar-max-widthFullHD: 737px;

$feedback-sidebar-min-width: 0px;
$feedback-sidebar-max-width: 466px;
$feedback-sidebar-min-height: 0vh;
$feedback-sidebar-max-height: 100vh;

$suggest-gift-sidebar-min-width: 0px;
$suggest-gift-sidebar-max-width: 742px;
$suggest-gift-sidebar-min-height: 0vh;
$suggest-gift-sidebar-max-height: 100vh;

$breakpoints: (
  xxs: 320px,
  xs: 576px,
  md: 768px,
  lg: 960px,
  xl: 1140px,
  xxl: 1366px,
  xxxl: 1800px,
);

// Global colors
$global-accent-color: var(--accent-color, $default-accent-color);
$global-sidebar-color: var(--sidebar-color, $default-sidebar-color);
$global-sidebar-text-color: var(--sidebar-text-color, $default-sidebar-text-color);
$global-org-rect-logo: var(--org-rect-logo, $default-org-rect-logo);
$global-org-square-logo: var(--org-square-logo, $default-org-square-logo);

// Export data to ts files
:export {
  breakpointMobileMin: map-get($breakpoints, xs);
  breakpointMobile: map-get($breakpoints, xs);
  breakpointMedium: map-get($breakpoints, md);
  breakpointDesktop: map-get($breakpoints, lg);
  breakpointLargeDesktop: map-get($breakpoints, xl);
  breakpointHD: map-get($breakpoints, xxl);
  breakpointFullHD: map-get($breakpoints, xxxl);
  graySuit: $gray-suit;
  bgColor: $bg-color;
  navDesktopMinWidth: $nav-desktop-min-width;
  navDesktopMinWidthHD: $nav-desktop-min-widthXXL;
  navDesktopMaxWidth: $nav-desktop-max-width;
  navMobileMinWidth: $nav-mobile-min-width;
  navMobileMaxWidth: $nav-mobile-max-width;
  orgSidebarMinWidth: $org-sidebar-min-width;
  orgSidebarMaxWidthHD: $org-sidebar-max-widthHD;
  orgSidebarMaxWidthFullHD: $org-sidebar-max-widthFullHD;
  orgSidebarMinHeight: $org-sidebar-min-height;
  orgSidebarMaxHeight: $org-sidebar-max-height;
  automationSidebarMinWidth: $automation-sidebar-min-width;
  automationSidebarMaxWidthHD: $automation-sidebar-max-widthHD;
  automationSidebarMaxWidthFullHD: $automation-sidebar-max-widthFullHD;
  automationSidebarMinHeight: $automation-sidebar-min-height;
  automationSidebarMaxHeight: $automation-sidebar-max-height;
  feedbackSidebarMinWidth: $feedback-sidebar-min-width;
  feedbackSidebarMaxWidth: $feedback-sidebar-max-width;
  feedbackSidebarMinHeight: $feedback-sidebar-min-height;
  feedbackSidebarMaxHeight: $feedback-sidebar-max-height;
  suggestGiftSidebarMinWidth: $suggest-gift-sidebar-min-width;
  suggestGiftSidebarMaxWidth: $suggest-gift-sidebar-max-width;
  suggestGiftSidebarMinHeight: $suggest-gift-sidebar-min-height;
  suggestGiftSidebarMaxHeight: $suggest-gift-sidebar-max-height;
  boxSidebarMinHeight: $box-sidebar-min-height;
  boxSidebarMaxHeight: $box-sidebar-max-height;
  boxSidebarMinWidth: $box-sidebar-min-width;
  boxSidebarMaxWidthHD: $box-sidebar-max-widthHD;
  boxSidebarMaxWidthFullHD: $box-sidebar-max-widthFullHD;
  defaultOrgRectLogo: $default-org-rect-logo;
  defaultOrgSquareLogo: $default-org-square-logo;
  errorRed: $error-red;
  budgetGreen: $budget-green;
  orangeSun: $orange-sun;
  grayEbony: $gray-ebony;
  // TODO: Changed the color structure???
  // https://stackoverflow.com/questions/56523785/exporting-scss-variables-to-js-auto-looping-variables
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.title {
  @include primary-font();

  color: $gray-suit;
  margin-bottom: 10px;

  @include max-w(xs) {
    font-size: 14px;
  }
}

.content {
  padding: 40px;
  border: 1px solid $gray-blue;
  border-radius: 10px;

  @include max-w(xs) {
    padding: 20px 10px;
  }
}

.result {
  padding-bottom: 20px;
  color: $gray-suit;

  &:not(:first-of-type) {
    border-top: 1px solid $gray-blue;
    padding-top: 30px;

    @include max-w(xs) {
      padding-top: 20px;
    }
  }
}

.hint {
  font-size: 16px;
  color: $error-red;
  line-height: 1.15;

  @include max-w(xs) {
    max-width: inherit;
    width: 100%;
  }
}

.action {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgbCl($global-accent-color);
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: $white;
  min-width: 42px;
  max-width: 42px;
  min-height: 42px;
  max-height: 42px;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 10px 20px transparent;
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 0 10px 20px rgbCl($global-accent-color, 0.4);
    color: $text-ebony;
  }

  > img {
    width: 100%;
  }

  @include max-w(xs) {
    padding: 10px;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
  }
}

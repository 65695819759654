@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.iconInner {
  @include make-responsive(
    'margin-right',
    (
      xxl: 15px,
      xxxl: 20px,
    ),
    20px
  );

  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 10px;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .countValue {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.icon {
  position: absolute;
  width: 70%;
  height: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.meta {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  margin: 0 15px;
}

.price {
  @include card-title-font();
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 10px,
      xxxl: 15px,
    ),
    15px
  );

  color: $ebony;
}

.title {
  @include primary-font();

  width: 100%;
  margin-top: 5px;
  font-weight: 500;
  line-height: 1.15;
  color: $ebony;
  text-align: left;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sku {
  @include secondary-font;

  width: fit-content !important;
  text-transform: uppercase;
  align-self: flex-start;

  &:hover {
    color: $gray-suit;
  }
}

.countHint {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.countHintContent {
  text-align: left;
  color: $tangerine;
  font-family: $ff-gilroy;
}

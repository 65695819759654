@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  background-color: $white;
  cursor: pointer;

  @include make-responsive(
    'padding-bottom',
    (
      xxl: 20px,
      xxxl: 37px,
    ),
    37px,
    px
  );

  @include make-responsive(
    'height',
    (
      xxl: 268px,
      xxxl: 338px,
    ),
    338px,
    px
  );

  &:not(:last-of-type) {
    @include make-responsive(
      'margin-bottom',
      (
        xxl: 10px,
        xxxl: 20px,
      ),
      20px,
      px
    );
  }

  .image {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;

    @include make-responsive(
      'height',
      (
        xxl: 120px,
        xxxl: 144px,
      ),
      144px,
      px
    );

    @include make-responsive(
      'margin-bottom',
      (
        xxl: 10px,
        xxxl: 20px,
      ),
      20px,
      px
    );
  }

  .postDate {
    @include secondary-font();
    color: $gray-suit;

    margin-bottom: 10px;
  }

  .title {
    font-weight: 600;
    @include make-responsive(
      'font-size',
      (
        xxl: 21px,
        xxxl: 24px,
      ),
      24px,
      px
    );
  }
}

.loadMoreBtn {
  margin-top: 30px;
  align-self: center;

  @include make-responsive(
    'height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.uploadInput {
  @include primary-font();

  width: 100%;
  height: 100%;
  position: relative;
  border: 2px dashed #00aeff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  color: $gray-suit;
  text-align: center;

  &.error {
    border-color: $error-red;
  }

  &.readOnly {
    pointer-events: none;
  }

  svg {
    margin-bottom: 30px;

    @include max-w(xs) {
      max-width: 41px;
    }

    &.activeDrag {
      border-color: #00ff2a;
    }
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 10px;
}

.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.spinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid;
  border-color: rgbCl($global-sidebar-color) transparent rgbCl($global-accent-color) transparent;
  animation: spinner 1.2s linear infinite;
}

.errorMessage {
  @include secondary-font(
    (
      xxl: 12px,
      xxxl: 16px,
    )
  );

  position: absolute;
  bottom: 0;
  color: $error-red;

  @include max-w(xs) {
    display: none;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.dropdownContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.dropdownWithSpinner {
  position: relative;
  z-index: 10;
  display: flex;
  background: $white;
  border-radius: 10px;
  height: 60px;

  .lds-ellipsis {
    height: auto;

    > div {
      top: -5px;
    }
  }
}

.list {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 10px;
  background: $white;
  border-radius: 6px;
  box-shadow: 0 8px 20px #dbdeeb;
  overflow: hidden;
}

.item {
  @include primary-font();

  font-weight: normal;
  padding: 1rem 20px;
  transition: all 0.25s ease;
  justify-content: flex-start;
  border-radius: 10px;

  &:hover {
    background: $bg-color;
  }

  @include max-w(xs) {
    padding: 1rem 10px;
  }
}

.value {
  @include primary-font();

  width: 100%;
  padding: 0 30px;
  justify-content: flex-start;
  border-radius: 10px;
  transition: all 0.25s ease;

  @include max-w(xs) {
    padding: 0 15px;
  }

  &:disabled {
    background: lighten($bg-color, 2.5%);
    color: rgba(43, 47, 56, 0.2);
    user-select: none;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    border-width: 7px;
    border-color: #bbc2d3 transparent transparent transparent;
    border-style: solid;
    top: 50%;
    right: 15px;
    transform: translateY(-25%);
    transform-origin: center center;
    transition: 0.3s ease;
  }
}

.opened {
  &:after {
    transform: translateY(-80%) rotate(-180deg);
  }
}

@mixin input-placeholder() {
  &::-webkit-input-placeholder {
    color: $white;
    font: 0.875rem $font-primary;
    opacity: $placeholderOpacity;
  }
  &::-moz-placeholder {
    color: $white;
    font: 0.875rem $font-primary;
    opacity: $placeholderOpacity;
  }
  &:-ms-input-placeholder {
    color: $white;
    font: 0.875rem $font-primary;
    opacity: $placeholderOpacity;
  }
  &:-moz-placeholder {
    color: $white;
    font: 0.875rem $font-primary;
    opacity: $placeholderOpacity;
  }
}

@mixin skeleton-placeholder {
  position: relative;
  overflow: hidden;
  background-color: $bg-color;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($gray-blue-light, 0) 0,
      rgba($gray-blue-light, 0.2) 20%,
      rgba($gray-blue-light, 0.5) 60%,
      rgba($gray-blue-light, 0)
    );
    animation: shimmer 2s infinite;
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  @include tiny-scrollbar;

  @include make-responsive(
    'min-height',
    (
      xs: 392px,
      xxl: 475px,
      xxxl: 466px,
    ),
    18px
  );

  overflow-y: auto;
  display: flex;
  flex-flow: column;
  align-items: center;

  .header,
  .content,
  .footer,
  .text {
    padding-left: 50px;
    padding-right: 50px;

    @include max-w(xxl) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include max-w(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .content,
  .text {
    width: 100%;
  }

  .header {
    position: relative;
    padding-top: 60px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    @include max-w(xxl) {
      padding-top: 40px;
    }

    @include max-w(xs) {
      margin-bottom: 15px;
    }

    .closeBtn {
      position: absolute;
      top: 40px;
      right: 40px;

      @include max-w(xxl) {
        top: 20px;
        right: 20px;
      }

      @include max-w(xs) {
        top: 15px;
        right: 15px;
      }
    }
  }

  .text {
    font-size: 18px;
    font-weight: 400;
    padding-bottom: 20px;
    max-width: 600px;
    text-align: center;
  }

  .content {
    margin-bottom: 30px;
    flex: 1;

    @include max-w(xxl) {
      margin-bottom: 20px;
    }

    .select {
      border-radius: 10px;

      [class^='reactSelect__menu'] {
        position: relative;
        padding: 0;
        margin-top: 5px;
      }
    }
  }

  .footer {
    margin-bottom: 34px;

    @include max-w(xxl) {
      margin-bottom: 40px;
    }

    @include max-w(xxl) {
      margin-bottom: 30px;
    }

    justify-content: center;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';
@import '../../styles/common/animations';

.container {
  position: relative;

  .bagIcon {
    @include make-responsive(
      'width',
      (
        xs: 20px,
        xl: 25px,
      ),
      25px
    );
  }

  .countValueContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translate(50%, -50%);
    border: 1px transparent;
    border-radius: 10px;

    @include make-responsive(
      'height',
      (
        xs: 16px,
        xl: 22px,
      ),
      22px
    );
    @include make-responsive(
      'width',
      (
        xs: 16px,
        xl: 22px,
      ),
      22px
    );

    @include make-responsive(
      'top',
      (
        xs: 2px,
        xl: 0,
      ),
      0
    );

    @include make-responsive(
      'right',
      (
        xs: -2px,
        xl: -3px,
      ),
      -3px
    );

    @include pulse-animation;
  }

  &:hover {
    cursor: pointer;
  }
}

.countValue {
  @include micro-font();

  vertical-align: middle;
  color: $white;
}

.disabled {
  opacity: 0.5;

  &:hover {
    cursor: unset;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.additionalSummaryForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textarea {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: 30px;
  min-height: 175px;

  @include max-w(md) {
    margin-bottom: 20px;
  }
}

.businessReasonContainer {
  position: relative;
}

.buttonsContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  button {
    @include secondary-font();
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 20px;
}

@import '../../variables';
@import '../../styles/common/mixins';

$table-min-height-default: 135px;

.container {
  flex: 1 1 0;
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  position: relative;
  border-radius: 10px;
  border: 1px solid $gray-table;
}

.body {
  @include tiny-scrollbar($width: 4px, $thumb-color: $gray-table, $track-color: transparent);

  flex: 1;
  flex-basis: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

.row {
  position: relative;
  display: grid;
  grid-template-columns: 70px 1fr 120px 70px;
  align-items: center;
  transition: all 0.25s ease;

  &.selected {
    background-color: rgbCl($global-accent-color, 0.1);
  }

  &:not(.disabledRow):hover {
    background-color: $bg-light-blue;
  }
}

.cell {
  @include cropped-text();

  padding: 15px;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $table-min-height-default;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  color: $gray-suit;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  max-height: 100%;
  height: calc(100% - 20vh);

  @include max-w(lg) {
    flex-direction: column;
    max-height: unset;
    height: auto;
  }
}

.addedItemsWrapper {
  display: flex;
  flex-flow: column;
  max-height: 100%;
  flex: 1;

  &.empty {
    height: 100%;

    .content {
      margin: auto;
    }
  }

  .content {
    @include tiny-scrollbar;

    overflow-y: auto;
    padding: 10px 10px 20px 0;

    .addedItem {
      display: flex;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 30px;
      }

      @include max-w(lg) {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }

      .quantityInputWrapper,
      .inventoryItem {
        flex: 1;
      }

      .inventoryItem {
        @include max-w(xl) {
          flex-direction: row;
        }

        @include max-w(xs) {
          width: 100%;
        }

        .iconInner {
          margin-bottom: 0;

          @include min-w(xxl) {
            min-width: 218px;
            max-width: 218px;
            min-height: 218px;
            max-height: 218px;
          }

          @include min-w(xs) {
            min-width: 140px;
            max-width: 140px;
            min-height: 140px;
            max-height: 140px;
          }

          @include max-w(xs) {
            margin-right: 10px;
            flex: 0;
          }
        }
      }

      .addedItemControls {
        > button {
          @include max-w(xs) {
            width: unset;
            height: unset;
          }
        }
      }

      .quantityInputWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .hideCheckboxContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .hideTooltipContainer {
            margin-left: 10px;
          }

          .helpHideTooltipContent {
            padding: 10px 20px;
            font-size: 16px;
          }
        }

        @include max-w(xs) {
          flex-direction: column;
          gap: 10px;
        }
      }

      & > div:not(:last-of-type) {
        @include make-responsive(
          'margin-right',
          (
            xl: 33px,
            xxxl: 60px,
          ),
          60px
        );

        @include max-w(xl) {
          margin: 0 0 15px;
        }
      }
    }
  }

  .iconInner {
    @include squared-element-icon;
  }
}

.totalPrice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include max-w(lg) {
    margin-top: 20px;
    flex-direction: column;
    align-items: start;
  }

  .price {
    @include primary-font(
      (
        xs: 24px,
        xl: 30px,
      )
    );

    font-weight: 500;
  }

  & > span:not(:last-of-type) {
    margin-right: 20px;

    @include max-w(lg) {
      margin: 0 0 24px;
    }
  }
}

.label {
  @include label();
}

.priceLabel {
  @include primary-font();

  color: $gray-suit;
  margin-right: 20px;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.searchForm {
  padding: 0 10px 10px 0;

  @include max-w(xs) {
    gap: 6px;
    padding: 10px 0 20px 0;
  }
}

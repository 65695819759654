@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  background: $white;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.contentContainer {
  @include tiny-scrollbar($width: $scrollbar-width, $thumb-color: #a3abbe);

  display: flex;
  overflow: scroll;
  flex-flow: column;
  background: $white;
  font-family: $ff-gilroy;
  height: 100%;
  padding-top: 20px;

  @include max-w(xs) {
    min-width: unset;
    width: 100% !important;
    bottom: 0;
  }
}

.closeButtonContainer {
  position: relative;
}

.closeButton {
  @include make-responsive(
    'right',
    (
      xs: 15px,
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  position: absolute;
  top: 0;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.formContainer {
  padding: 0 30px 10px;

  @include max-w(xxl) {
    padding: 0 20px 10px;
  }

  @include max-w(xs) {
    padding: 0 10px 10px;
  }
}

.saveChangesModal {
  z-index: 999;
}

.saveChangesModalOverlay {
  z-index: 110;
}

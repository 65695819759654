@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  align-items: center;
}

.label {
  @include make-fluid('font-size', 14px, 21px, get-breakpoint-value(xs), get-breakpoint-value(xl), 21px, px);
  margin-bottom: 10px;
  color: $gray-suit;
}

.selector {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 10px;

  & [data-lastpass-icon-root] {
    display: none !important;
  }
}

.btn {
  position: relative;
  width: 20px;
  height: 20px;
  flex-grow: 0;
  flex-shrink: 0;

  border: 0.5px solid $gray-blue;
  border-radius: 3px;

  &:hover:before,
  &:hover:after {
    background: RGB($default-accent-color);
  }

  &:disabled {
    background: $gray-blue;
    opacity: 0.6;
    border: 0.5px solid transparent;

    &:hover {
      cursor: not-allowed;
    }

    &:before,
    &:after {
      background: lighten($gray-blue, 50%);
    }
  }
}

.increase,
.decrease {
  &:before,
  &:after {
    width: 50%;

    content: '';
    display: block;
    height: 1px;
    background: $gray-blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transition: background 0.25s ease;
  }

  &:before {
    transform: translate(-50%, -50%);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.decrease:after {
  content: none;
}

.customInput {
  @include primary-font();

  max-width: 60px;
  background: transparent;
  border: none;
  color: $ebony;
  width: 100%;
  height: 100%;
  text-align: center;
  outline: none;
  transition: background 0.25s ease;
  margin: 0 10px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }

  &:focus {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  }
}

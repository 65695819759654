@import '../../../../variables';
@import '../../../../styles/common/mixins';

.hasSecret {
  ::placeholder {
    color: black;
    opacity: 1;
  }
}

.secretIcon {
  cursor: pointer;

  &.activated {
    path {
      transition: 0.2s ease;
      fill: rgbCl($global-accent-color);
    }
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.megaButton {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @include max-w(xs) {
    width: 32px;
    height: 32px;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.inventoryItem {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  cursor: default;

  &.noHover {
    &:hover {
      background: transparent;
    }
  }

  @include max-w(xs) {
    z-index: 0;
  }
}

.iconInner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  margin-bottom: 30px;
  border-radius: 7.5%;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    margin-bottom: 20px;
  }

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.info {
  display: flex;
  width: 100%;

  @include max-w(xs) {
    flex-direction: column;
    margin: 0;
  }
}

.titleAndPrice {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0.25em 0.7em;
  background: $white;
  border-radius: 25px;
  margin-top: -40px;
  z-index: 1;

  @include min-w(get-breakpoint-value(xs) + 1) {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.25s ease, transform 0.25s ease;
    transform: scale(0.85);

    &:hover {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
      transform: scale(1) translateY(5%);
    }
  }

  .controlButton {
    @include secondary-font();

    cursor: pointer;
    margin-top: 2px;

    &.edit {
      color: lighten($gray-light, 10%);

      &:hover {
        color: $gray-light;
      }
    }

    &.delete {
      color: lighten($error-red, 10%);

      &:hover {
        color: $error-red;
      }
    }
  }

  &.mobile {
    box-shadow: unset;
    border-radius: unset;
    margin: 0;
    justify-content: flex-start;
    background: transparent;
    padding: 5px 0;
  }
}

.icon {
  position: absolute;
  width: 70%;
  height: 70%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.title {
  @include primary-font();

  width: 100%;
  margin-top: 5px;
  font-weight: 500;
  line-height: 1.15;
  color: $ebony;
  text-align: left;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.price {
  @include card-title-font();

  color: $ebony;
}

.addBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: RGB($global-accent-color);
  background-image: url('../../assets/images/icon-plus.svg');
  background-position: center;
  background-repeat: no-repeat;
  min-width: 32px;
  min-height: 32px;
  max-width: 40px;
  max-height: 40px;
  width: 20%;
  height: 20%;
  border-radius: 5px;

  &.selected {
    background-color: $budget-green;
    background-image: url('../../assets/images/icon-checked.svg');

    &:hover {
      background-color: RGB($global-accent-color);
      background-image: url('../../assets/images/icon-remove-minus.svg');
    }
  }

  &:hover {
    cursor: pointer;

    &.disabled {
      cursor: unset;
    }
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

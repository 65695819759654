@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: relative;

  .value {
    box-shadow: none;
    border-radius: unset;
    z-index: unset;
    padding: 0;
    font-size: 18px;
  }
}

.hint {
  width: 18px;
  height: 18px;
  flex-shrink: 0;

  &.floating {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.6) translate(-100%, -100%);
    z-index: 1;

    @include max-w(xs) {
      transform: scale(0.9) translate(-40%, -25%);
    }
  }

  @include max-w(xs) {
    width: 22px;
    height: 22px;
  }
}

.hintContent {
  text-align: left;
  font-size: 16px;
  color: $tangerine;
  flex-shrink: 0;

  &.error {
    color: $error-red;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;

  @include make-responsive(
    'height',
    (
      xxl: 428px,
      xxxl: 478px,
    ),
    478px
  );

  .lowItemsWidget,
  .delayedPackagesWidget {
    max-width: calc(50% - 10px);
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.itemContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 10px 0;
  border-bottom: 0.5px solid $gray-blue;

  .deleteButton {
    position: absolute;
    top: 0;
    right: 0;

    z-index: 1;

    margin: 10px;

    width: 12px;
    height: 12px;

    @include max-w(xs) {
      margin: 10px 15px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  @include max-w(xs) {
    padding: 0;
  }
}

.mskuAccordion {
  .multipleSkuContainer {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px;
    margin-left: unset;
    margin-top: 10px;
    gap: 10px;

    @include max-w(xs) {
      margin-left: 10px;
      margin-top: unset;
      margin-bottom: 10px;
    }
  }
}

.campaignContainer {
  height: unset;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-items: flex-start;

  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 20px;

  .iconContainer {
    display: flex;
    align-items: center;
    order: 2;
    margin-right: 10px;
    margin-left: auto;
    padding: 6px;
    border: 1px solid $ebony;
    border-radius: 50%;
    cursor: pointer;

    @include max-w(xs) {
      margin-right: 0;
    }

    > svg {
      margin-right: 0;
    }

    &:hover {
      border-color: RGB($global-accent-color);
      box-shadow: 0 5px 10px rgbCl($global-accent-color, 0.2);

      > svg {
        fill: RGB($global-accent-color);
      }
    }
  }

  @include max-w(xs) {
    grid-template-columns: 76px 1fr auto;
    padding: 10px 10px;
  }

  @include min-w(get-breakpoint-value(xxxl) + 1) {
    padding: 10px 0 10px 30px;
  }
}

.itemImageContainer {
  position: relative;
  border: 0.5px solid $gray-blue;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 14px;
  width: 80px;
  height: 80px;

  @include max-w(xs) {
    width: 76px;
    height: 76px;
    padding: 12px;
  }
}

.iconInner {
  flex-shrink: 0;

  width: 52px;
  height: 52px;

  .icon {
    width: 100%;
    height: 100%;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .customizableIcon {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 25px;
    height: 25px;
  }
}

.itemCountContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-blue;
  border-radius: 3px;
  padding: 0 3px;
  min-width: 21px;
  max-height: 21px;
  min-height: 21px;
  right: -1px;
  top: -1px;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      right: -0.5px;
      top: -0.5px;
    }
  }
}

.itemQuantity {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: $ebony;
}

.itemPrice {
  @include primary-font(
    (
      xxl: 18px,
      xxxl: 22px,
    )
  );

  font-weight: 600;
  display: flex;
  align-items: center;
  color: $ebony;
  min-width: 70px;

  @include max-w(xs) {
    font-size: 24px;
    justify-content: left;
  }
}

.nameGroup {
  display: flex;
  flex-flow: column;
}

.itemName {
  @include cropped-text();
  @include primary-font();

  color: $ebony;
  font-weight: 500;

  @include max-w(xs) {
    margin-left: 0;
    padding-top: 5px;
  }
}

.itemDetailsContainer {
  margin-left: 1em;
  display: grid;
  grid-template-columns: auto;
  align-items: center;

  .itemDetails {
    display: flex;
    align-items: center;

    @include max-w(xs) {
      flex-flow: column;
    }
  }

  .itemOptionsContainer {
    margin-top: 10px;

    @include max-w(xs) {
      margin-top: 5px;
    }
  }

  .itemOptionsHeading {
    display: flex;
    gap: 5px;

    @include max-w(xs) {
      flex-direction: column;
    }
  }

  @include max-w(get-breakpoint-value(xs)) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10px;
    max-width: calc(100% - 135px);
  }
}

.itemLayoutContainer {
  display: flex;
  position: relative;
  align-items: stretch;
  width: 100%;

  @include max-w(xs) {
    flex-wrap: wrap;
  }

  .itemControls {
    display: flex;
    flex-direction: column;
    //align-self: center;
    align-items: flex-end;
    margin-left: auto;
    margin-right: 10px;

    &.mskuItemControls {
      width: unset;
      align-self: center;
    }

    @include max-w(xs) {
      width: 100%;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      padding-left: 50px;
      padding-top: 5px;
    }

    .hideCheckboxContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include max-w(xxl) {
        padding-top: 0.2em;
      }

      @include max-w(xl) {
        padding-top: 0.4em;
      }

      @include max-w(md) {
        padding-top: 0.6em;
      }

      @include max-w(xs) {
        padding: 0 10px 0 0;
        justify-content: center;
      }

      .hideTooltipContainer {
        margin-left: 10px;

        @include max-w(xs) {
          margin-left: 5px;
        }
      }

      .helpHideTooltipContent {
        padding: 10px 20px;
        font-size: 16px;
      }
    }

    @include max-w(xs) {
      margin-right: 5px;
    }
  }
}

.icon {
  display: block;
  height: 14px;
  width: 14px;
  margin-right: 10px;
  fill: $ebony;
  transition: transform 0.3s ease-in-out;
}

.iconShown {
  @extend .icon;

  transform: rotate(180deg);
}

.count {
  margin-right: 13px;
  margin-left: auto;
}

.countLeft {
  display: flex;
  align-self: center;

  margin-right: 20px;
  margin-left: unset;
  min-width: 40px;

  @include max-w(xs) {
    margin-right: 9px;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.hiddenIcon {
  margin-left: auto;
}

.mobileOptionsContainer {
  padding-left: 10px;
}

.metadataContainer {
  left: unset !important;
  right: 0 !important;
  top: 25px !important;

  .customizableIcon {
    width: 20px;
    height: 20px;
  }
}

.quantityInput {
  position: relative;
  flex: 2;

  &:hover,
  &:focus-within {
    .maxQuantityError {
      transform: translate(-50%, 0);
    }
  }
}

.maxQuantityError {
  position: absolute;
  background: $white;
  border-radius: 6px;
  box-shadow: $tooltip-shadow;
  padding: 0.2em 0.6em 0.1em;
  z-index: 1;
  top: 100%;
  left: 50%;
  white-space: nowrap;
  font-size: 13px;
  color: $orange-header;
  transform: scale(0) translate(-60%, -10%);
  transition: transform 0.25s ease;

  @include max-w(xs) {
    font-size: 12px;
  }
}

.preorderIcon {
  width: 25px;
  height: 25px;
  display: flex;
  align-self: center;
  margin-right: 35px;
}

.preorderTitle {
  font-size: 14px;
  font-weight: 600;
  color: $budget-green;
}

.contentWrapper {
  width: 100%;
  display: flex;
}

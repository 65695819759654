@import '../../../variables';
@import '../../../styles/common/mixins';

.resetPasswordModal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 705px;
  height: 630px;

  @include max-w(md) {
    height: 100%;
    width: 100%;
  }
}

.closeButton {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  user-select: none;

  @include max-w(xs) {
    top: 25px;
    right: 25px;
  }
}

.input {
  margin-bottom: 30px;
}

.title {
  @include make-fluid('font-size', 32px, 48px, get-breakpoint-value(xs), get-breakpoint-value(xl), 48px, px);

  font-weight: 500;
  line-height: 1.15;
  color: $midnight-blue;
  margin-bottom: 10px;
  text-align: center;
}

.infoText {
  @include primary-font();

  color: $midnight-blue;
  margin-bottom: 40px;
  text-align: center;

  @include max-w(xs) {
    font-size: 18px;
  }
}

.inner {
  margin: auto;
  width: 405px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include max-w(xs) {
    width: 100%;
    padding: 18px;
  }

  input {
    margin-bottom: -10px;
  }

  svg {
    margin-bottom: 55px;
  }
}

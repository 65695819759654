@import '../../../variables';
@import '../../../styles/common/mixins';

.item {
  width: 100%;
  background-color: $white;
  border-radius: 10px;

  &.show {
    > .contentWrapper {
      max-height: unset;
      transition: max-height 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);

      .content {
        opacity: 1;
        transition: opacity 0.2s ease-in-out 0.2s;
      }
    }

    > .title .icon {
      transform: rotate(180deg);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 30px;
  text-align: left;

  @include max-w(xxl) {
    height: 50px;
    padding: 0 20px;
  }

  &.clickOnIcon {
    cursor: default;

    .icon {
      cursor: pointer;
    }
  }

  .icon {
    display: block;
    height: 14px;
    width: 14px;
    margin-right: 10px;
    fill: $ebony;
    transition: transform 0.3s ease-in-out;
  }
}

.contentWrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;

  .content {
    opacity: 0;
    transition: opacity 0.1s linear, ease 0.5s;

    padding: 10px 30px 30px;

    @include max-w(xxl) {
      padding: 10px 20px 20px;
    }
  }
}

@import '../../variables';

.container {
  :global(.reactSelectContainer) {
    min-width: 100px;
    max-width: 130px;
  }

  :global(.reactSelect__control) {
    min-height: 25px;
    max-height: 25px;
  }

  :global(.reactSelectContainer .reactSelect__control) {
    box-shadow: none;
    background-color: $bg-color;
  }

  :global(.reactSelect__indicators) {
    right: 5px;
  }

  :global(.reactSelect__menu) {
    width: 150px;
    transform: translateX(-60px);
  }

  :global(.reactSelect__value-container) {
    height: unset;
  }

  :global(.reactSelect__single-value) {
    font-weight: 600;
  }
}

.wrapper {
  display: flex;
  align-items: baseline;
}

.label {
  margin-bottom: 5px;
}

.loader {
  position: relative;
  background-color: transparent;
  height: 25px;
}

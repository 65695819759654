@import '../../../../variables';
@import '../../../../styles/common/mixins';

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: box-shadow 0.25s ease;
  box-shadow: $transparent-shadow;

  &:hover .input:read-write {
    box-shadow: $input-shadow;
  }

  &.inline {
    &:hover .input:read-write {
      box-shadow: none;
    }

    .input {
      padding: 0;
      border-radius: 0;
      height: 100%;

      &:focus {
        border: none;
      }

      &:disabled {
        background: unset;
      }
    }
  }
}

.error {
  @include secondary-font(
    (
      xxl: 12px,
      xxxl: 16px,
    )
  );

  position: absolute;
  top: calc(100% + 5px);
  line-height: 1.15;
  color: $error-red;
}

.input {
  @include primary-font();
  @include make-responsive(
    'max-height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );

  width: 100%;
  position: relative;
  border: none;
  border-radius: 10px;
  padding: 22px 42px 22px 30px;
  color: $ebony;
  transition: all 0.25s ease;

  @include make-responsive(
    'padding-left',
    (
      xs: 15px,
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  @include max-w(xs) {
    max-height: 56px;
  }

  &.inline {
    padding: 0;
    border-radius: 0;

    &:focus {
      border: none;
    }
  }

  &.errorBorder {
    @include error-border;
  }

  &:focus {
    outline: none;
    border: 1px solid $ebony;
  }

  &:disabled {
    background: lighten($bg-color, 2.5%);
    color: rgba(43, 47, 56, 0.2);
    user-select: none;

    &::placeholder {
      opacity: 0.2;
    }
  }

  &:read-only {
    &:focus {
      border: none;
    }
  }

  &::placeholder {
    color: $ebony;
    opacity: 0.5;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.inputInner {
  position: relative;

  svg {
    display: flex;
    transform: translateY(-50%);
    position: absolute;
    right: 30px;
    top: 50%;
    max-width: 15px;
    max-height: 15px;
    height: auto;
    width: auto;

    @include max-w(xxl) {
      right: 20px;
    }
  }

  &.errorBorder input {
    @include error-border;
  }
}

.clearBtn {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  z-index: 1;
  transform: translate(50%, -50%) scale(0.75);
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  margin-bottom: 30px;

  @include min-w(get-breakpoint-value(md) + 1) {
    margin-bottom: 50px;
  }
}

.icon {
  background-color: $white;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center center;
}

.priorityAndPrice {
  display: flex;
  flex-direction: row;
}

.priority {
  max-width: 135px !important;

  @include max-w(md) {
    max-width: 91px !important;
    margin-right: 30px;
  }

  input {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
}

.price {
  background: $bg-color;
  max-width: 135px !important;

  .amount {
    top: 15px;

    @include make-responsive(
      'font-size',
      (
        xxl: 24px,
        xxxl: 30px,
      ),
      30px
    );

    @include max-w(xs) {
      font-size: 24px;
    }

    font-weight: 600;
    line-height: 1.15;
    display: flex;
    align-items: center;
  }

  @include min-w(get-breakpoint-value(md) + 1) {
    margin-right: auto;
    margin-left: 15px;
    width: 100%;
  }
}

.items {
  .content {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
  }

  .item {
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    margin-bottom: 30px;
  }
}

.wrapper {
  display: flex;
  width: 100%;

  @include max-w(xxl) {
    flex-direction: column;
  }
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  min-width: 60%;
  margin-top: 15px;

  @include min-w(xxl) {
    margin-left: 50px;
  }
}

.shipping {
  display: flex;
  flex-flow: column;
  min-width: 550px;

  @include max-w(md) {
    min-width: 100%;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.engagement {
  @include tiny-scrollbar($width: 1rem);

  position: relative;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sendOnBehalf {
  display: flex;
  flex-direction: column;

  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

.avatarContainer {
  @include primary-font(
    (
      xxl: 18px,
      xxxl: 24px,
    )
  );

  font-weight: 500;
}

.label {
  @include label();
}

.pygIcon {
  @include make-responsive(
    'margin-left',
    (
      xs: 9px,
      xxl: 10px,
      xxxl: 15px,
    ),
    10px
  );

  @include make-responsive(
    'width',
    (
      xxl: 25px,
      xxxl: 35px,
    ),
    25px
  );

  vertical-align: middle;
  margin-top: -5px;
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include tiny-scrollbar;
  @include container;

  @include max-w(xs) {
    padding: 0 10px 25px;
  }
}

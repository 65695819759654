@import '../../../../variables';
@import '../../../../styles/common/mixins';

$day: '.react-datepicker__day';

.bluebird-date-picker {
  .react-datepicker {
    &__header--custom {
      border-bottom: 0;
      padding: 0;
      background: $white;
    }

    &__month {
      margin: 10px 18px 15px;
    }

    &__week {
      &:not(:last-of-type) {
        margin-bottom: 3px;
      }
    }

    &__day-names {
      border-top: 1px solid $gray-aero;
    }

    &__day-name {
      @include make-responsive(
        'font-size',
        (
          xs: 16px,
          xxl: 18px,
        ),
        18px
      );

      min-width: var(--day-size);
      max-width: var(--day-size);
      font-family: $ff-gilroy;
      color: $gray-suit;

      @include max-w(lg) {
        margin: 4.5px;
      }
    }

    &__day {
      @include make-responsive(
        'font-size',
        (
          xs: 16px,
          xxl: 18px,
        ),
        18px
      );

      min-width: var(--day-size);
      max-width: var(--day-size);
      min-height: var(--day-size);
      max-height: var(--day-size);
      font-family: $ff-gilroy;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      margin: 2px;
      position: relative;
      z-index: 0;

      @include max-w(lg) {
        margin: 4.5px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        z-index: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: background 0.25s ease;
      }

      &--keyboard-selected {
        background: transparent;

        &:after {
          background: $deep-sky-blue;
        }
      }

      &:hover {
        color: $ebony;

        &:after {
          background: $deep-sky-blue;
        }
      }

      &:not(#{$day}--outside-month) {
        &:hover {
          &:after {
            background: rgba($gray-suit, 0.2);
          }
        }

        :root {
          --mega-custom-var: '#{$day}';
        }

        &#{$day}--in-selecting-range:not(#{$day}--in-range) {
          color: $ebony;
          background: transparent !important;

          &:hover {
            &:after {
              background: rgba($gray-suit, 0.2);
            }
          }
        }

        &#{$day}--in-range {
          color: $ebony;
          background: transparent;

          &:after {
            background: rgba($deep-sky-blue, 0.2);
          }

          &:hover {
            &:after {
              background: $deep-sky-blue;
            }
          }
        }

        &#{$day}--range-start {
          color: $white;

          &:after {
            background: $deep-sky-blue;
          }
        }

        &#{$day}--range-end {
          &:not(#{$day}--range-start) {
            &:before {
              content: '';
              background: $white;
              position: absolute;
              top: 50%;
              left: 50%;
              width: calc(100% - 2px);
              height: calc(100% - 2px);
              transform: translate(-50%, -50%);
              border-radius: 50%;
              z-index: 1;
              transition: background-color 0.25s ease;
            }

            &:after {
              background: $deep-sky-blue;
            }

            &:hover {
              color: $ebony;

              &:before {
                background: transparent;
              }
            }
          }

          &#{$day}--range-start {
            color: $white;

            &:before {
              background: transparent;
            }

            &:after {
              background: $deep-sky-blue;
            }
          }
        }
      }

      &#{$day}--disabled {
        background: transparent !important;
        color: rgba($ebony, 0.2);

        &:after {
          background: none;
        }

        &:hover {
          color: rgba($ebony, 0.2);

          &:after {
            background: none;
          }
        }
      }

      &#{$day}--outside-month,
      &#{$day}--outside-month#{$day}--disabled {
        background-color: transparent;
        cursor: default;
        pointer-events: none;
        color: transparent;

        &:before,
        &:after {
          background-color: transparent !important;
        }
      }
    }
  }
}

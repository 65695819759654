@import '../../../variables';
@import '../../../styles/common/mixins';

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid $gray-blue;

  .headerBtn {
    align-self: center;
    flex: 0 0;
    white-space: nowrap;
  }
}

.title {
  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xl: 24px,
      xxxl: 26px,
    ),
    30px
  );

  font-weight: 700;
  padding: 20px 0;
}

.continueButton {
  margin-left: auto;

  @include max-w(xs) {
    margin-right: auto;
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  background-color: #1dbeee;

  @include max-w(xs) {
    width: 30px;
    min-width: 30px;
    height: 30px;
  }
}

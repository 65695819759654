@import '../../variables';
@import '../../styles/common/mixins';
@import '../../styles/common/animations';

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  margin-top: 40px;
  padding: 20px;
}

.text {
  display: flex;
  align-items: flex-end;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  min-height: 74px;
  opacity: 1;
  background-color: #db00ff;
  background-image: linear-gradient(90.98deg, #db00ff -1.93%, #f7be2c 107.66%);
  background-size: 200% 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  -webkit-animation: gradientAnimation 2s ease infinite;
  -moz-animation: gradientAnimation 2s ease infinite;
  animation: gradientAnimation 2s ease infinite;
}

.lottie {
  max-width: 500px;
  max-height: 500px;

  @include max-w(xxl) {
    max-width: 30vw;
    max-height: 30vh;
  }
  @include max-w(xl) {
    max-width: 50vw;
    max-height: 50vh;
  }

  @include max-w(xs) {
    max-width: 100vw;
    max-height: 100vh;
  }
}

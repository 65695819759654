@import './src/styles/common/mixins';
@import './src/variables';

.container {
  overflow: visible;
}

.selectOptionContainer {
  width: 100%;
  padding: 0 150px;

  @include max-w(xs) {
    padding: 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin-top: 20px;
}

.actionButton {
  margin-top: 30px;
}

.closeButton {
  &:hover {
    cursor: pointer;
  }
}

.selectOpened {
  height: 280px;
}

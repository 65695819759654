@import '../../../../variables';
@import '../../../../styles/common/mixins';

.selector__container {
  position: relative;
  display: flex;
  flex-direction: column;

  &__error {
    @include secondary-font(
      (
        xxl: 12px,
        xxxl: 16px,
      )
    );

    position: absolute;
    top: calc(100% + 5px);
    line-height: 1.15;
    color: $error-red;
  }
}

.reactSelectContainer {
  border-radius: 10px;
  background-color: $white;
  box-shadow: none;

  &.readOnly {
    cursor: default;
    pointer-events: none;
    .reactSelect__control:hover {
      box-shadow: none;
    }

    .reactSelect__indicators {
      display: none;
    }

    .reactSelect__value-container {
      cursor: default;
    }
  }

  &.menuOpened {
    filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.25));
    border-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 7px 5px 10px;
    z-index: 1;
  }

  &.menuOpened .reactSelect__menu {
    display: flex;
    flex-direction: column;
    margin: 0;
    filter: none;
    padding: 0 7px 5px 10px;
    border-radius: 0 0 10px 10px;
  }

  &.menuOpened .reactSelect__control {
    border: 1px solid $ebony;

    &:hover {
      box-shadow: none;
    }
  }

  &.searchable .reactSelect__value-container {
    cursor: text;
    flex-wrap: nowrap;
  }

  &.readOnly .reactSelect__value-container {
    cursor: default;
    flex-wrap: nowrap;
  }

  &.readOnly .reactSelect__control {
    &:hover {
      box-shadow: none;
    }
  }

  &.reactSelect--is-disabled .reactSelect__control {
    filter: contrast(0.65);
    user-select: none;
  }

  .reactSelect {
    &__control {
      background-color: $white;
      border: none;
      border-radius: 10px;
      transition: box-shadow 0.25s ease;
      box-shadow: $transparent-shadow;

      @include make-responsive(
        'height',
        (
          xxl: 50px,
          xxxl: 64px,
        ),
        64px,
        px
      );
      @include max-w(xs) {
        height: 56px;
      }

      &:hover {
        box-shadow: $input-shadow;
        border-color: inherit;
      }

      &--is-focused {
        border-color: inherit;
        box-shadow: none;
      }
    }

    &__placeholder {
      @include primary-font();

      color: $ebony;
      opacity: 0.5;
    }

    &__value-container {
      @include primary-font();
      @include tiny-scrollbar();

      height: 100%;
      overflow: auto;
      padding: 5px 20px 5px 13px;
      display: flex;
      align-items: center;
      cursor: pointer;
      align-self: center;

      @include min-w(get-breakpoint-value(xs) + 1) {
        @include make-responsive(
          'padding-left',
          (
            xs: 13px,
            xxl: 28px,
          ),
          28px,
          px
        );
        @include make-responsive(
          'padding-right',
          (
            xs: 30px,
            xxl: 40px,
          ),
          30px,
          px
        );
      }
    }

    &__single-value {
      position: static;
      transform: none;
    }

    &__multi-value {
      border-radius: 7px;
      background-color: $bg-color;

      &__remove {
        border-radius: 7px;
        cursor: pointer;
      }
    }

    &__indicators {
      right: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      cursor: pointer;
      transition: 0.3s ease;

      @include min-w(get-breakpoint-value(md) + 1) {
        @include make-responsive(
          'right',
          (
            xs: 10px,
            xxl: 25px,
          ),
          20px,
          px
        );
      }
    }

    &__dropdown {
      transition: 0.3s ease;

      &.opened {
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &__clear-indicator {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);

      @include min-w(get-breakpoint-value(md) + 1) {
        transform: translate(100%, -50%);
        @include make-responsive(
          'right',
          (
            xs: 8px,
            xxl: -5px,
          ),
          5px,
          px
        );
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      z-index: 10;
      box-shadow: none;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      border: none;
      border-radius: 10px;
      left: 0;
      padding: 10px 7px 5px 10px;
      background-color: $white;
      margin-top: 10px;
    }

    &__menu-list {
      @include tiny-scrollbar;
      @include make-responsive(
        'max-height',
        (
          xs: 120px,
          xxl: 210px,
        ),
        210px,
        px
      );

      padding: 0;
    }

    &__option {
      @include primary-font();

      position: relative;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 5px;
      color: $ebony;
      cursor: pointer;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include min-w(get-breakpoint-value(xs) + 1) {
        @include make-responsive(
          'padding',
          (
            xs: 10px,
            xxl: 20px,
          ),
          20px,
          px
        );
        @include make-responsive(
          'padding-right',
          (
            xs: 10px,
            xxl: 40px,
          ),
          20px,
          px
        );
      }

      &--is-focused {
        background-color: transparent;
      }

      &--is-disabled {
        background-color: $gray-lighter;
        color: $gray-blue;
      }

      &--is-selected {
        background-color: $bg-color;
        color: inherit;

        &::after {
          content: '';
          position: absolute;
          top: 38%;
          right: 25px;
          width: 12px;
          height: 6px;
          border-bottom: 4px solid $gray-blue;
          border-left: 4px solid $gray-blue;
          transform: rotate(-45deg);
        }
      }

      &:hover {
        background-color: #d9dbe2;
      }
    }
  }
}

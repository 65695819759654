@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  z-index: unset !important;
}

.toggle {
  @include filterLabel();

  color: $gray-suit;

  .icon {
    transition: transform 0.25s ease;
    margin-left: 5px;
  }

  &.opened {
    .icon {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    cursor: default;
  }
}

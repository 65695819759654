@import '../../../variables';
@import '../../../styles/common/mixins';

.closeButton {
  &:hover {
    cursor: pointer;
  }
}

.container {
  overflow: visible;
  background-color: $bg-color;
  border-radius: 20px;
  max-width: 1050px;
  height: 100%;
  min-width: 700px;
  box-sizing: border-box;

  @include max-w(xl) {
    max-width: 85vw;
  }

  @include max-w(md) {
    max-height: calc(100vh - 30px);
    max-width: calc(100vw - 30px);
    border-radius: 10px;
  }

  @include max-w(xs) {
    max-height: calc(100vh - 20px);
    max-width: calc(80vw - 20px);
    min-width: 370px;
  }
}

.content {
  width: 100%;
}

.title {
  @include label();
}

.contentHeader {
  @include make-responsive(
    'font-size',
    (
      xs: 36px,
      xl: 48px,
    ),
    48px
  );

  font-weight: 500;
  color: $midnight-blue;
  text-align: center;
}

.fcInputContainer {
  margin: 30px 0 15px;
}

.selectOptionContainer {
  width: 100%;
  padding: 0 150px;

  @include max-w(xs) {
    padding: 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.selectOpened {
  height: 280px;
}

.uploadField {
  width: 100%;
  height: 270px;
  margin-bottom: 30px;

  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 30px;

      @include max-w(xs) {
        max-width: 41px;
      }
    }
  }
}

.clearIcon {
  @include close-btn;
}

.exampleLink {
  .toggle {
    min-width: unset;
    min-height: unset;
    width: 15px;
    height: 15px;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.lookupSearchContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: column;

  &.readOnly {
    pointer-events: none;

    .inputWrapper {
      .input {
        input {
          padding-right: 1em;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.loading {
    .icon :global {
      animation: 0.5s linear 0s infinite alternate searching;
    }
  }

  .inputWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: stretch;

    .icon {
      display: flex;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      cursor: pointer;

      .opened {
        transform: rotate(180deg);
        transition: all 0.25s ease;
      }

      @include max-w(xxl) {
        right: 20px;
      }
    }

    .input {
      input {
        padding-right: 55px;
        max-width: unset;
      }
    }
  }

  .resultWrapper {
    position: absolute;
    z-index: 1;
    top: 100%;
    width: 100%;
    margin-top: 5px;
    transform-origin: 50% 0;
    transform: scaleY(0);
    transition: all 0.25s ease;
    overflow: hidden;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    background: $white;

    &.active {
      transform: scaleY(1);
    }

    .spinner {
      display: flex;
      justify-content: center;
    }
  }

  .clearIndicator {
    position: absolute;
    top: 0;
    right: 10px;
    transform: translate(50%, -50%);
    z-index: 5;
  }
}

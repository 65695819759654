@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;

  flex-flow: column;
  max-height: 100%;
  flex: 1;
}

.header {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 0;
  justify-content: space-between;

  @include max-w(xl) {
    padding-bottom: 0;
  }

  @include max-w(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include max-w(xs) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.filter {
  display: flex;
  margin-right: 10px;
  gap: 20px;

  @include max-w(xs) {
    padding-top: 10px;
    flex-wrap: wrap;
  }

  .dropdownFilter {
    position: relative;
    margin-right: 10px;

    @include max-w(xs) {
      margin-right: 100%;
      margin-top: 10px;
    }

    .dropdownContent {
      width: 335px;

      @include max-w(md) {
        top: 140%;
        width: 280px;
      }

      @include max-w(xs) {
        transform: unset;
      }
    }
  }
}

.addButton {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 5px;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-left: 0;
    max-width: 100%;
  }

  @include min-max-w(get-breakpoint-value(md) + 1, xl) {
    margin-right: 15px;
  }
}

.table {
  max-width: 100%;
}

.tableBody {
  max-height: 100%;
}

.tableHeader,
.tableBody {
  grid-template-columns: 1fr 1fr 1fr 150px 80px 100px 100px 120px 70px;
}

.notActiveStatus {
  color: $gray-suit;
}

.total {
  padding: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: $gray-suit;
}

.amount {
  margin-left: 5px;
  color: $black;
}

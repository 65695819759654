@import '../../variables';
@import '../../styles/common/mixins';

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.header {
  @include container;

  display: flex;
  align-items: center;
  padding-bottom: 0;
  margin-bottom: 20px;

  @include max-w(xl) {
    padding-bottom: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .controls {
    display: flex;
    align-items: center;
    width: 100%;

    @include max-w(xl) {
      margin-top: 20px;
    }

    @include max-w(xs) {
      margin-top: 0;
      flex-flow: column;
      align-items: flex-start;
    }
  }

  .search {
    max-width: 400px;

    @include max-w(xl) {
      max-width: 438px;
    }

    @include max-w(xs) {
      max-width: unset;
      width: 100%;
    }
  }
}

.toggle {
  margin-left: 20px;
  width: auto;

  @include max-w(xl) {
    margin-left: 0;
  }

  @include max-w(xs) {
    margin-left: 0;
    margin-top: 10px;
  }
}

.toggleLabel {
  @include make-responsive(
    'font-size',
    (
      xs: 14px,
      xxl: 18px,
    ),
    18px
  );
}

.submitBtn {
  @include make-responsive(
    'margin-left',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    25px
  );

  @include max-w(xs) {
    margin: 10px auto 0;
    max-width: unset;
  }
}

.listWrapper {
  @include tiny-scrollbar();

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 9px;
  }

  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;

  .list {
    @include container;

    padding-top: 0;

    @include max-w(xs) {
      padding-top: 0;
    }
  }
}

.controlsInfo {
  margin-right: 15px;

  @include max-w(md) {
    margin-right: 10px;
  }
}

.cancel,
.delete {
  width: auto;

  .optional {
    margin-left: 0.25em;

    @include max-w(md) {
      display: none;
    }
  }
}

.delete {
  background-color: $error-red;
}

.mobile {
  padding: 0;
  width: auto;
  height: auto;
}

.noEngagement {
  @include make-responsive(
    'margin-top',
    (
      xs: 20px,
      xxl: 30px,
      xxxl: 40px,
    ),
    40px
  );

  display: flex;
  flex-direction: column;
  width: 100%;
  color: $gray-suit;

  .image {
    width: 50%;
    height: auto;
    margin: 70px auto 0;

    @include max-w(xxl) {
      width: 75%;
      margin-top: 47px;
    }

    @include max-w(xs) {
      width: 1000%;
      margin-top: 55px;
    }
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;
  @include tiny-scrollbar($width: 1rem);

  flex-flow: column;
  max-height: 100%;
  overflow-y: auto;
}

.form {
  @include max-w(xs) {
    margin-bottom: 30px;
  }
}

.formElement {
  max-width: 50%;

  @include max-w(xs) {
    max-width: 100%;
  }
}

.loader {
  z-index: 5;
  background: $bg-color;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.desktopSideNav {
  display: flex;
  flex-direction: column;
  background-color: rgbCl($global-sidebar-color);
  font-size: 18px;
  line-height: 1.15;
  max-height: 100%;
  height: 100%;
  position: relative;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 80px;
    position: fixed;
    z-index: 100;
  }

  @include min-max-w(get-breakpoint-value(xs) + 1, xxl) {
    z-index: 100;
    position: fixed;
  }

  .body {
    @include tiny-scrollbar;

    padding-top: 45px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
  }

  .logo {
    display: block;
    margin: 0 auto;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;

  flex-flow: column;
  max-height: 100%;

  @include max-w(xs) {
    padding: 10px 0 10px 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: $gray-suit;
  padding: 0 10px;

  @include max-w(xs) {
    flex-direction: column-reverse;
    align-items: flex-start;
    box-shadow: $element-shadow;
    position: relative;
    z-index: 1;
  }

  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xxl: 21px,
    ),
    21px
  );
}

.filters {
  display: flex;
  flex-grow: 1;
  gap: 20px;
  flex-flow: row wrap;
  justify-content: space-between;

  @include max-w(xs) {
    gap: 10px;
  }

  .commonFilters {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 10px;

    @include max-w(md) {
      order: 3;
    }

    @include max-w(xs) {
      gap: 15px;
    }
  }

  @include max-w(xs) {
    flex-wrap: wrap;
    margin-right: 0;
  }

  .typeFilter {
    padding-right: 20px;

    @include max-w(xs) {
      padding-right: 0;
    }
  }
}

.dropdownFilter {
  position: relative;
}

.dropdownContent {
  width: 335px;
  transform: translateX(-30%);

  @include max-w(md) {
    transform: translateX(-50%);
    top: 140%;
    width: 280px;
  }

  @include max-w(xs) {
    transform: unset;
  }
}

.dropdownLabel {
  @include filterLabel;
}

.bucketTotal {
  align-self: flex-start;
  // min-height here is aligned with Input.input's max-height <- InventoryList screens
  @include make-responsive(
    'min-height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );
  @include max-w(xs) {
    min-height: 56px;
  }

  @include max-w(md) {
    order: 1;
    margin-left: auto;
    margin-bottom: -10px;
  }
}

.content {
  @include tiny-scrollbar($width: 0.5rem);

  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  @include max-w(xs) {
    padding-bottom: 25px;
  }
}

.itemsList {
  @include tiny-scrollbar($width: 0.5rem);

  padding: 20px 0;
  overflow-y: auto;

  @include min-w(get-breakpoint-value(xs) + 1) {
    @include fluidRow;

    margin-right: 0;
    align-items: flex-start;
  }

  @include max-w(xs) {
    padding: 20px 10px;
    flex-wrap: unset;
  }
}

.continueButton {
  margin: 10px 0 10px 0;

  @include max-w(xs) {
    margin: 10px 0;
  }
}

.pagination {
  @include make-responsive(
    'margin-top',
    (
      xs: 0,
      xxl: 10px,
    ),
    10px
  );

  margin-right: calc(var(--grid-item-margin) / 2);
}

.loader {
  z-index: 5;
  background-color: $bg-color;
}

.modal {
  z-index: 1110;
}

.modalOverlay {
  z-index: 1100;
}

.searchByFilterContainer {
  padding: 0 10px 0 0;
  margin-left: -10px;

  @include max-w(md) {
    order: 2;
    padding: 0;
    margin-left: 0;
    width: 100%;
    max-width: unset;
  }
}

.item {
  @include min-w(get-breakpoint-value(xs) + 1) {
    @include fluidCell;
  }

  @include min-w(get-breakpoint-value(xl) + 1) {
    @include fluidCell($perRow: 6);
  }

  @include min-w(get-breakpoint-value(xxxl) + 1) {
    @include fluidCell($perRow: 9);
  }

  @include max-w(xs) {
    align-items: center;
    flex-direction: row;
    padding-bottom: 20px;
    width: 100%;
    gap: 20px;
  }

  .wide {
    @include max-w(xs) {
      flex-direction: row;
      align-items: center;
    }
  }

  .iconInner {
    @include max-w(xs) {
      width: $mobile-item-icon-width;
      min-width: $mobile-item-icon-width;
      margin: 0;
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.divider {
  padding-right: 20px;
  border-right: 1px solid $gray-suit;
  margin-right: 0;
}

.preorderFilter {
  padding-left: 0;
}

.actionContainer {
  @include max-w(xs) {
    width: 100%;
    height: unset;
  }

  .btnText {
    width: 0;
    transition: 0.3s ease;

    @include max-w(xs) {
      transition: unset;
      width: 100%;
    }
  }

  .btnIcon {
    fill: $white;
    width: 100%;
    transition: 0.3s ease;

    @include make-responsive(
      'height',
      (
        xxl: 15.5px,
        xxxl: 20px,
      ),
      20px
    );

    @include max-w(xs) {
      width: 0;
    }
  }

  &:hover {
    .btnIcon {
      width: 0;
    }

    .btnText {
      width: 100%;
    }
  }
}

.submitBtn {
  transition: 0.3s ease;
  height: 100%;
  box-shadow: 0 0 20px rgba($midnight-blue, 0.2);
  overflow: hidden;

  @include make-responsive(
    'width',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px
  );

  @include make-responsive(
    'height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px
  );

  @include max-w(xs) {
    width: 262px;
    height: 56px;
    box-shadow: none;
    margin: auto;
  }

  &:hover {
    @include min-w(get-breakpoint-value(xs) + 1) {
      @include make-responsive(
        'width',
        (
          xxl: 232px,
          xxxl: 298px,
        ),
        298px
      );
    }
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.labelWithEditButton {
  @include label();

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editImage {
  &:hover {
    cursor: pointer;

    path {
      transition: 0.2s ease;
      fill: rgbCl($global-accent-color);
    }
  }
}

.labelText {
  font: inherit;
}

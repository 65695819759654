@import '../../../../variables';
@import '../../../../styles/common/mixins';

.button {
  position: relative;
  display: flex;
  min-height: 50px;

  .spinner {
    $size: 15px;

    max-width: $size;
    max-height: $size;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
  }
}

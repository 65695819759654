@import '../../variables';
@import '../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.tag {
  @include primary-font();

  justify-content: flex-start;
  position: relative;
  padding: 20px 0 15px 30px;
  background-color: white;
  color: black;
  border-radius: 10px;
  font-weight: 400;
  box-shadow: $transparent-shadow;
  transition: transform 0.2s ease, box-shadow 0.3s ease;

  &:hover {
    box-shadow: $input-shadow;
    transform: translate(-1px, -2px);
  }

  @include max-w(md) {
    padding-left: 15px;
  }

  &.required {
    @include error-border;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;

    @include max-w(md) {
      margin-bottom: 10px;
    }
  }

  .strong {
    font-weight: 500;
  }
}

.tagTooltipWrapper {
  position: absolute;
  right: 9px;
  top: 9px;
  height: 8px;
  color: $error-red;

  &.questionMark {
    height: inherit;
  }
}

.tagTooltipContent {
  white-space: nowrap;
}

.requiredTooltip {
  @include primary-font(
    (
      xs: 14px,
      xxl: 16px,
    )
  );

  box-shadow: $tooltip-shadow;
  color: $error-red;
}

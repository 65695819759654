@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  flex: 1;
  width: fit-content;
  align-self: stretch;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  padding-right: 30px;
  padding-left: 15px;

  @include max-w(xxl) {
    padding-right: 20px;
    padding-left: 10px;
  }

  @include max-w(xl) {
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
  }

  @include max-w(md) {
    min-width: 100%;
  }

  @include max-w(xs) {
    padding: 0 10px 20px;
  }

  .detailInfo {
    @include make-responsive(
      'margin-top',
      (
        md: 10px,
        xxl: 21px,
        xxxl: 23px,
      ),
      23px
    );

    display: grid;
    grid-template-columns: 10em auto;
    grid-gap: 10px;

    @include max-w(xs) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .option {
    @include secondary-font();

    color: $gray-suit;
    flex: 1;
  }

  .value {
    @include primary-font();

    flex: 1;

    &.reason {
      @include cropped-text(1);
    }

    &.notes {
      @include cropped-text(2);
    }

    &.campaign {
      display: flex;
      align-items: center;

      .name {
        @include cropped-text(1);
      }

      .campaignTooltip {
        margin-left: 10px;
        min-width: 15px;
      }
    }
  }

  .head {
    display: flex;
    justify-content: space-between;
  }

  .controls {
    margin-left: auto;

    @include max-w(xl) {
      margin-top: 10px;
    }

    & > :not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.icons {
  @include min-w(get-breakpoint-value(md) + 1) {
    margin-top: -10px;
    margin-bottom: -5px;
  }

  &.oneLink {
    @include min-w(get-breakpoint-value(md) + 1) {
      margin-top: -5px;
    }
  }
}

.dates {
  margin-top: -5em;
  display: flex;
  flex-flow: column;
  align-items: flex-end;

  @include max-w(xxl) {
    margin-top: -4em;
  }

  @include max-w(xl) {
    margin-top: unset;
  }

  > :not(:last-child) {
    margin-bottom: 10px;
  }
}

.daysLeft {
  color: $gray-suit;

  @include make-responsive(
    'font-size',
    (
      xs: 12px,
      xxl: 16px,
      xxxl: 18px,
    ),
    18px
  );
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.formWrapper {
  @include primary-font();

  padding: 10px;
  display: flex;
  flex-flow: column;
  height: 100%;

  @include min-w(get-breakpoint-value(xs) + 1) {
    padding: 0 45px;
  }
}

.title {
  @include heading-font();

  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.row {
  display: flex;
  gap: 10px;
  margin-bottom: 1.75em;
}

.errorMessage {
  @include micro-font();

  color: $error-red;
  position: absolute;
  top: 100%;
  margin-top: 0.5em;
}

.select {
  position: relative;
  width: 100%;

  :global {
    .reactSelect__control {
      border: 1px solid $gray-table;

      &:hover {
        border-color: $gray-table;
      }
    }
  }

  &.error {
    :global {
      .reactSelect__control {
        @include error-border;
      }
    }
  }
}

.input {
  border: 1px solid $gray-table;

  &.number {
    width: 83px;
    padding: 16px;

    &:disabled {
      cursor: not-allowed;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      background: #fff
        url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=)
        no-repeat center center;
      background-size: 1rem;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 2rem;
      opacity: 0.5;
    }
  }
}

.switch {
  margin-right: 5px;
  height: 50px;
  border: 5px solid $gray-lighter;
  border-radius: 3px;
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.controlsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-bottom: 10px;
  flex: 0 1;
}

.controlsGroup {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.deleteButton,
.cancelButton {
  font-size: 18px;
  margin: 0 25px;
  padding: 10px;

  @include max-w(xs) {
    font-size: 14px;
  }
}

.deleteButton {
  color: $error-red;
  text-decoration: underline;
  margin: 0;
}

.saveButton {
  width: 90px;

  @include max-w(xs) {
    width: 60px;
  }
}

.status {
  display: flex;
  margin-bottom: 15px;
  align-items: baseline;
  flex-flow: row wrap;
  column-gap: 5px;
}

.statusLabel {
  width: 100%;
}

.bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.tooltip {
  margin-left: 5px;
}

.noScroll {
  overflow: hidden;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.radioButton {
  @include primary-font();

  position: relative;
  display: flex;
  flex: auto;
  flex-flow: row;
  width: auto;
  padding: 0;
  color: $ebony;
  border: none;
  cursor: pointer;
}

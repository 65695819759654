@import '../../../../variables';
@import '../../../../styles/common/mixins';

.labelContainer {
  @include label();
  width: 100%;
}

.text {
  &.required::after {
    content: '*';
    color: $error-red;
    margin-left: 0.2em;
  }
}

.hint {
  margin-left: 5px;
  margin-bottom: 2px;
  vertical-align: middle;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-bottom: 30px;

  @include min-max-w(get-breakpoint-value(xs) + 1, xxl) {
    margin-bottom: 20px;
  }
}

.info {
  @include primary-font();
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

.optionsList {
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  .label {
    @include primary-font(
      (
        xs: 16px,
        xxl: 20px,
        xxxl: 24px,
      )
    );

    font-weight: 500;
  }
}

.datePicker {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 12px;
}

.dateInputLabel {
  width: 100%;
}

.dateToggle {
  width: unset;
  margin-right: 70px;
}

.hint > div {
  padding: 0;
}

.label {
  @include label();
}

.advancedSettingContainer {
  margin-top: 30px;
}

.advancedSettings {
  display: flex;
  flex-direction: column;
  border: 1px solid $gray-blue;
  border-radius: 10px;

  @include make-responsive(
    'gap',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    20px,
    px
  );

  @include make-responsive(
    'padding',
    (
      xs: 10px,
      xxl: 30px,
      xxxl: 40px,
    ),
    40px,
    px
  );
}

.allowedDomainInput {
  position: relative;
}

.allowedDomainLabelButton {
  @include secondary-font();
  @include hover-underline($gray-suit);

  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 5px;
  color: $gray-suit;
  align-self: flex-end;

  &:hover {
    cursor: pointer;
  }
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.toggleInput {
  display: flex;
  flex-direction: row;
  align-items: center;

  & button {
    text-align: left;
  }
}

.toggle {
  width: unset;
  margin-right: 16px;
}

.quantityInput {
  border-radius: 4px;
  background-color: $white;
}

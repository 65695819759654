@import '../../variables';
@import '../../styles/common/mixins';

$border-width: 1px;

.container {
  @include make-responsive(
    'margin-top',
    (
      xs: 30px,
      xxxl: 40px,
    ),
    30px
  );

  flex: 1;
  max-height: -webkit-fill-available;
  position: relative;
  display: flex;
  flex-flow: column;
  border: $border-width solid $gray-blue;
  border-radius: 0 10px 10px 10px;

  @include max-w(xs) {
    margin-bottom: 30px;
  }
}

.tabList {
  @include tiny-horizontal-scrollbar(4px);

  margin-bottom: 0;
  display: flex;
  position: absolute;
  top: -$border-width;
  left: -$border-width;
  transform: translateY(-100%);
  background: $bg-color;
  z-index: 1;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.tabPanel {
  @include tiny-scrollbar(8px);

  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
  padding: 20px 0 0 20px;

  .contentWrapper {
    width: 100%;
  }

  .loader {
    background-color: $bg-color;
    position: relative;
    align-self: center;
    margin: 0;
  }

  @include max-w(md) {
    padding: 10px 0 0 10px;
  }

  &:not(.selectedPanel) {
    display: none;
  }
}

.tab {
  @include secondary-font();

  cursor: pointer;
  padding: 10px 20px 5px;
  background: $bg-light-blue;
  border-radius: 10px 10px 0 0;
  color: $gray-suit;
  outline: none;
  white-space: nowrap;

  @include max-w(xs) {
    padding: 8px 11px 5px 13px;
  }

  &.active {
    cursor: default;
  }
}

.selectedTab {
  position: relative;
  background: $bg-color;
  color: $ebony;
  border: $border-width solid $gray-blue;
  border-bottom: none;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    background: $bg-color;
    width: 100%;
    height: 2 * $border-width;
    transform: translateY(-50%);
  }
}

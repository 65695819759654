@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;
  @include make-fluid('padding-right', 75px, 300px, get-breakpoint-value(xl), get-breakpoint-value(xxxl), 300px, px);

  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 50px;
}

.stepper {
  position: sticky;
  top: 0;
  z-index: 10;
  background: $bg-color;
  align-self: center;
  margin-bottom: 40px;
  width: 100%;

  @include min-w(get-breakpoint-value(md) + 1) {
    align-self: flex-start;
    justify-content: flex-start;
  }
}

.warning {
  margin-bottom: 20px;
}

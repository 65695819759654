@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background: $white;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.detailsContainer {
  @include make-responsive(
    'padding-right',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  display: flex;
  overflow: scroll;
  flex-flow: column;
  background: $white;
  font-family: $ff-gilroy;
  height: 100vh;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 0 20px 0 0;
  }

  @include min-w(get-breakpoint-value(xxxl) + 1) {
    padding: 0 25px 0 0;
  }

  @include max-w(xs) {
    min-width: unset;
    width: 100vw !important;
    bottom: 0;
    padding-top: 17px;
    padding-bottom: 90px;
    padding-right: 0;
  }

  @include tiny-scrollbar($width: $scrollbar-width, $thumb-color: #a3abbe);
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 25px;
  gap: 10px;
  border-bottom: 0.5px solid $gray-blue;

  @include max-w(xs) {
    padding: 10px 10px 20px;
  }
}

.headerGroup {
  display: flex;
}

.heading {
  flex: 1;
  padding: 20px 30px;

  h3 {
    color: $ebony;
    font-weight: bold;
    @include primary-font(
      (
        xs: 18px,
        md: 22px,
        xl: 22px,
        xxl: 26px,
      )
    );
  }

  p {
    color: $gray-suit;
    margin-top: 5px;
    @include secondary-font(
      (
        xs: 14px,
        xxxl: 18px,
      )
    );
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 20px 20px;

    h3 {
      font-weight: bold;
    }
  }

  @include max-w(xs) {
    padding: 0;
    margin-top: 0;
    display: flex;
    flex-flow: column;
    justify-content: center;

    h3 {
      font-weight: 600;
    }
  }

  @include min-w(get-breakpoint-value(xxxl) + 1) {
    padding: 30px 30px 50px 30px;

    h3 {
      font-weight: bold;
    }
  }

  @include max-w(xl) {
    h3 {
      font-weight: bold;
    }
  }
}

.warning {
  margin-top: 10px;
}

.headerBtn {
  align-self: center;
  flex: 0 0;
  white-space: nowrap;
}

.resetBtn {
  @include button-shadow;
  color: $error-red !important;
  border-color: $error-red !important;
}

.closeButtonContainer {
  display: flex;
  justify-content: flex-end;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    margin-top: 20px;
    margin-right: -10px;
  }

  @include max-w(xxl) {
    margin-top: 20px;
    margin-right: 10px;
  }

  @include max-w(xs) {
    margin: 0 10px;
  }
}

.closeButton {
  position: relative;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.actionButtonsContainer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  gap: 10px;

  @include max-w(xs) {
    display: flex;
    justify-content: center;
    padding: 10px;
    margin: auto 0 0 0;
    z-index: 10;

    background: $white;

    align-content: flex-end;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -4px 10px rgba(219, 222, 235, 0.8);
  }
}

.preorder {
  border-color: $orange-header;
}

.error {
  border-color: $error-red;
  color: $error-red;
}

.warning {
  border-color: $orange-header;
  color: $orange-header;
}

.preorderIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-self: center;
  margin-right: 15px;
  flex-shrink: 0;
}

.preorderTooltip {
  font-size: 16px;
}

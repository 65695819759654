@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include secondary-font;

  @include make-responsive(
    'gap',
    (
      xs: 16px,
      xxl: 50px,
    ),
    50px
  );

  color: $gray-suit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;

  @include max-w(xs) {
    width: calc(100vw - 30px);
  }
}

.price {
  display: flex;
  align-items: center;
  min-width: 9.5em;
  gap: 20px;

  @include max-w(xs) {
    gap: 10px;
    min-width: unset;
  }
}

.value {
  @include card-title-font();

  color: $ebony;
  font-weight: 500;
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
}

@import '../../../styles/common/mixins';
@import '../../../variables';

.navIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 26px;
  max-height: 26px;
  margin: 0 8px;
  flex-shrink: 0;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    svg {
      width: 100%;
    }
  }
}

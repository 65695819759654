@import '../../styles/common/mixins';
@import '../../variables';

.integrationPicker {
  display: flex;
  flex-direction: column;

  @include max-w(xs) {
    width: 100%;
  }

  &.authenticated {
    .icon {
      filter: none;
    }
  }

  .title {
    @include primary-font();

    color: $gray-suit;
    height: 2em;
  }

  .icon {
    padding: 1em 0 40px 0;
    filter: grayscale(1);
    transition: all 0.5s ease;
    height: 220px;
    align-self: center;

    @include max-w(xs) {
      height: 100%;
    }

    svg {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: 100%;
    }
  }

  .status {
    @include primary-font();

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 18px;

    .statusIcon {
      width: 15px;
      height: 15px;
      margin-right: 15px;
      margin-bottom: 5px;

      //svg {
      //  height: 100%;
      //  width: 100%;
      //}

      &.rotate {
        animation: reload-rotate-no-translate 2.5s linear infinite;
      }
    }
  }

  .error {
    margin-top: 15px;
    padding: 14px 16px;
    color: #fa453e;
    border: 1px solid #fa453e;
    border-radius: 6px;
    text-align: center;
  }

  button {
    @include primary-font();

    height: 2.75em;
    width: 100%;
    max-width: 100%;
    color: white;
    background-color: $salesforce-blue;
    border-radius: 10px;
    font-weight: 500;
  }
}

.salesforce {
  button {
    @include button-shadow;

    background-color: $salesforce-blue;
  }
}

.gmail-integration {
  button {
    @include button-shadow;

    background-color: $gmail-red;
  }
}

.hubspot {
  button {
    background-color: $hubspot-orange;

    &:hover {
      box-shadow: 0 10px 20px rgba($hubspot-orange, 0.4);
    }
  }
}

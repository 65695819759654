@import '../../styles/common/mixins';
@import '../../variables';

.container {
  position: static;
}

.menuLabel {
  position: relative;
  display: flex;
  align-items: center;

  > :not(:last-of-type) {
    margin-right: 10px;
  }

  .dots {
    height: 4px;
    width: 4px;
    background-color: $light-blue;
    border-radius: 50%;
    position: relative;

    &::after {
      content: '';
      height: 4px;
      width: 4px;
      background-color: $light-blue;
      border-radius: 50%;
      position: absolute;
      top: 8px;
    }

    &::before {
      content: '';
      height: 4px;
      width: 4px;
      background-color: $light-blue;
      border-radius: 50%;
      position: absolute;
      top: -8px;
    }
  }

  .alert {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid $white;
    border-radius: 50%;
  }
}

.menuContent {
  width: 320px;
  right: 0;
  margin-top: 10px;
  overflow: hidden;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(174, 177, 188, 0.5);
  cursor: default;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    margin-top: 25px;
  }

  @include max-w(xxl) {
    margin-top: 25px;
  }

  svg {
    height: 20px;
    width: 20px;
    margin: 0;
  }

  .header {
    padding: 20px 15px 15px 15px;
    background-color: $bg-color;

    & > :not(:last-child) {
      margin-bottom: 20px;
    }

    &:empty {
      display: none;
    }
  }

  .content {
    padding: 10px 0;

    > * {
      width: 100%;
      padding: 10px 15px;
    }

    .control {
      @include primary-font();

      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      color: $ebony;

      &:hover {
        background-color: $bg-color;
      }

      svg {
        margin-right: 15px;
      }
    }

    .userName {
      @include card-title-font();
    }
  }
}

.orgName {
  @include cropped-text(1);

  text-align: left;
}

.icon {
  min-width: 20px;

  & path {
    fill: lighten($ebony, 0%);
  }
}

.currency {
  font-weight: 600;
}

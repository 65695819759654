@import '../../../variables';
@import '../../../styles/common/mixins';

.question {
  position: relative;
  width: 15px;
  height: 15px;
  outline: none;

  @include max-w(xl) {
    width: 10px;
    height: 10px;
  }

  @include max-w(xs) {
    width: 20px;
    height: 20px;
    padding: 5px;
    margin: -5px;
    z-index: 2;
  }

  .icon {
    width: 100%;
    height: 100%;
    background-size: cover;
  }
}

.tooltip {
  &:global(.show) {
    padding: 0 !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    box-shadow: $tooltip-shadow !important;
  }
}

.content {
  opacity: 1;
  max-width: 312px;
  word-break: break-word;
  white-space: unset;
  font-size: 14px;
  line-height: 1.15;
  padding: 20px;

  @include max-w(xs) {
    position: relative;
    z-index: 1;
    padding: 10px;
    width: 200px;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.addNoteForm {
  position: relative;
  display: flex;
  flex-direction: column;
}

.textarea {
  height: 430px;

  .input {
    font-family: $ff-cormorant;
  }

  @include max-w(xs) {
    height: 420px;
  }
}

.button {
  max-width: 300px;

  @include max-w(xs) {
    max-width: unset !important;
  }
}

.textareaInner {
  position: relative;

  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

.counter {
  @include primary-font();

  position: absolute;
  bottom: 20px;
  right: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  background: $white;
  padding: 0.25em;
  border-radius: 0.5em;
  line-height: 1;
}

.max {
  font-weight: normal;
}

.delimiter {
  color: $gray-suit;
  margin: 0 5px;
}

.current {
  color: $gray-suit;
}

.warning,
.warning ~ .delimiter {
  color: $orange-sun;
}

.error,
.error ~ .delimiter {
  color: $error-red;
}

.buttonInner {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include max-w(xxl) {
    flex-direction: column;
    align-items: flex-end;
  }

  @include max-w(xs) {
    align-items: flex-start;
  }
}

.checkbox {
  margin-right: 55px;
  margin-bottom: 5px;

  @include max-w(xxl) {
    margin-right: 0;
  }

  @include max-w(xs) {
    margin-bottom: 10px;
  }
}

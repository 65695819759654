@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
}

.inputFieldContainer {
  @include primary-font();
  @include make-responsive(
    'max-height',
    (
      xxl: 52px,
      xxxl: 58px,
    ),
    58px,
    px
  );
  @include make-responsive(
    'padding-left',
    (
      xs: 15px,
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  width: 100%;
  max-width: 45%;
  position: relative;
  border: none;
  border-radius: 10px;
  padding: 8px 30px 8px 30px;
  color: $ebony;
  overflow: unset;
  transition: box-shadow 0.25s ease;
  box-shadow: $transparent-shadow;

  .deleteButton {
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 4px;
    z-index: 1;
    width: 12px;
    height: 12px;

    @include max-w(xs) {
      margin: 10px 15px;
    }

    &:hover {
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &:hover {
    box-shadow: $input-shadow;
  }

  &.errorBorder {
    @include error-border;
  }

  @include max-w(xs) {
    max-height: 56px;
    max-width: 100%;
  }
}

.tagsContainer {
  @include tiny-horizontal-scrollbar(5px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 8px 0;
}

.tagItem {
  @include make-responsive(
    'font-size',
    (
      xs: 14px,
      xxl: 16px,
    ),
    16px,
    px
  );

  white-space: nowrap;
  display: flex;
  gap: 2px;
  background-color: $gray-lighter;
  border-radius: 4px;
  padding: 2px 4px;
  margin: 2px;
  color: unset;
  border: 0;

  .removeBtn {
    cursor: pointer;
    transition: color 0.25s ease;
    display: flex;
    align-items: center;

    &:hover {
      color: RGB($default-accent-color);
    }

    &::after {
      content: ' x';
      margin-top: 3px;
      margin-left: 3px;
    }
  }

  &.emptyTag {
    .removeBtn {
      display: none;
    }
  }
}

.tagInput {
  @include secondary-font();

  flex: 1 1 0;
  border: 0;
  overflow: visible;
  width: unset;
  color: $ebony;
  margin-bottom: 0;
}

.error {
  color: $error-red;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  align-items: center;

  > svg {
    margin-right: 10px;
    transform-origin: left center;

    @include max-w(xxl) {
      transform: scale(0.67);
      margin-right: 0;
    }

    @include max-w(xs) {
      transform: scale(0.83);
      margin-right: 5px;
    }

    &.oneLinkIcon path {
      fill: $ebony;
    }
  }

  & > :last-of-type {
    margin-right: 0;
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  z-index: unset !important;
}

.picker {
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  flex-flow: column;
  background: $white;
  width: min-content;
  box-shadow: $tooltip-shadow;
  border-radius: 10px;
  z-index: 95;
  transform: translate(-50%, 0);

  &.mobile {
    position: static;
    transform: none;
  }
}

.start {
  color: $white;

  &:hover {
    color: $ebony;

    &:after {
      background: $deep-sky-blue !important;
    }
  }

  &:after {
    background: $deep-sky-blue;
  }
}

.calendarsPanel {
  display: flex;
  position: relative;
}

.controls {
  display: flex;
  padding: 10px 30px;
  align-items: center;
  border-top: 1px solid $gray-aero;

  @include max-w(lg) {
    padding: 8px 10px 20px;
  }
}

.controlBtn {
  max-width: 137px;

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  @include max-w(lg) {
    max-width: 101px;
  }
}

.rangeCount {
  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xxl: 14px,
      xxxl: 18px,
    ),
    18px
  );

  margin-right: auto;
  color: $gray-suit;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1;
  flex: 1;
}

.header {
  margin-bottom: 0;
}

.closeButton {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  user-select: none;
  z-index: 100;

  @include max-w(xs) {
    top: 20px;
    right: 20px;
  }
}

.content {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 1;
  flex: 1;

  @include max-w(xs) {
    max-width: calc(100% - 20px);
  }
}

.title {
  @include make-responsive(
    'font-size',
    (
      xs: 32px,
      md: 48px,
    ),
    36px,
    px
  );

  text-align: center;
  font-weight: 600;
  margin: 1em auto 0.5em;

  @include max-w(xs) {
    margin: 40px auto 0.5em;
  }
}

.info {
  @include primary-font();

  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;

  strong {
    font-weight: 600;
  }
}

.image {
  max-width: 188px;
  z-index: 0;
  margin: auto 0 20px;
}

.links {
  @include tiny-scrollbar($width: 5px, $thumb-color: $bg-color);

  overflow-y: auto;
  margin: 20px auto auto;
  padding: 0 1em;

  &:has(.inputItem) {
    width: 100%;
  }

  .linksItem {
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 0.75em;
    }
  }
}

.actions {
  display: flex;
  align-self: stretch;
  justify-content: center;
  margin: 20px 0 60px;

  @include max-w(xs) {
    margin-bottom: 40px;
  }
}

.btn {
  align-self: stretch;
}

.inputItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  height: 43px;

  .labelText {
    height: 100%;
    display: flex;
    align-items: center;
    min-width: 80px;
    border-radius: 10px 0 0 10px;
    border: 1px solid #bbc1d0;
    padding: 10px 16px;
    font-weight: 600;
  }

  .input {
    border-radius: 0;
    border-top: 1px solid #bbc1d0;
    border-bottom: 1px solid #bbc1d0;
    font-size: 12px;
    padding: 10px 12px;
    height: 43px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:focus {
      border-top: 1px solid #bbc1d0 !important;
      border-bottom: 1px solid #bbc1d0 !important;
    }
  }

  .copyButton {
    height: 100%;
    width: 72px;
    border-radius: 0 10px 10px 0;
    border: 1px solid #bbc1d0;
    border-left: none;
    font-size: 12px;
    font-weight: 600;
  }
}

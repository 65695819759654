@import '../../variables';
@import '../../styles/common/mixins';

.businessReasons {
  @include container();

  display: flex;
  flex-direction: column;

  @include max-w(xs) {
    padding: 10px 10px 0;
  }
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 810px;
  height: 100%;
  padding-bottom: 10px;
}

.addButton {
  @include max-w(xs) {
    margin-bottom: 0;
  }
}

.row {
  & > *:last-child {
    flex: unset;
  }

  @include max-w(map-get($breakpoints, xs)) {
    gap: 10px;
  }
}

.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 20px 0;

  .search {
    @include max-w(xs) {
      max-width: none;
    }
  }
}

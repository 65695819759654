@import '../../../../variables';
@import '../../../../styles/common/mixins';

.pygBtn {
  flex-shrink: 0;
  padding: 12px 30px;
  border-radius: 50px;
  box-shadow: 0 0 20px 0 rgba($midnight-blue, 0.2);
  transition: background-color 0.5s ease, transform 0.25s ease;

  @include max-w(xxl) {
    padding: 9px 20px;
  }

  @include max-w(xs) {
    position: absolute;
    left: 100%;
  }

  &.disabled {
    background-color: $deep-sky-blue;

    @include max-w(xs) {
      padding-left: 11px;
      padding-right: 20px;
      transform: translateX(-45%);
    }
  }

  &.enabled {
    background-color: $gray-suit;

    @include max-w(xs) {
      transform: translateX(0);
    }
  }

  svg {
    @include max-w(xs) {
      min-height: 12px;
    }
  }
}

.infoWrapper {
  @include make-responsive(
    'font-size',
    (
      lg: 12px,
      xxl: 18px,
      xxxl: 21px,
    ),
    18px
  );

  display: flex;
  align-items: center;
  color: $white;
  line-height: 1.15;
  white-space: nowrap;

  @include max-w(xs) {
    font-size: 18px;
  }

  & > :not(:last-child) {
    margin-right: 10px;
  }

  .giftIcon {
    @include max-w(xxl) {
      width: 12px;
      height: 12px;
    }
  }

  .backIcon {
    width: 8px;
    height: 14px;

    @include max-w(xxl) {
      width: 6.6px;
      height: 12px;
    }

    & path {
      fill: $white;
    }
  }
}

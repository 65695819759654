@import '../../variables';

:global {
  .react-grid-layout {
    margin-right: 10px;
    margin-top: -20px;
  }

  .react-grid-item.react-grid-placeholder {
    background: RGB($global-accent-color);
    border-radius: 10px;
  }
}

@import '../../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mainInfo {
  display: flex;
  gap: 30px;
  margin-bottom: 2em;

  @include max-w(md) {
    flex-direction: column;
    margin-bottom: 1em;
  }

  .uploadField {
    @include max-w(md) {
      padding: 0;
      margin-top: 15px;
    }
  }

  .verticalBlock {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.colorPicker {
  @include max-w(xs) {
    width: 50%;
    max-width: unset;
    font-size: 1rem;
  }
}

.inputContainer {
  max-width: 33%;
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;

  &.selectorInput {
    width: 100%;

    @include max-w(md) {
      max-width: 50%;
    }

    @include max-w(xs) {
      max-width: inherit;
    }
  }
}

.tagsContainer {
  @include make-responsive(
    'max-width',
    (
      xxl: 298px,
      xxxl: 406px,
    ),
    298px,
    px
  );

  width: 100%;

  @include max-w(lg) {
    margin-bottom: 20px;
  }
}

.labelWithHint {
  display: flex;

  > svg {
    order: 2;
    margin-bottom: 3px;
  }

  &:after {
    order: 1;
  }
}

.infoIcon {
  margin-left: 10px;
  align-self: center;

  @include max-w(xs) {
    margin-left: 5px;
  }
}

.tooltipImage {
  @include max-w(xs) {
    max-width: 80vw;
    width: 100%;
  }

  & > img {
    max-width: 100%;
  }
}

.info {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  margin-left: -10px;
  margin-right: -10px;

  .previewBtn {
    @include make-responsive(
      'height',
      (
        xxl: 50px,
        xxxl: 64px,
      ),
      64px,
      px
    );

    @include make-responsive(
      'width',
      (
        xxl: 232px,
        xxxl: 298px,
      ),
      298px,
      px
    );

    @include make-responsive(
      'margin-left',
      (
        xxl: 10px,
        xxxl: 20px,
      ),
      20px,
      px
    );

    @include max-w(xs) {
      height: 56px;
      width: 100%;
      margin: 0 10px;
    }
  }
}

.warning {
  margin-bottom: 10px;
}

.colorInputsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;

  @include max-w(xs) {
    gap: 10px;
  }
}

.customizeInputFieldsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
  gap: 50px;

  .tabProvider {
    flex: unset;
    width: 100%;
  }

  .customizerForm {
    display: flex;
    flex-direction: column;
    padding: 20px;

    @include max-w(xs) {
      padding: 10px;
    }
  }

  .tabLabel {
    @include secondary-font();
  }

  .fields,
  .tags {
    width: 100%;
  }

  @include max-w(xl) {
    flex-direction: column;
  }

  .info {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    flex-flow: column;
    align-items: stretch;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .inputContainer {
    width: 100%;
    max-width: unset;
    padding: 0;
    margin-bottom: 0;
  }

  .htmlEditor {
    border: none;
    border-radius: 10px;
    min-height: 270px;
    background: $white;

    &.viewContainer {
      padding: 30px;
    }
  }
}

.inputHeadingContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  padding: 6px 0;
}

.resetCustomizationButton {
  @include primary-font();

  border-bottom: 1px solid $ebony;
}

.heading {
  @include card-title-font();

  display: flex;
  margin-bottom: 20px;

  @include max-w(xs) {
    margin-bottom: 20px;
  }
}

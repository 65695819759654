@import '../../variables';

.container {
  margin-top: 10px;
  width: 100%;

  span {
    font-size: 16px;
    color: $ebony;
  }

  :global(.reactSelectContainer .reactSelect__control) {
    background-color: $white !important;
  }

  :global(.reactSelect__indicators) {
    right: 15px !important;

    & svg {
      height: 12px;
      width: 12px;
    }
  }
}

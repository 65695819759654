@import '../../variables';
@import '../../styles/common/mixins';

.container {
  padding: 20px 30px;

  @include max-w(xxl) {
    padding: 10px 20px;
  }
}

.row {
  &:not(:last-of-type) {
    margin-bottom: 30px;

    @include max-w(xxl) {
      margin-bottom: 20px;
    }
  }
}

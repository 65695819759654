@import '../../variables';
@import '../../styles/common/mixins';

.container {
  display: flex;
  align-items: flex-end;
  padding-left: 20px;

  @include max-w(xs) {
    border-right: none;
  }
}

.label {
  @include filterLabel;
}

.checkbox {
  display: none;
}

.checked {
  color: $ebony;
}

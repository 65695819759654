@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include tiny-scrollbar;

  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 60px;
  color: $ebony;
  overflow: auto;

  @include max-landscape-h(xl) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .contentHeader {
    @include heading-font();
  }

  .description {
    @include primary-font();

    padding: 20px 0 30px;
    text-align: center;

    @include max-landscape-h(xl) {
      padding-bottom: 10px;
    }
  }

  .status {
    text-align: center;

    .deleteSuccessIcon {
      max-height: 70px;
      max-width: 70px;
    }

    .link {
      @include make-responsive(
        'font-size',
        (
          xxl: 18px,
          xxxl: 21px,
        ),
        21px,
        px
      );

      width: auto;
      padding: 0;
      margin: 20px auto 10px;
      font-weight: 500;
      color: $midnight-blue;
      text-decoration: underline;
      box-shadow: unset;
      background-color: unset;
      line-height: 1.15;

      @include max-w(xxl) {
        margin-top: 0;
      }
    }

    .linkInfo {
      @include micro-font();
    }
  }
}

.footer {
  justify-content: center;
  padding-top: 30px;

  @include max-landscape-h(xl) {
    padding-top: 10px;
  }
}

.loader {
  background-color: $bg-color;
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;
  @include make-responsive(
    'padding-right',
    (
      xs: 10px,
      xxl: 34px,
      xxxl: 289px,
    ),
    289px
  );

  flex-direction: column;
}

.form {
  padding-bottom: 30px;

  @include max-w(xs) {
    padding-bottom: 20px;
  }
}

.controls {
  margin-top: 0;
  display: flex;
  justify-content: flex-end;

  .button {
    @include max-w(xs) {
      max-width: 100%;
    }
  }
}

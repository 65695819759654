@import '../../../variables';
@import '../../../styles/common/mixins';

.title {
  flex: 1;
  display: grid;
  grid-template-columns: 9fr 6.25fr 3.25fr 5.5fr auto;
  align-items: center;
  word-break: break-word;

  .name {
    @include make-responsive(
      'font-size',
      (
        xxl: 18px,
        xxxl: 24px,
      ),
      24px
    );

    line-height: 1.15;
  }

  .price {
    @include make-responsive(
      'font-size',
      (
        xxl: 21px,
        xxxl: 24px,
      ),
      24px
    );

    font-weight: 600;
    line-height: 1.15;

    &.lowPrice {
      color: $error-red;
    }
  }

  .sends {
    @include primary-font;
  }

  .iconWrapper {
    & > :not(:last-of-type) {
      margin-right: 10px;
    }

    .icon {
      width: 18px;
      height: 18px;
      fill: $solitude;

      &.error path {
        fill: $error-red;
      }

      @include max-w(xxl) {
        width: 15px;
        height: 15px;
      }

      path {
        stroke: $white;
      }
    }
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 262px;
  min-width: 262px;
  border-left: 1px solid $gray-blue;

  @include max-w(xxl) {
    max-width: 214px;
    min-width: 214px;
  }
}

.hoverArea {
  @include make-responsive(
    'padding',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px
  );

  width: 100%;
  position: absolute;
  text-align: center;
}

.totalSpent {
  @include make-responsive(
    'font-size',
    (
      xxl: 14px,
      xxxl: 18px,
    ),
    18px
  );

  color: $gray-suit;
  margin-bottom: 20px;
}

.total {
  @include make-responsive(
    'font-size',
    (
      xxl: 21px,
      xxxl: 30px,
    ),
    30px
  );

  color: $ebony;
  font-weight: 600;
  justify-content: center;
  margin-top: 10px;
}

.countsList {
  @include make-responsive(
    'font-size',
    (
      xxl: 16px,
      xxxl: 21px,
    ),
    21px
  );

  width: 100%;
  display: flex;
  flex-flow: column;
}

.countRow {
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 12px,
      xxxl: 15px,
    ),
    15px
  );

  width: 100%;
  display: flex;
  justify-content: space-between;
}

.label {
  @include make-responsive(
    'font-size',
    (
      xxl: 14px,
      xxxl: 18px,
    ),
    18px
  );

  margin-right: 10px;
}

.value {
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.button {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  @include max-w(xxl) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include max-w(xs) {
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 3px;
  }
}

.filled {
  .icon path {
    fill: $white;
  }
}

.reset {
  @include reset-button;
}

.normal {
  .icon {
    path {
      fill: rgbCl($global-accent-color);
    }

    width: 25px;
    height: 25px;

    @include min-w(get-breakpoint-value(xxl) + 1) {
      width: 18px;
      height: 18px;
    }

    @include max-w(xxl) {
      width: 20px;
      height: 20px;
    }

    @include max-w(xs) {
      width: 10px;
      height: 10px;
    }
  }

  .spinner {
    &:after {
      // It isn't needed on mobile breakpoint because button has static size on this breakpoint
      @include min-w(get-breakpoint-value(xs) + 1) {
        box-sizing: border-box;
      }
    }
  }
}

.small {
  .icon {
    width: 14px;
    height: 14px;

    @include max-w(xs) {
      width: 10px;
      height: 10px;
    }
  }
}

.loader {
  position: static;
  background: none;
}

.icon {
  path {
    transition: all 0.5s ease;
  }
}

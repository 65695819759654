@import '../../variables';
// Thin scrollbars styling
$thumbHeight: 50px;

@mixin tiny-scrollbar($width: 8px, $thumb-color: rgba(112, 112, 112, 0.15), $track-color: transparent) {
  &::-webkit-scrollbar {
    width: $width;
  }

  &::-webkit-scrollbar-thumb {
    height: $thumbHeight;
    border-radius: 5px;
    background-color: lighten($thumb-color, 5%);
    transition: all 0.25s ease;

    &:hover {
      background-color: darken($thumb-color, 5%);
    }
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.5px;
    background-color: $track-color;
  }
}

@mixin tiny-horizontal-scrollbar($height: 8px, $thumb-color: $gray-blue, $track-color: $white) {
  @include tiny-scrollbar($thumb-color: $thumb-color, $track-color: $track-color);

  &::-webkit-scrollbar {
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
}

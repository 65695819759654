@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: relative;
  padding: 4px 50px 0 135px;
  background-color: #f1c40f;
  color: $ebony;
  opacity: 0.5;
  transition: 0.2s ease;
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 40px;

  @include max-w(xxl) {
    padding: 4px 30px 0 40px;
  }

  @include max-w(md) {
    padding: 4px 20px 0;
  }

  @include max-w(xs) {
    padding: 4px 10px 0;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-top: -4px;

    path {
      fill: $ebony;
    }
  }

  span {
    @include primary-font();

    line-height: 1;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.baseRow {
  display: flex;
  flex-direction: row;

  @include max-w(xs) {
    flex-direction: column;
  }
}

.baseInfo {
  margin: 0 0 15px;

  @include max-w(xs) {
    margin: 0 0 10px;
  }

  .uploadField {
    margin-right: 30px;
    margin-bottom: 15px;

    @include max-w(xs) {
      padding: 0;
      margin: 10px auto;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -15px 0;
    width: 100%;
    position: relative;

    @include max-w(md) {
      flex-flow: column;

      @include max-w(xs) {
        margin: auto;
      }
    }

    .infoRow {
      display: flex;
      flex-direction: row;

      & > :not(:last-of-type) {
        @include make-responsive(
          'padding-right',
          (
            xxl: 10px,
            xxxl: 20px,
          ),
          20px
        );
      }

      @include max-w(xs) {
        flex-direction: column;
        padding-right: 0;
      }
    }

    .departmentInput {
      width: 100%;

      @include max-w(xs) {
        max-width: unset;
      }

      .label {
        @include label();
      }

      .departmentSelector {
        width: 100%;
      }
    }

    .headerInputField {
      width: 100%;
      flex: 33.3333%;

      @include max-w(get-breakpoint-value(xl)) {
        flex: 50%;
      }
    }

    .headerInputRow {
      display: flex;
      flex-wrap: wrap;
    }

    .inputDescription {
      padding-right: 10px;

      @include max-w(get-breakpoint-value(xs)) {
        padding-right: 0;
      }
    }
  }
}

.additionalInfo {
  flex-flow: row wrap;
  margin: 0 -10px;

  @include max-w(xxl) {
    margin: 0 -5px;
  }

  @include max-w(xs) {
    margin: 0;
  }
}

.additionalInfoInputWrapper {
  display: flex;
  width: 100%;
  padding: 0 10px;
  box-sizing: content-box;

  @include max-w(xxl) {
    padding: 0 5px;
  }

  @include max-w(xs) {
    width: 100%;
    padding: 0;
  }
}

.input {
  max-width: 406px;
  margin: 15px 0;

  .label {
    display: flex;
    gap: 10px;
  }

  @include max-w(xxl) {
    max-width: 298px;
  }

  @include max-w(md) {
    max-width: 202px;
  }

  @include max-w(xs) {
    max-width: 100%;
  }
}

.placeholderContainer {
  margin-top: 30px;
  max-width: 50%;

  .placeholderLabel {
    @include primary-font();

    text-align: left;
    max-width: 70%;

    @include max-w(xs) {
      max-width: unset;
    }
  }

  .placeholderImage {
    text-align: center;
  }

  @include max-w(xs) {
    max-width: unset;
  }
}

.itemCustomizationContainer {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0 10px;
  box-sizing: content-box;

  .checkbox {
    @include secondary-font();
  }

  @include max-w(xxl) {
    padding: 0 5px;
  }

  @include max-w(xs) {
    width: 100%;
    padding: 0;
  }
}

.heading {
  @include make-responsive('font-size', $values: (xxl: 18px, xxxl: 26px));

  display: flex;
  margin-bottom: 10px;
  font-weight: 600;

  @include max-w(xs) {
    margin-bottom: 20px;
  }
}

.fcSelectContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.textareaContainer {
  padding: 15px 0 20px;
  height: 280px;

  @include make-responsive(
    'width',
    (
      lg: 827px,
      xxxl: 690px,
    ),
    690px,
    px
  );

  @include max-w(lg) {
    width: 100%;
  }

  @include min-w(get-breakpoint-value(xxxl) + 1) {
    width: 50%;
  }
}

.preorderToggle {
  margin: 10px 0;
}

.toggleWrapper {
  position: relative;
  width: 100%;
}

.warning {
  position: absolute;
}

.quantityInfo {
  align-items: center;
  gap: 15px;
  margin: 20px 0;
}

.thresholdInput {
  max-width: 350px;

  @include max-w(xs) {
    max-width: 100%;
  }

  & svg {
    margin-left: 10px;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-flow: row wrap;
  row-gap: 5px;

  &:not(.noBorder) {
    .input {
      border: 1px solid $gray-suit;
    }

    .countrySelect {
      :global {
        .reactSelect__control {
          border-radius: 10px;
          border: 1px solid $gray-suit;
        }
      }
    }
  }

  .input {
    &.errorBorder {
      @include error-border;
    }
  }
}

.inputContainer {
  max-width: 49%;
  margin: 10px 0;

  @include max-w(xs) {
    max-width: 100%;
  }

  &.fullWidth {
    max-width: inherit;
  }
}

.optionsList {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  .label {
    @include primary-font(
      (
        xs: 16px,
        xxl: 20px,
        xxxl: 24px,
      )
    );

    margin-top: 30px;
    font-weight: 500;

    @include max-w(xs) {
      display: none;
    }
  }
}

.input {
  border: 1px solid transparent;
}

.inputWidth {
  width: 50%;
}

.fullWidth {
  width: 100%;
}

.selector {
  margin: 40px 0 20px 0;
}

.countrySelect {
  :global {
    .reactSelect__control {
      border-radius: 10px;
      border: 1px solid transparent;

      &.reactSelect__control--is-disabled {
        filter: none;
        background: lighten($bg-color, 2.5%);
        color: rgba(43, 47, 56, 0.2);

        .reactSelect__placeholder,
        .reactSelect__indicators {
          opacity: 0.2;
        }
      }
    }
  }

  &.errorBorder {
    :global {
      .reactSelect__control {
        border: 1px solid transparent !important;
      }
    }
  }
}

.resetContainer {
  width: 49%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 10px;

  .resetBtn {
    @include hover-underline($error-red);

    color: $error-red;
  }
}

.errorBorder {
  @include error-border;
}

@import '../../variables';
@import '../../styles/common/mixins';

$table-min-height-default: 135px;
$grid-columns-default: 1fr 120px 1fr 1fr 70px;
$table-min-height-default: 135px;

.container {
  @include tiny-scrollbar($width: 4px, $thumb-color: $gray-table, $track-color: transparent);
  @include tiny-horizontal-scrollbar($height: 4px, $thumb-color: $gray-table, $track-color: transparent);

  border-radius: 10px;
  border: 1px solid $gray-table;
  background-color: $white;
  overflow: auto;
  min-height: 200px;
}

.widthLimit {
  min-width: 800px;
}

.head {
  display: grid;
  grid-template-columns: $grid-columns-default;
  border-bottom: 1px solid $gray-table;
  position: sticky;
  top: 0;
  background: $white;
  z-index: 1;

  .cell {
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;

    &:hover {
      cursor: default;
    }
  }
}

.body {
  max-height: 100%;
}

.row {
  position: relative;
  display: grid;
  grid-template-columns: $grid-columns-default;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-table;
  }
}

.cell {
  @include cropped-text;

  padding: 15px 20px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    display: flex;
    justify-content: flex-end;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $table-min-height-default;
}

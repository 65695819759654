@import '../../variables';
@import '../../styles/common/mixins';

.container {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
  background: $white;
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  flex: 0;
  max-width: 100%;
  padding-top: 30px;

  @include max-w(xxl) {
    padding-top: 15px;
  }

  @include max-w(md) {
    flex: 1;
  }

  @include max-w(xs) {
    padding-top: 20px;
  }

  &.clickable {
    cursor: pointer;
  }

  &.expanded {
    flex: 1;
    width: 100%;

    .status {
      @include min-w(get-breakpoint-value(md) + 1) {
        justify-content: flex-start;
      }
    }

    .card {
      padding: 0 15px 30px 30px;
      max-width: 100%;

      @include max-w(xxl) {
        padding: 0 10px 20px 20px;
      }

      @include max-w(xl) {
        padding-right: 20px;
        padding-left: 20px;
      }

      @include max-w(xs) {
        padding: 0 10px 26px;
      }

      &.details {
        margin-left: 30px;

        @include max-w(xl) {
          margin-left: 0;
        }
      }
    }
  }

  &.oneLink {
    position: relative;
    flex: unset;

    @include max-w(xl) {
      flex-flow: column;
    }

    .createdBy {
      margin-top: unset;
      margin-bottom: 10px;

      @include max-w(xs) {
        max-width: 50%;
      }
    }
  }
}

.confirmedSends {
  position: absolute;
  top: 30px;
  right: 30px;

  @include max-w(xxl) {
    top: 15px;
    right: 20px;
  }

  @include max-w(xs) {
    max-width: 50%;
    text-align: right;
    top: 20px;
    right: 10px;
  }
}

.createdBy {
  margin-bottom: 10px;
  margin-top: -5px;
}

.status {
  @include primary-font();

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 26px;
  line-height: 1.15;

  @include max-w(xxl) {
    padding: 0 20px 20px;
  }

  @include max-w(xs) {
    padding: 0 10px 15px;
  }

  .statusModule {
    margin-left: 30px;
  }
}

.card {
  background: unset;
  border-radius: 0;
  padding-top: 0;

  @include max-w(xxl) {
    padding: 0 15px 20px;
  }

  @include max-w(lg) {
    width: 100%;
    min-width: unset;
  }

  @include max-w(xs) {
    padding: 0 10px 26px;
  }

  .people {
    padding-top: 0;
    margin-top: 0;

    @include max-w(md) {
      flex-wrap: wrap;
    }

    .arrowIcon {
      margin: 0 15px;
      min-width: 20px;

      @include max-w(xxl) {
        margin: 0 10px;
      }

      @include max-w(md) {
        align-self: flex-start;
        margin-left: 0;
      }
    }

    .sender {
      @include max-w(md) {
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 18px;
      }
    }
  }
}

.controlsWrapper {
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 28px;

  @include max-w(xxl) {
    margin-top: 20px;
  }

  @include max-w(xs) {
    margin-top: 15px;
  }

  .controls {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > :not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

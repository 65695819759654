@import '../../../../styles/common/mixins';

.container {
  position: relative;

  :global(.reactSelectContainer) {
    min-width: 150px;
  }

  :global(.reactSelect__menu) {
    margin-top: 5px;
  }

  &.hasValue {
    :global(.reactSelect__dropdown:not(.opened)) {
      opacity: 0.2;
    }

    &:hover {
      :global(.reactSelect__dropdown:not(.opened)) {
        opacity: 1;
      }
    }
  }

  &.noCode {
    :global(.reactSelectContainer) {
      min-width: 30px;
    }

    :global(.reactSelect__control) {
      box-shadow: none;
      border: none;
      border-radius: 0;
      background-color: transparent;

      &:focus,
      &:hover {
        box-shadow: none;
      }
    }

    :global(.reactSelect__menu) {
      width: 150px;

      transform: translate(-15px);

      @include min-w(xl) {
        transform: translateX(-20px);
      }

      @include min-w(xxl) {
        transform: translateX(-25px);
      }

      @include min-w(xxxl) {
        transform: translateX(-30px);
      }
    }

    :global(.reactSelectContainer) {
      width: 40px;
      @include make-responsive(
        'margin-left',
        (
          xs: 15px,
          xxl: 20px,
          xxxl: 30px,
        ),
        30px,
        px
      );
    }

    :global(.reactSelect__single-value) {
      width: 10px;
    }

    :global(.reactSelect__value-container) {
      padding: 5px;
    }

    :global(.reactSelect__indicators) {
      right: 5px;
    }
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.itemQuantityContainer {
  position: absolute;
  right: -1px;
  top: -1px;
  border-radius: 3px;
  padding: 3px 6px;
  background-color: $gray-blue;

  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      right: -0.5px;
      top: -0.5px;
    }
  }
}

.quantityValue {
  text-align: center;
  font-size: 12px;
  color: $ebony;
  line-height: 14px;
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include container;
  @include make-fluid('padding-right', 75px, 300px, get-breakpoint-value(xl), get-breakpoint-value(xxxl), 300px, px);

  height: fit-content;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 65px;

  @include max-w(xxl) {
    padding-bottom: 50px;
  }

  @include max-w(xs) {
    padding-bottom: 40px;
  }
}

.actionBtn {
  @include secondary-font();
  @include hover-underline($gray-light, 2px);

  align-self: center;

  &.activate {
    @include hover-underline($budget-green, 2px);

    color: $budget-green;
  }

  &.deactivate {
    @include hover-underline($error-red, 2px);

    color: $error-red;
  }

  &.department {
    width: auto;
    height: auto;
  }
}

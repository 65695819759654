@import '../../../../../../variables';
@import '../../../../../../styles/common/mixins';

.container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
  flex-flow: column;
}

.userNames {
  @include secondary-font;
  @include cropped-text(1);

  text-align: left;
  flex: 1 0;
}

.roleAndDept {
  @include make-responsive(
    'font-size',
    (
      xl: 14px,
      xxl: 16px,
    ),
    14px,
    px
  );
  @include cropped-text;

  text-align: left;
  flex: 1 1;
  color: $gray-suit;
}

@import '../../variables';

@mixin primary-font(
  $values: (
    xs: 16px,
    xxl: 17px,
    xxxl: 18px,
  ),
  $default: 18px
) {
  @include make-responsive('font-size', $values, $default, px);
  line-height: 1.15;
}

@mixin secondary-font(
  $values: (
    xs: 14px,
    xxl: 16px,
    xxxl: 17px,
  ),
  $default: 17px
) {
  @include make-responsive('font-size', $values, $default, px);

  line-height: 1.15;
}

@mixin micro-font(
  $values: (
    xs: 12px,
    xxl: 14px,
    xxxl: 16px,
  ),
  $default: 17px
) {
  @include make-responsive('font-size', $values, $default, px);

  line-height: 1.15;
}

@mixin card-title-font(
  $values: (
    xs: 18px,
    xxl: 18px,
    xxxl: 22px,
  ),
  $default: 22px,
  $weight: 600
) {
  @include make-responsive('font-size', $values, $default, px);

  font-weight: $weight;
  line-height: 1.15;
}

@mixin heading-font(
  $values: (
    xs: 24px,
    xxl: 26px,
    xxxl: 30px,
  ),
  $default: 30px,
  $weight: 600
) {
  @include make-responsive('font-size', $values, $default, px);

  font-weight: $weight;
  line-height: 1.15;
}

@mixin label() {
  @include secondary-font;

  color: $gray-suit;
  margin-bottom: 0.45em;
}

@mixin filterLabel() {
  @include make-responsive(
    'font-size',
    (
      xs: 14px,
      xxl: 16px,
      xxxl: 17px,
    ),
    17px
  );

  width: max-content;
  cursor: pointer;
  user-select: none;
  color: $gray-suit;
}

@mixin clearlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@mixin pulse-animation($color: RGB($default-accent-color)) {
  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: $color;
    border-radius: 50%;
    z-index: -1;
  }

  &:before {
    animation: pulse 0.25s ease-out 0s;
  }

  @keyframes pulse {
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
}

@mixin error-border {
  border: 1px solid $error-red;
}

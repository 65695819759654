@import '../../variables';
@import '../../styles/common/mixins';

.list {
  @include tiny-scrollbar;

  max-height: 360px;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;

  .item {
    position: relative;
    padding: 8px 10px 5px;
    min-height: 60px;
    background: $white;
    display: flex;
    flex-flow: column;
    text-align: left;
    align-items: stretch;
    justify-content: center;
    transition: all 0.25s ease;

    &.noResults {
      text-align: center;
    }

    &:not(:first-of-type) {
      border-top: 1px solid $modal-overlay;
    }

    &:not(.noResults):hover {
      box-shadow: inset 0 -2px 10px rgba(0, 0, 0, 0.08);
    }

    .badge {
      transform: translateY(-25%);
      width: 40px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      line-height: 1;
      color: $white;
      border-radius: 10px;
      padding-top: 2px;

      &.lead {
        background: $light-navy;
      }

      &.contact,
      &.customer {
        background: $orange-header;
      }
    }

    .caption {
      font-size: 14px;
      margin-bottom: 5px;
      display: flex;

      .contactInfo {
        margin-right: auto;

        .email {
          font-style: italic;
        }
      }

      .phone {
        margin-right: 15px;
        font-style: italic;
      }
    }

    .additionalInfo {
      display: flex;

      .description {
        margin-right: auto;

        font-size: 12px;
        color: $gray-suit;
        font-style: italic;
      }

      .company {
        font-size: 14px;
        color: $ebony;
      }
    }
  }
}

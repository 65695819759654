@import '../../variables';
@import '../../styles/common/mixins';

.pagination {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .currentPage {
    width: 40px;
    margin-right: 10px;
  }

  .currentPageInput {
    @include primary-font();

    padding: 0.35em 0.25em 0.25em;
    text-align: center;
  }

  .totalPages {
    color: $light-blue;

    @include primary-font();
  }

  .prevBtn {
    padding-right: 20px;
  }

  .nextBtn {
    padding-left: 20px;
  }

  .prevBtn,
  .nextBtn {
    transform: translateY(2px);

    &:disabled {
      cursor: default;

      svg path {
        fill: $gray-blue;
      }
    }

    svg {
      height: 15px;
      width: auto;

      path {
        fill: $ebony;
      }
    }
  }
}

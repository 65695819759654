@import '../../../variables';
@import '../../../styles/common/mixins';

.controls {
  display: flex;
  padding: 30px 0;

  @include min-w(get-breakpoint-value(xs) + 1) {
    margin-top: 30px;
    justify-content: flex-end;
  }

  > *:last-child {
    @include make-responsive(
      'margin-left',
      (
        xxl: 30px,
        xxxl: 50px,
      ),
      50px
    );
  }

  .saveBtn {
    @include max-w(xs) {
      max-width: 100%;
      align-self: center;
      margin-left: auto;
    }
  }

  .status {
    @include primary-font();

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .statusIcon {
      width: 15px;
      height: 15px;
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }
}

.optionalControls {
  display: flex;
  justify-content: flex-end;

  & > :not(:first-child) {
    @include make-responsive(
      'margin-left',
      (
        xs: 10px,
        xxl: 30px,
        xxxl: 50px,
      ),
      50px
    );
  }

  & ~ .content {
    padding-top: 10px;
  }

  .textBtn {
    @include secondary-font();
    @include hover-underline($gray-light, 2px);

    align-self: center;
    color: $gray-suit;
    white-space: nowrap;

    @include max-w(xs) {
      font-size: 14px;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    &.delete {
      @include hover-underline($error-red, 2px);

      color: $error-red;
      margin-right: 0;
    }
  }
}

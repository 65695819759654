@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  position: fixed;
  bottom: 120px;
  right: 50px;
  z-index: 1;

  @include max-w(xs) {
    width: 100%;
    bottom: 0;
    right: 0;
    background: $bg-color;
    padding: 10px 0;
    box-shadow: $mobile-floating-shadow;
    z-index: 90;
    transform-origin: bottom center;
  }
}

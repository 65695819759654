@import '../../variables';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.addressContainer {
  width: 100%;
}

.address {
  color: $ebony;
}

@import '../../variables';
@import '../../styles/common/mixins';

.addNoteContainer {
  @include container();
  flex-direction: row;

  @include max-w(lg) {
    flex-direction: column;
    max-width: 570px;
  }

  @include max-w(md) {
    padding: 10px 10px 25px;
  }
}

.subHeading {
  @include primary-font();

  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 57%;

  @include make-responsive(
    'margin-right',
    (
      xxl: 30px,
      xxxl: 40px,
    ),
    40px,
    px
  );

  @include make-responsive(
    'max-width',
    (
      xxl: 528px,
      xxxl: 835px,
    ),
    835px,
    px
  );

  @include max-w(lg) {
    margin-bottom: 20px;
    max-width: unset;
    width: unset;
    margin-right: 0;
  }

  @include max-w(xs) {
    padding: 0;
  }
}

.dropdown {
  @include make-responsive(
    'max-width',
    (
      xxl: 298px,
      xxxl: 405px,
    ),
    405px,
    px
  );

  @include max-w(xs) {
    max-width: unset;
  }

  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

.inventorySidebar {
  padding: 0;
  @include make-responsive(
    'max-width',
    (
      xxl: 488px,
      xxxl: 617px,
    ),
    617px,
    px
  );

  @include max-w(lg) {
    max-width: unset;
  }
}

.label {
  @include label();
}

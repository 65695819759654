@import '../../../../variables';
@import '../../../../styles/common/mixins';

.addButton {
  @include max-w(xs) {
    max-width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 3px;
  }
}

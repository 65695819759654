@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.suggestGiftContainer {
  @include tiny-scrollbar();

  overflow-y: auto;
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include max-w(xs) {
    padding: 30px 20px;
  }

  img {
    margin-bottom: 20px;
    height: 376px;
    width: 682px;

    @include max-w(xl) {
      width: 100%;
      height: unset;
    }
  }

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;

    font-size: 30px;
    line-height: 1.3;
    font-weight: 600;
  }

  .title {
    font-size: 26px;
    line-height: 1.3;
    font-weight: 600;
  }

  .description {
    @include primary-font();

    display: flex;
    flex-direction: column;
    gap: 15px;

    ol {
      list-style-type: decimal;
    }

    .step {
      margin-left: 24px;
    }
  }

  .submit {
    margin-top: 20px;

    @include max-w(xs) {
      align-self: center;
    }
  }
}

.closeBtn {
  position: absolute;
  top: -10px;
  right: 0;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

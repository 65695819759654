@import '../../../../variables';
@import '../../../../styles/common/mixins';

.item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;

  .code {
    @include primary-font;

    text-transform: uppercase;
    margin-right: auto;
  }

  .copyButton {
    width: unset;
    height: 100%;
    margin-right: 10px;
  }

  .viewSummaryButton {
    @include button-shadow;

    transition: all 0.5s ease;
    width: 38px;
    height: 38px;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.improveContainer {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px;

  .selector {
    width: 100%;
  }

  .improveButton {
    width: 200px;
  }

  .instructionContainer {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    .loaderButton {
      align-self: center;
      justify-self: center;
      position: unset;
      max-width: 20px;
      max-height: 20px;
    }

    @include max-w(xs) {
      flex-direction: column;
      gap: 10px;
    }
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.formWrapper {
  display: flex;

  @include max-w(xl) {
    flex-flow: column;
  }
}

.container {
  flex: 2;

  @include max-w(xxl) {
    flex: 4;
  }

  @include max-w(xl) {
    flex: 4;
  }
}

.metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.campaignInfo {
  display: flex;

  @include max-w(md) {
    flex-flow: column;
  }
}

.shippingInfo {
  display: flex;
  flex-flow: column;
  min-width: 550px;

  @include max-w(md) {
    min-width: 100%;
  }
}

.optionsInfo {
  display: flex;
  flex-flow: column;
  min-width: 60%;
  margin-top: 15px;

  @include min-w(xxl) {
    margin-left: 50px;
  }
}

.enableShippingConfig {
  @include max-w(md) {
    margin-bottom: 10px;
  }
}

.inputContainer {
  margin-bottom: 30px;
  max-width: 406px;
  display: flex;
  flex-flow: column;

  @include max-w(md) {
    max-width: inherit;
  }

  .label {
    @include label();

    &.name {
      line-height: 0.9;
    }
  }
}

.iconInput {
  margin-right: 30px;
  margin-bottom: 30px;

  @include max-w(md) {
    align-self: center;
    margin-right: unset;
  }
}

.templates {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;

  @include max-w(md) {
    flex-flow: column;
  }

  .inputContainer {
    flex: 1 1 50%;
    max-width: calc(50% - 10px);

    @include max-w(md) {
      flex: 1 1 100%;
      max-width: inherit;
    }

    .error {
      white-space: nowrap;
    }

    .priority {
      max-width: 135px;
      margin-right: auto;

      input {
        text-align: center;
        padding: 22px 30px;
      }
    }
  }
}

.preview {
  flex: 1;

  .label {
  }
}

.checkboxes_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.wrapper {
  display: flex;
  width: 100%;

  @include max-w(xxl) {
    flex-flow: column;
  }
}

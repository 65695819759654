@import '../../../../variables';
@import '../../../../styles/common/mixins';

.actionButton {
  @include primary-font();

  width: 100%;
  display: flex;
  flex-direction: row;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  font-weight: 500;

  @include max-w(xs) {
    font-size: 18px;
  }
  &:disabled {
    background: #babdc4;
    color: #dadfe9;
    border: unset;

    &:hover {
      cursor: unset;
    }
  }

  &.inline {
    @include hover-underline($gray-suit, 2px);

    color: $gray-suit;
    padding: 0;
    width: auto;
    background: none;
    box-shadow: none;
    position: relative;

    &:hover {
      box-shadow: none;
    }
  }
}

.main {
  @include button-shadow;

  color: $white;
  background: rgbCl($global-accent-color);

  &.outlined {
    background: unset;
    color: rgbCl($global-accent-color);
    border: 1px solid rgbCl($global-accent-color);

    &:disabled {
      color: $gray-suit;
      border-color: $gray-suit;
    }
  }
}

.secondary {
  @include button-shadow;

  color: $midnight-blue;
  border: 1px solid $midnight-blue;
}

.normal {
  max-width: 300px;
  width: 100%;
  padding: 20px 0;
  border-radius: 10px;

  @include max-w(xxl) {
    max-width: 231px;
    padding: 15px 0;
  }

  @include max-w(xs) {
    max-width: 262px;
    width: 100%;
    padding: 17px 0;
  }
}

.small {
  padding: 10px 12px;
  border-radius: 6px;
  font-size: 16px;
}

.icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;

  svg path {
    stroke: rgbCl($global-accent-color);
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: relative;
  background-color: $white;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0.6em 0.9em;
  box-shadow: $element-shadow;
  border-radius: 20px 10px 10px 10px;
  z-index: 1;

  &.indicator {
    cursor: default;
    z-index: unset;
  }

  @include max-w(xxl) {
    border-radius: 10px;
  }

  @include make-responsive(
    'font-size',
    (
      xxl: 14px,
      xxxl: 16px,
    ),
    16px,
    px
  );

  @include max-w(xs) {
    padding: 5px;
    max-height: 26px;
  }

  .inventoryIcon {
    fill: $ebony;
    height: 15px;
    width: 15px;

    @include max-w(xxl) {
      height: 12px;
      width: 12px;
    }
  }

  .count {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 5px;
    margin-bottom: -0.3em;
  }
}

.noCount {
  padding: 5px;
  border-radius: 10px 5px 5px 5px;

  .countHint {
    position: static;
    transform: none;
    width: 1rem;
    height: 1rem;
  }
}

.countHint {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.75rem;
  height: 0.75rem;
  transform: translate(25%, 25%);
}

.countHintContent {
  text-align: left;
  color: $tangerine;
  font-family: $ff-gilroy;

  &.error {
    color: $error-red;
  }
}

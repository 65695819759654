@import '../../variables';
@import '../../styles/common/mixins';

:root {
  --grid-templates-per-row: 3;
  --grid-templates-margin: 1.5vw;

  @include max-w(xxxl) {
    --grid-templates-per-row: 2;
  }
  @include max-w(xl) {
    --grid-templates-per-row: 1;
  }
}

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.header {
  @include container;

  display: flex;
  padding-bottom: 0;

  @include max-w(md) {
    flex-direction: column;
    align-items: flex-start;

    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @include max-w(xs) {
    margin-bottom: 0;
  }

  & > * {
    @include make-responsive(
      'margin-right',
      (
        xs: 20px,
        xxl: 30px,
        xxxl: 33px,
      ),
      33px
    );
  }
}

.addButton {
  margin-left: auto;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-left: 0;
    max-width: 100%;
  }
}

.listWrapper {
  @include tiny-scrollbar();

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 9px;
  }

  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.list {
  @include container;
  @include fluidRow($margin: var(--grid-users-margin));

  padding-top: 0 !important;

  @include max-w(xs) {
    right: 0;
  }
}

.dropdownFilter {
  position: relative;
  margin-right: 10px;

  @include min-max-w(xs, md) {
    margin-bottom: 10px;
  }

  .dropdownContent {
    width: 335px;
    transform: translateX(-30%);

    @include max-w(md) {
      transform: unset;
      top: 140%;
      width: 280px;
    }
  }
}
.card {
  @include fluidCell($perRow: var(--grid-templates-per-row), $margin: var(--grid-templates-margin));

  justify-content: space-between;

  @include max-w(xl) {
    max-width: 620px;
  }

  .top {
    align-items: flex-start;

    @include max-w(xs) {
      flex-flow: row wrap;
    }

    .templateInfo {
      display: flex;
      flex-flow: column;
      flex: 1;
      padding-right: 30px;
      overflow: hidden;
      white-space: nowrap;

      & > * {
        margin-bottom: 10px;
      }

      .title {
        @include card-title-font();

        text-overflow: ellipsis;
        overflow: hidden;
      }

      .type {
        @include primary-font(
          (
            xs: 16px,
            xxxl: 20px,
          )
        );

        text-overflow: ellipsis;
        overflow: hidden;

        @include max-w(xs) {
          order: -1;
          font-weight: 700;
        }
      }

      .emailType {
        @include primary-font();
      }
    }

    .deptInfo {
      @include primary-font();

      display: flex;
      flex-flow: column;
      align-items: flex-end;

      & > *:not(:last-child) {
        margin-bottom: 10px;
      }

      .mainDept {
        color: $gray-light;
      }

      .additionalDept {
        .badge {
          background-color: $medium-purple;
          color: $white;
          padding: 4px;
          border-radius: 20px;
        }

        .additionalDepartsList {
          flex-flow: column;
          width: max-content;
          max-width: 247px;
          top: calc(100% + 10px);
          left: 50%;
          transform: translate(-50%, 0);
          padding: 30px 30px 20px;
          background-color: $white;
          border-radius: 5px;
          box-shadow: $tooltip-shadow;
          word-wrap: break-word;

          @include max-w(xs) {
            left: 100%;
            transform: translate(-100%, 0);
            padding: 15px;
          }

          & > :not(:last-of-type) {
            @include primary-font();

            padding-bottom: 0.5em;
          }
        }
      }
    }
  }

  .middle {
    margin: 0;
  }

  .controlsWrapper {
    @include max-w(xs) {
      justify-content: space-between;
    }

    .controls {
      display: flex;
      flex: 1;

      @include max-w(xs) {
        justify-content: flex-end;
      }
    }
  }
}

.actionBtn {
  @include primary-font();
  @include hover-underline($gray-light, 2px);

  color: $gray-light;

  &:not(:last-of-type) {
    margin-right: 15px;

    @include max-w(xs) {
      margin-right: 10px;
    }
  }

  &.delete {
    @include hover-underline($error-red, 2px);

    color: $error-red;
  }
}

.dropdownLabel {
  @include secondary-font();
}

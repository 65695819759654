@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include make-responsive(
    'min-width',
    (
      lg: 240px,
      xxl: 254px,
      xxxl: 354px,
    ),
    354px
  );

  @include max-w(md) {
    max-width: calc(100% - 30px);
    min-width: unset;
  }

  @include max-w(xs) {
    height: auto;
  }

  .top {
    @include card-title-font();
    @include cropped-text;

    white-space: nowrap;
    margin-bottom: 5px;
  }

  .middle {
    @include cropped-text;
    @include secondary-font();

    white-space: nowrap;
    margin-bottom: 20px;
  }

  .bottom {
    @include secondary-font();

    color: $gray-suit;
  }
}

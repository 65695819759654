@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include secondary-font;

  display: flex;
  flex-wrap: wrap;
  color: $gray-suit;

  &:not(.wide) {
    .option {
      display: flex;
      width: unset;
      flex: unset;

      &:not(:first-of-type) {
        @include make-responsive(
          'margin-left',
          (
            xs: 3px,
            xxl: 5px,
          ),
          5px
        );

        &:before {
          @include make-responsive(
            'margin-right',
            (
              xs: 3px,
              xxl: 5px,
            ),
            5px
          );

          content: '';
          flex-shrink: 0;
          width: 1px;
          background: $gray-suit;
        }
      }
    }
  }
}

.option {
  position: relative;
  width: 100%;
  display: flex;
  flex: 50%;
  padding-bottom: 5px;

  @include max-w(xs) {
    flex: 100%;
    font-size: 15px;
  }
}

.customIcon {
  margin-right: 5px;
  margin-top: -1px;
  box-shadow: unset;
  transition: unset;
  padding: unset;
  border-radius: unset;

  &:hover {
    box-shadow: unset;
  }

  @include make-responsive(
    'width',
    (
      xs: 14px,
      xxl: 16px,
      xxxl: 21px,
    ),
    21px
  );

  @include make-responsive(
    'height',
    (
      xs: 14px,
      xxl: 16px,
      xxxl: 21px,
    ),
    21px
  );
}

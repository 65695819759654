@import '../../variables';
@import '../../styles/common/mixins';

.dashboard {
  @include container();
}

.listRow {
  @include fluidRow;
  @include min-w(get-breakpoint-value(xxl) + 1) {
    max-height: 35%;
  }
}

.listCell {
  @include fluidCell;
}

@keyframes reload-rotate-no-translate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes back-button-top-leaf-hover {
  0% {
    transform: translateY(-1px) rotate(45deg);
  }
  50% {
    transform: translateY(-1px) rotate(30deg);
  }
  100% {
    transform: translateY(-1px) rotate(45deg);
  }
}

@keyframes back-button-bottom-leaf-hover {
  0% {
    transform: translateY(-1px) rotate(-45deg);
  }
  50% {
    transform: translateY(-1px) rotate(-30deg);
  }
  100% {
    transform: translateY(-1px) rotate(-45deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes searching {
  // 0 -50
  0% {
    transform: translate(5%, -45%);
  }
  25% {
    transform: translate(-5%, -45%);
  }
  50% {
    transform: translate(-5%, -55%);
  }
  100% {
    transform: translate(5%, -55%);
  }
}

@keyframes rollout {
  0% {
    transform: translateY(-100px);
    opacity: 0.2;
  }
  25% {
    transform: translateY(-50px);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-25px);
    opacity: 0.7;
  }
  75% {
    transform: translateY(-10px);
    opacity: 0.9;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes gradientAnimation {
  0% {
    background-position: 0 53%;
  }
  50% {
    background-position: 100% 48%;
  }
  100% {
    background-position: 0 53%;
  }
}
@-moz-keyframes gradientAnimation {
  0% {
    background-position: 0 53%;
  }
  50% {
    background-position: 100% 48%;
  }
  100% {
    background-position: 0 53%;
  }
}
@keyframes gradientAnimation {
  0% {
    background-position: 0 53%;
  }
  50% {
    background-position: 100% 48%;
  }
  100% {
    background-position: 0 53%;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

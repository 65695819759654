@import '../../../../variables';
@import '../../../../styles/common/mixins';

.wrapper {
  position: relative;
  height: 224px;
  max-width: 224px;
  min-width: 224px;

  &.uploaded {
    border: unset;
    background-color: $white;
  }

  @include max-w(xl) {
    min-height: 180px;
    max-height: 180px;
    min-width: 180px;
    max-width: 180px;
  }

  @include max-w(xs) {
    max-height: inherit;
    width: 100%;
    max-width: 100%;
  }

  .imgPreview {
    max-height: 85%;
    max-width: 85%;
  }

  .deleteBtn {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(-50%, -50%);
  }

  .imgPlaceHolder {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: 70px;
      height: 70px;
      margin-bottom: 30px;

      @include max-w(xxl) {
        width: 40px;
        height: 40px;
      }

      @include max-w(xs) {
        max-width: 58px;
        width: 25px;
        height: 25px;
      }
    }
  }

  .uploadMessage {
    @include primary-font();
  }
}

.disabled {
  opacity: 0.5;
}

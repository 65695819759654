@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    padding-top: 30px;
    padding-bottom: 70px;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 474px;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.header {
  position: relative;
  padding: 0 30px;

  @include max-w(xxl) {
    margin-top: 20px;
  }

  @include max-w(xs) {
    padding: 0 25px;
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 30px;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  @include max-w(xxl) {
    top: -10px;
    right: 20px;
  }

  @include max-w(xs) {
    top: -5px;
    right: 15px;
  }

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.logo {
  max-width: 50%;
  max-height: 60px;
  margin-bottom: 20px;

  @include max-w(xs) {
    margin-bottom: 28px;
    max-height: 40px;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 30px;

  @include max-w(map-get($breakpoints, xxl)) {
    gap: 30px;
  }

  @include max-w(xs) {
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  .name {
    @include primary-font(
      (
        xs: 18px,
        xxl: 22px,
        xxxl: 26px,
      )
    );

    flex: 1;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  .controls {
    display: flex;
    gap: 30px;
    color: $gray-light;

    @include max-w(map-get($breakpoints, xxl)) {
      gap: 20px;
    }

    @include max-w(xs) {
      gap: 10px;
    }

    .actionBtn {
      @include primary-font();
      @include hover-underline($gray-light, 2px);

      @include max-w(xs) {
        min-width: 32px;
      }

      &:not(.addBtn) {
        color: $gray-light;
      }

      &.activate {
        @include hover-underline($budget-green, 2px);

        color: $budget-green;
      }

      &.deactivate {
        @include hover-underline($error-red, 2px);

        color: $error-red;
      }
    }
  }
}

.integrations {
  display: flex;
  width: 100%;
  margin-bottom: 40px;

  .item {
    @include primary-font();
  }

  @include min-w(map-get($breakpoints, xxl) + 1) {
    // Should be the same as the first-child of the .row
    // Department name
    & > :first-child {
      flex: 0 1 30%;
    }
  }

  @include max-w(map-get($breakpoints, xxl)) {
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
  }

  @include max-w(xs) {
    margin-bottom: 0;
  }
}

.title {
  color: $gray-light;
}
.value {
  text-transform: capitalize;
  color: $ebony;
  margin-left: 10px;
}

.departments {
  margin-right: 15px;
  flex: 1;
  overflow-y: auto;

  @include max-w(xxl) {
    margin-right: 6px;
  }

  @include max-w(xs) {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .list {
    @include tiny-scrollbar($width: $scrollbar-width, $thumb-color: #a3abbe);

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      border: 1px #d2d8e3 solid;
    }

    padding-right: 5px;

    @include max-w(xxl) {
      padding-right: 14.5px;
    }

    @include max-w(xs) {
      padding: 10px;
      gap: 10px;

      // Remove placeholders
      & > :empty {
        display: none;
      }
    }
  }

  .tableTitle,
  .row {
    display: flex;
    gap: 15px;

    @include min-w(map-get($breakpoints, xxl) + 1) {
      // Department name
      & > :first-child {
        flex: 1 1 40%;
      }

      // Budget
      & > :nth-child(2) {
        flex: 1 1 40%;
      }

      // Limit
      & > :nth-child(3) {
        flex: 1 1 20%;
      }
    }

    @include min-max-w(map-get($breakpoints, xs) + 1, map-get($breakpoints, xxl)) {
      // Department name
      & > :first-child,
      & > :nth-child(3) {
        flex: 1 1 30%;
      }

      // Budget
      & > :nth-child(2) {
        flex: 1 1 35%;
      }
    }

    @include min-w(map-get($breakpoints, xs) + 1) {
      // Limit
      & > :nth-child(3) {
        flex: 1 1 40%;
      }

      // Controls
      & > :last-child {
        flex: 0 1 15%;
        justify-content: space-evenly;
      }
    }

    @include max-w(map-get($breakpoints, xxl)) {
      gap: 10px;
    }
  }

  .tableTitle {
    display: flex;
    align-items: center;

    padding: 5px 0 18px 10px;
    margin-right: 15px;

    @include max-w(xxl) {
      padding: 15px 0 15px 10px;
      margin-right: 14px;
    }

    @include max-w(xs) {
      padding: 10px 25px 10px 25px;
      margin-right: 0;
    }
  }

  .row {
    padding-left: 10px;
    padding-right: 0;

    &:last-of-type {
      border-bottom: 1px solid $gray-blue;
    }

    @include max-w(xxl) {
      padding-left: 10px;
    }

    @include max-w(xs) {
      padding: 25px 15px;
      border: unset;
      border-radius: 6px;
      box-shadow: 1px 1px 10px rgba($black, 0.15);

      // Department name
      & > :first-child {
        font-weight: 700;
      }

      // Budget string
      & > :nth-child(2) > :last-child {
        padding: 0;
      }

      // Limit block
      & > :nth-child(3) {
        flex: 0 1 50%;
        overflow: visible;
      }
    }
  }

  .editableCell {
    border: 1px solid $ebony;
    border-radius: 10px;
    overflow: visible;

    :global(.reactSelect__control) {
      position: relative;
      height: 20px;
      top: -9px;
    }

    :global(.reactSelectContainer) {
      margin-left: 0;
      height: 20px;
      margin-right: 5px;
    }

    @include max-w(xs) {
      border-radius: 6px;
    }
  }

  .moneyInput,
  .moneyInput input {
    box-shadow: none !important;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.container {
  @include tiny-scrollbar($width: 4px, $thumb-color: $gray-table, $track-color: transparent);

  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;
  overflow-y: auto;
  padding: 20px 0;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 474px;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.closeBtnContainer {
  position: relative;
  margin-top: 30px;
  margin-right: 10px;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 15px;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  @include max-w(xxl) {
    top: -20px;
  }

  @include max-w(xs) {
    top: -5px;
    right: 15px;
  }

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

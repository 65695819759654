@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  height: 100%;

  @include max-landscape-h(xs) {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .header {
    justify-content: center;
    margin-bottom: 20px;

    @include max-landscape-h(xs) {
      margin-bottom: 0;
    }
  }

  .title {
    text-align: center;
  }

  .closeModalButton {
    position: absolute;
    top: 40px;
    right: 40px;

    @include max-w(xxl) {
      top: 20px;
      right: 20px;
    }

    @include max-w(xs) {
      top: 15px;
      right: 15px;
    }
  }

  .description {
    text-align: center;
    color: $ebony;

    @include make-responsive(
      'margin-bottom',
      (
        xxl: 10px,
        xxxl: 30px,
      ),
      25px,
      px
    );

    @include primary-font();

    @include max-landscape-h(xs) {
      margin-bottom: 0;
    }
  }

  .modalContent {
    @include tiny-scrollbar;

    overflow-y: auto;
    margin-bottom: 0.6em;
    flex: 1;

    @include max-landscape-h(xs) {
      padding: 15px 0 10px;
    }

    @include max-w(xs) {
      padding: 30px 10px;
      width: 100%;
    }

    .lowItem {
      margin-bottom: 10px;

      .lowItemImage {
        width: 120px;

        @include max-w(xs) {
          width: 80px;
        }

        @include max-landscape-h(xs) {
          width: 80px;
        }
      }
    }
  }

  .modalFooter {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .checkBoxContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .modalSnoozeHintIcon {
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

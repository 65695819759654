@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  width: 100%;
  display: flex;
  padding: 10px 0;
  border: 1px solid $gray-blue;
  border-radius: 10px;
  align-self: stretch;
}

.graphs {
  position: relative;
  width: 100%;
}

.hoverArea {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: 262px;
  min-width: 262px;
  border-left: 1px solid $gray-blue;

  @include max-w(xxl) {
    max-width: 214px;
    min-width: 214px;
  }
}

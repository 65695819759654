@mixin reset-button {
  background: none;
  border: 0 none;
  outline: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@mixin button-shadow($color: rgba(33, 33, 33, 0.23)) {
  box-shadow: 0 0 0 transparent;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, $color 0px 6px 6px;
  }

  &:disabled {
    &:hover {
      box-shadow: none;
    }
  }
}

@mixin back-btn {
  display: block;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    background: $black;
    position: absolute;
    width: 100%;
    height: 2px;
    transform-origin: 1px center;
  }

  &:before {
    transform: translateY(-1px) rotate(45deg);
  }
  &:after {
    transform: translateY(-1px) rotate(-45deg);
  }

  &:hover {
    &:before {
      animation: back-button-top-leaf-hover 0.4s ease-in-out 0s 3 normal;
    }
    &:after {
      animation: back-button-bottom-leaf-hover 0.4s ease-in-out 0s 3 normal;
    }
  }
}

@mixin close-btn {
  position: relative;
  min-width: 21px;
  min-height: 21px;
  transform: rotate(-45deg) scale(1);
  transition: transform 0.4s ease-in-out;

  &:hover {
    transform: rotate(135deg) scale(1.2);
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: $gray-blue;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: 0;
  }

  &::before {
    transform: rotate(90deg) translate(-50%, -50%);
  }

  &::after {
    transform: translate(-50%, -50%);
  }
}

@mixin hover-underline($color: $black, $height: 1px) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    transform: scaleX(0);
    height: $height;
    width: 100%;
    background: $color;
    transition: transform 0.25s ease;
  }

  &:hover {
    &::after {
      transform: scaleX(1);
    }
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

@mixin smoothlyAnimation {
  transition: 0.25s ease;
}

.control {
  @include primary-font();

  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: $ebony;
  position: relative;

  &:hover {
    background-color: $bg-color;
  }

  svg {
    margin-right: 15px;
  }
}

.backBtn {
  @include secondary-font();

  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }

  & button {
    height: 12px;
    width: 12px;
  }
}

.loader {
  background-color: $bg-color;
  z-index: 0;
}

.row {
  position: relative;
  z-index: 1;
}

.active {
  font-weight: 600;
}

@import '../../variables';
@import '../../styles/common/mixins';

$padding: 0.5em;
$min-width-loader-btn: calc(125px + 2 * #{$padding});
$min-width-loader-btn-xxl: calc(140px + 2 * #{$padding});

.inventory {
  @include container;

  flex-flow: column;
  max-height: 100%;

  @include max-w(xs) {
    padding: 10px 0 10px 0;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 20px 55px 20px 140px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: $gray-suit;
  padding: 0 10px;

  @include max-w(xs) {
    flex-direction: column-reverse;
    align-items: flex-start;
    box-shadow: $element-shadow;
    position: relative;
    z-index: 1;
  }

  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xxl: 21px,
    ),
    21px
  );
}

.filters {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex-grow: 1;
  gap: 20px;

  .commonFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 10px;

    @include max-w(xs) {
      gap: 15px;
    }
  }

  @include max-w(xs) {
    flex-wrap: wrap;
    margin-right: 0;
  }

  .typeFilter {
    padding-right: 20px;

    @include max-w(xs) {
      padding-right: 0;
    }
  }
}

.controls {
  flex: 1;
  display: flex;
  align-self: flex-start;

  .addButton,
  .downloadBtn {
    padding: 0.8em 0.5em;
    white-space: nowrap;
  }

  .loaderBtn {
    min-width: $min-width-loader-btn;

    & .spinner {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  & > :not(:last-of-type) {
    @include make-responsive(
      'margin-right',
      (
        xxl: 10px,
        xxxl: 20px,
      ),
      20px
    );
  }

  @include min-max-w(get-breakpoint-value(xs) + 1, lg) {
    // justify-content has been removed on the lg for the better view of the flex-wrap
    justify-content: initial;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    .loaderBtn {
      min-width: $min-width-loader-btn-xxl;
    }
  }

  @include max-w(xs) {
    justify-content: flex-end;
  }
}

.content {
  @include tiny-scrollbar($width: 0.5rem);

  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  flex: 1;
}

.list {
  @include tiny-scrollbar($width: 0.5rem);
  @include min-w(get-breakpoint-value(xs) + 1) {
    @include fluidRow;

    margin-right: 0;
    overflow-y: auto;
    align-items: flex-start;
  }

  @include max-w(xs) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.item {
  @include min-w(get-breakpoint-value(xs) + 1) {
    @include fluidCell;
  }

  @include min-w(get-breakpoint-value(md) + 1) {
    @include fluidCell($perRow: 3);
  }

  @include min-w(get-breakpoint-value(lg) + 1) {
    @include fluidCell($perRow: 4);
  }

  @include min-w(get-breakpoint-value(xl) + 1) {
    @include fluidCell($perRow: 5);
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    @include fluidCell($perRow: 6);
  }

  @include min-w(get-breakpoint-value(xxxl) + 1) {
    @include fluidCell($perRow: 9);
  }

  @include max-w(xs) {
    width: 100%;
  }

  .wide {
    @include max-w(xs) {
      flex-direction: row;
      align-items: center;
    }
  }

  .icon {
    @include max-w(xs) {
      width: $mobile-item-icon-width;
      min-width: $mobile-item-icon-width;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }

  .itemInfo {
    margin-top: 0.3em;
  }
}

.pagination {
  margin-top: 10px;

  margin-right: calc(var(--grid-item-margin) / 2);
}

.loader {
  z-index: 5;
  background-color: $bg-color;
}

.placeholder {
  height: 100%;
  margin: 0 auto;
}

.dropdownFilter {
  position: relative;

  .dropdownContent {
    width: 335px;
    transform: translateX(-10%);
    z-index: 2;

    @include max-w(md) {
      top: 140%;
      width: 280px;
    }

    @include max-w(xs) {
      transform: unset;
    }
  }
}

.dropdownLabel {
  @include filterLabel();
}

.wrapper {
  position: relative;

  .top {
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include max-w(xs) {
      right: unset;
      width: calc(#{$mobile-item-icon-width} - 2px);
      min-width: calc(#{$mobile-item-icon-width} - 2px);
    }

    .tooltip {
      position: absolute;
      right: 10px;
      top: 10px;
      height: auto;

      @include max-w(xxl) {
        right: 5px;
        top: 5px;
      }
    }
  }
}

.divider {
  padding-right: 20px;
  border-right: 1px solid $gray-suit;

  margin-right: 0;
}

.preorderFilter {
  @include max-w(xs) {
    padding-left: 0;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.fcDetails {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  align-items: flex-end;
  max-width: 150px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include min-max-w(get-breakpoint-value(lg) + 1, xl) {
    max-width: 100px;
  }

  &.highlighted .fcLabel {
    color: $error-red;
  }

  span {
    @include micro-font();

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $gray-suit;
    max-width: 100%;
  }
}

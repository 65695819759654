@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
}

.tinyEditor {
  height: 300px;

  @include max-w(xs) {
    height: 395px;
  }
}

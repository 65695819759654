@import '../../variables';
@import '../../styles/common/mixins';

$min-height-breakpoint: 750px;

.loginContainer {
  overflow-y: visible;
  display: flex;
  align-items: center;
  width: 100%;

  @include max-w(xxl) {
    flex-direction: column;
  }
}

.inner {
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column;
  justify-content: center;

  @include max-w(xxl) {
    width: 100%;
    order: 2;
  }

  @include min-w(get-breakpoint-value(md) + 1) {
    @include max-h($min-height-breakpoint) {
      height: unset;
      padding: 5vh 0 2.5vh;
    }
  }
}

.title {
  @include make-responsive(
    'font-size',
    (
      xs: 30px,
      xl: 48px,
    ),
    48px,
    px
  );

  font-weight: 500;
  line-height: 1.15;
  text-align: center;
  margin-bottom: 40px;
  max-width: 600px;
  align-self: center;

  @include max-h($min-height-breakpoint) {
    margin-bottom: 20px;
  }

  @include max-w(xs) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.message {
  @include primary-font();

  align-self: center;
  text-align: center;
  margin: 0 20px 40px;
  max-width: 600px;
}

.form {
  width: 405px;
  margin: 0 auto;

  @include max-w(xs) {
    width: 100%;
    padding: 10px;
  }
}

.passwordContainer {
  position: relative;

  &.linkMode {
    margin-bottom: 50px;
  }
}

.forgetPassword {
  @include primary-font();

  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  display: flex;
  user-select: none;
  cursor: pointer;
  color: $deep-purple;
  z-index: 1;

  &:hover {
    color: lighten($deep-purple, 30%);
  }

  @include max-w(xs) {
    font-size: 12px;
  }
}

.mailInput {
  margin-bottom: 30px;

  @include max-h($min-height-breakpoint) {
    margin-bottom: 15px;
  }
}

.passwordInput {
  margin-bottom: 10px;
}

.rememberMe {
  margin-top: 25px;

  @include max-h($min-height-breakpoint) {
    margin-top: 10px;
  }
}

.submitBtn {
  margin: 25px auto 0;
  max-width: 400px;
}

.providers {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 40px auto;
  width: 400px;

  @include max-w(xs) {
    margin: 25px auto;
    max-width: 400px;
    width: 100%;
  }

  @include max-h($min-height-breakpoint) {
    margin: 25px auto;
  }
}

.providerBtn {
  @include primary-font();

  background: none;
  border-radius: 10px;
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 65px;
  font-weight: 500;
  border: 1px solid $ebony;
  color: $black;

  @include max-h($min-height-breakpoint) {
    max-height: 45px;
  }

  @include max-w(xs) {
    font-size: 16px;
    padding: 14px 0;
  }

  svg {
    margin-right: 18px;
    max-width: 25px;

    &.pace {
      max-width: 40px;
      margin-left: -35px;
    }
  }
}

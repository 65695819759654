@import '../../../variables';
@import '../../../styles/common/mixins';

.addEmailForm {
  position: relative;
  display: flex;
  flex-direction: column;
}

.editorContainer {
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );

  .tinyEmailEditor {
    @include make-responsive(
      'height',
      (
        xxl: 280px,
        xxxl: 430px,
      ),
      430px,
      px
    );

    @include max-w(xs) {
      height: 395px;
    }
  }

  .tinyEmailLabel {
    display: flex;

    .tinyEmailTooltip {
      margin-left: 10px;
    }

    .tinyLabelStrongFont {
      font-weight: 500;
    }
  }
}

.button {
  max-width: 300px;

  @include max-w(xs) {
    max-width: unset !important;
  }
}

.input {
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px,
    px
  );
}

.buttonInner {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include max-w(xxl) {
    flex-direction: column;
    align-items: flex-end;
  }

  @include max-w(xs) {
    align-items: flex-start;
  }
}

.checkbox {
  margin-right: 55px;
  margin-bottom: 5px;

  @include max-w(xxl) {
    margin-right: 0;
  }

  @include max-w(xs) {
    margin-bottom: 10px;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

.filter {
  display: flex;
}

.filterLabel {
  @include filterLabel;
}

.chosen {
  color: $ebony;
  align-self: center;
  cursor: default;
}

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  &:not(:last-child) {
    @include make-responsive(
      'margin-right',
      (
        xs: 20px,
        xxl: 30px,
        xxxl: 33px,
      ),
      33px
    );

    @include max-w(xs) {
      margin-right: 10px;
    }
  }

  & svg {
    margin-right: 5px;
    position: absolute;
    left: -25px;
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.sectionTitle {
  font-weight: 700;

  @include make-responsive(
    'font-size',
    (
      xs: 16px,
      xl: 24px,
      xxxl: 26px,
    ),
    30px
  );
}

.secondaryTitle {
  margin-top: 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 0;

  gap: 20px;

  flex: 1;

  @include max-w(xs) {
    flex-direction: column;
  }
}

.inputField {
  border: 1px solid $gray-suit;
  border-radius: 10px;
}

.toggleSlider {
  border: 1px solid $gray-suit;
}

.shippingOptionsRow {
  display: unset;
  justify-content: unset;
}

.optionsList {
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  .deliveryOptionsGroup {
    @include max-w(xs) {
      margin: 5px;
    }
  }

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
}

.noteTextArea {
  padding: 30px;
  min-height: 15vh;
}

.controlsContainer {
  display: flex;
  align-items: center;

  .cancelButton {
    background-color: transparent;
    border: 1px solid RGB($default-accent-color);
    color: RGB($default-accent-color);
    max-width: 130px;

    @include max-w(xs) {
      max-width: 100%;
    }
  }

  .continueButton {
    @include max-w(xs) {
      max-width: 100%;
    }
  }
}

.togglesGrid {
  flex: 1;
  margin: 1em 0;
  flex-flow: column;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;

  @include make-responsive(
    'row-gap',
    (
      xs: 20px,
      xxl: 30px,
      xxxl: 40px,
    ),
    40px,
    px
  );

  @include make-responsive(
    'margin-top',
    (
      xs: 10px,
      xxl: 20px,
      xxxl: 40px,
    ),
    40px,
    px
  );

  @include make-responsive(
    'margin-bottom',
    (
      xs: 10px,
      xxl: 20px,
      xxxl: 40px,
    ),
    40px,
    px
  );
}

.toggleDescription {
  @include secondary-font();
}

.fullWidth {
  width: 100%;
}

@import '../../../variables';
@import '../../../styles/common/mixins';

$table-columns-xxxl: auto 250px 40px;
$table-columns-xxl: auto 150px 40px;
$table-columns-xs: auto 65px 40px;

.container {
  display: flex;
  flex-flow: column;

  @include max-w(xl) {
    margin-bottom: 30px;
  }
}

.grid {
  display: grid;
  column-gap: 5px;
  row-gap: 10px;
  grid-template-columns: $table-columns-xxxl;

  @include max-w(xxl) {
    grid-template-columns: $table-columns-xxl;
  }

  @include max-w(xs) {
    grid-template-columns: $table-columns-xs;
  }
}

.header {
  @include make-responsive(
    'padding-right',
    (
      xs: 15px,
      xxl: 20px,
      xxxl: 30px,
    ),
    15
  );
}

.column {
  position: relative;
}

.body {
  @include make-responsive(
    'padding',
    (
      xs: 15px,
      xxl: 20px,
      xxxl: 30px,
    ),
    15
  );

  background: $white;
  border-radius: 10px;
}

.row {
  display: contents;
  position: relative;
}

.cell {
  @include primary-font();

  overflow: hidden;
  padding: 8px 0;
  display: flex;
  align-items: center;

  @include max-w(xxl) {
    padding: 5px 0;
  }

  @include max-w(xs) {
    padding: 3px 0;
  }
}

.name {
  @include cropped-text();
  white-space: nowrap;
}

.summaryButton {
  @include primary-font();
  @include hover-underline($ebony, 2px);
}

@import '../../variables';
@import '../../styles/common/mixins';

.editableCell {
  border: 1px solid $deep-sky-blue;
  border-radius: 3px;

  @include max-w(xs) {
    border-radius: 6px;
  }

  input {
    border: none;
    outline: none;
    @include primary-font(
      (
        xs: 12px,
        xl: 16px,
      ),
      16px
    );

    &:disabled {
      color: rgba(43, 47, 56, 0.2);

      &::placeholder {
        opacity: 0.2;
      }
    }
  }

  .countrySelect {
    &:disabled {
      & + div > svg {
        opacity: 0.2;
      }
    }
  }
}

.error {
  border-color: $error-red;
  display: flex;
}

.controls {
  gap: 5px;

  button {
    width: 23px;
    height: 23px;
  }

  .delete {
    order: 1;
    transition: opacity 0.25s ease;

    @include min-w(get-breakpoint-value('xs') + 1) {
      opacity: 0;
    }
  }

  .edit {
    order: 2;
  }

  .save + .cancel,
  .save + .delete {
    margin-left: 0;
  }
}

.deletePlaceholder {
  position: relative;
  background-color: $light-pink;
  display: flex;
  align-items: baseline;
  justify-content: center;
  text-transform: initial;
  padding: 21px 18px;
  gap: 10px;
  border-bottom: 1px solid $gray-blue;

  & button {
    @include error-border;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 7px;
    border-radius: 3px;
    min-width: 90px;
    width: auto;
    box-sizing: border-box;
  }

  & > * {
    @include primary-font(
      (
        xs: 12px,
        xl: 16px,
      ),
      16px
    );
  }

  @include max-w(xs) {
    border-radius: 6px;
    box-shadow: 1px 1px 10px rgba($black, 0.15);
    padding: 25px 15px;
  }
}

.alertIcon {
  position: relative;
  top: 3px;
  width: 20px;
  height: auto;
}

.deleteButton {
  background-color: $error-red;
  color: $white;
}

.cancelButton {
  color: $error-red;
}

.deleteText {
  font-weight: 500;
}

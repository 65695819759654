@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  border-top: 0.5px solid $gray-blue;

  @include max-w(xs) {
    padding-bottom: 70px;
  }
}

.price {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding: 30px 0 10px 0;

  color: $gray-suit;
  margin-top: 5px;

  .value {
    color: $ebony;
    font-weight: 500;

    @include primary-font(
      (
        xs: 24px,
        xxl: 30px,
      )
    );
  }

  @include secondary-font(
    (
      xs: 14px,
      xxl: 21px,
    )
  );

  @include max-w(xs) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
  }
}

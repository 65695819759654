@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  @include make-responsive(
    'max-height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 10px;
  background-color: white;

  &.rounded {
    flex-direction: column;
    max-height: unset;
    border-radius: unset;
    background-color: transparent;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @include max-w(xs) {
    margin-top: 5px;
    border-radius: 6px;
  }
}

.option {
  padding: 7px;

  span {
    @include primary-font();

    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    border-radius: 10px;
    text-align: center;

    &.rounded {
      padding: 4px 0;
    }

    @include max-w(xs) {
      border-radius: 6px;
    }
  }

  .input {
    position: absolute;
    visibility: hidden;
    z-index: -10;

    &:checked + span {
      background: rgbCl($global-accent-color);
      color: $white;
    }

    &:checked:disabled + span {
      background: $gray-suit;
    }
  }

  &.rounded {
    padding: 10px;

    .input {
      position: unset;
      visibility: unset;
      z-index: unset;
      display: grid;
      place-content: center;
      appearance: none;
      margin: 0 10px 0 0;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid transparent;
      background-color: $gray-blue;
      border-radius: 50%;
      cursor: pointer;

      &:checked + span {
        background: unset;
        color: $ebony;
      }

      &:before {
        content: '';
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: $white;
      }

      &:checked:before {
        transform: scale(1);
        background-color: $white;
        border: 0.3em solid RGB($default-accent-color);
      }

      &:hover {
        opacity: 0.75;
      }

      &:checked {
        cursor: default;

        &:hover {
          opacity: 1;
        }
      }
    }

    span {
      padding: 0;
    }
  }
}

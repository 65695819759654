@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  @include secondary-font();

  font-weight: 600;
}

.label {
  font-weight: normal;
  color: $gray-suit;
  margin-right: 0.25em;
}

.source {
  font-weight: normal;
  display: inline-flex;
  color: $gray-suit;
  margin-left: 0.25em;

  > svg {
    margin-left: 0.25em;
    margin-top: -2px;
  }
}

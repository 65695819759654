@import '../../variables';
@import '../../styles/common/mixins';

@mixin smoothlyAnimation {
  transition: 0.25s ease;
}

.sideNav {
  display: flex;
  z-index: 110;

  &.nonAuth {
    flex-basis: 33%;
    order: 1;
    margin-right: 0;
  }

  @include min-max-w(get-breakpoint-value(xs) + 1, xxl) {
    margin-right: $nav-desktop-min-widthXXL;
  }

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-height: 100%;
    z-index: 90;
  }

  .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: rgbCl($global-sidebar-text-color);
    cursor: pointer;
    justify-content: center;
    border-radius: 10px;
    height: 38px;
    margin: 6px 14px;

    & svg {
      & path {
        fill: rgbCl($global-sidebar-text-color);
      }
    }

    .icon {
      @include smoothlyAnimation();

      transform-origin: center;
    }

    &:hover:not(.activated) {
      background-color: rgbCl($global-sidebar-text-color, 0.1);

      & .icon {
        transform: scale(1.1);
      }
    }

    &.wide {
      justify-content: flex-start;
    }

    &.expanded {
      .expandIcon {
        transform: rotate(-180deg);
      }
    }

    .expandIcon {
      fill: rgbCl($global-sidebar-text-color);
      margin-left: 10px;
      transition: 0.3s ease;
    }
  }

  .activated {
    background-color: rgbCl($global-accent-color, 0.1);

    div,
    span {
      @include smoothlyAnimation();

      color: rgbCl($global-accent-color);
      font-weight: 600;
    }
    svg path {
      @include smoothlyAnimation();

      fill: rgbCl($global-accent-color);
    }
  }
}

.subItems {
  padding-left: 50px;

  .menuItem {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 45%;
      height: 6px;
      width: 6px;
      background-color: rgbCl($global-sidebar-text-color);
      border-radius: 50%;
    }

    margin: 0 10px;
    padding: 7px 15px;
  }

  > div {
    padding: 5px 0;
  }
}

.iconWrapper {
  width: 100%;
  text-align: end;
  margin-right: 10px;
}

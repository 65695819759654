@import '../../../../variables';
@import '../../../../styles/common/mixins';

.editorContainer {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  :global {
    .tox-tinymce {
      border: none;
      border-radius: 10px;
      flex: 1;
      transition: box-shadow 0.25s ease;
      box-shadow: $transparent-shadow;

      &:hover[aria-disabled='false'] {
        box-shadow: $input-shadow;
      }

      .tox-statusbar {
        border-top: none;
      }
    }
  }

  &.errorBorder {
    :global {
      .tox-tinymce {
        border: 1px solid $error-red;
      }
    }
  }
}

.editor {
  position: relative;
}

.hint {
  @include label();

  margin-top: 0.75em;
  margin-bottom: 0;
}

.error {
  @include secondary-font;

  margin-top: 0.75em;
  color: $error-red;
}

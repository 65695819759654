@import '../../variables';
@import '../../styles/common/mixins';

$scrollbar-width: 4px;

.container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-flow: column;
  background: $white;

  @include min-w(get-breakpoint-value(xs) + 1) {
    max-width: 100%;
    bottom: 0;
    padding: 30px 0;
    width: auto;
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    height: 100vh !important;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    width: 474px;
  }

  @include max-w(xs) {
    // This `!important` is important to overwrite useSpring inline value
    // if screen size is changed after rotating the phone
    width: 100vw !important;
  }
}

.overlay {
  z-index: 95;
  background: $modal-overlay;
}

.header {
  position: relative;
  padding: 0 30px;

  @include max-w(xxl) {
    margin-top: 20px;
  }

  @include max-w(xs) {
    padding: 0 25px;
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 30px;
  width: 15px;
  height: 15px;
  transition: transform 0.25s ease;

  @include max-w(xxl) {
    top: -10px;
    right: 20px;
  }

  @include max-w(xs) {
    top: -5px;
    right: 15px;
  }

  &:hover {
    transform: scale(1.15);
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    left: 50%;
    transform-origin: center;
    background: $gray-blue;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 20px;

  @include max-w(xs) {
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }

  .title {
    @include primary-font(
      (
        xs: 22px,
        xl: 32px,
      ),
      32px
    );

    flex: 1;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }
}

.upload {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include max-w(xs) {
    gap: 5px;
  }

  & .label {
    width: fit-content;
    color: $black;
    font-weight: 600;
  }
}

.uploadFile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  @include max-w(xs) {
    width: 100%;
  }

  .chooseBtn {
    width: fit-content;
    padding: 20px 24px;

    @include max-w(xs) {
      width: 100%;
      margin: 0 auto;
      max-height: 48px;
      max-width: 100%;
    }
  }
}

.allowedRecipients {
  margin: 0 30px;
  flex: 1;
  overflow-y: auto;

  @include max-w(xs) {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .list {
    @include tiny-scrollbar($width: $scrollbar-width, $thumb-color: $gray-ebony);
    border: 1px solid $shady;
    border-radius: 0 0 10px 10px;
    border-top: none;

    // Remove placeholders
    & > :empty {
      display: none;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
      border: 1px $shady solid;
    }

    @include max-w(xs) {
      padding: 10px;
      margin-right: 30px;
      gap: 10px;
      border: none;
    }
  }

  .tableTitle,
  .row {
    display: flex;
    gap: 15px;
    text-transform: initial;

    & > * {
      padding: 6px;
      @include primary-font(
        (
          xs: 12px,
          xl: 16px,
        ),
        16px
      );
    }

    // First name
    & > :first-child {
      flex: 1 1 14%;
    }

    // Last name
    & > :nth-child(2) {
      flex: 1 1 14%;
    }

    // Email
    & > :nth-child(3) {
      flex: 1 1 25%;
    }

    // Phone
    & > :nth-child(4) {
      flex: 1 1 25%;
    }

    & > :last-child {
      flex: 0 1 12%;
      justify-content: right;
    }

    @include max-w(xs) {
      padding: 25px 15px;
      border: unset;
      border-radius: 6px;
      box-shadow: 1px 1px 10px rgba($black, 0.15);

      & > :first-child,
      & > :nth-child(2) {
        flex: 0 1 48%;
      }
      & > :nth-child(3),
      & > :nth-child(4) {
        flex: 0 1 100%;
      }
      & > :last-child {
        flex: 0 1 100%;
        order: 3;
        display: flex;
        gap: 6px;

        button {
          width: 35px;
          height: 35px;
        }
      }
    }

    @include max-w(map-get($breakpoints, xxl)) {
      gap: 10px;
    }
  }

  .tableTitle {
    display: flex;
    align-items: center;
    border-radius: 10px 10px 0 0;
    border: 1px solid $shady;
    padding: 1em;
    background-color: $gray-lighter;
    color: $black;
    font-weight: 500;
    max-height: 60px;
  }

  .row {
    padding: 1em;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }

    @include max-w(xs) {
      border: unset;
      border-radius: 6px;
      box-shadow: 1px 1px 10px rgba($black, 0.15);
    }
  }
}

.search {
  max-width: 100%;
  border: 1px solid $shady;
  border-radius: 10px;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin: 10px 30px 0;
  flex-wrap: wrap;

  @include max-w(xs) {
    margin-bottom: 20px;
  }

  .deleteAll {
    color: $error-red;
    font-weight: 600;

    &::after {
      background: $error-red;
    }
    &:disabled {
      color: $gray-suit;

      &::after {
        display: none;
      }
    }
  }
}

.paginationContainer {
  @include primary-font(
    (
      xs: 12px,
      xl: 16px,
    ),
    16px
  );
  display: flex;
  gap: 20px;
  align-items: center;
}

@import '../../variables';
@import '../../styles/common/mixins';

.header {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 0;
  justify-content: space-between;
  max-width: 1300px;

  @include max-w(xl) {
    padding-bottom: 0;
  }

  @include max-w(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include max-w(xs) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.addButton {
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 5px;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-left: 0;
    max-width: 100%;
  }

  @include min-max-w(get-breakpoint-value(md) + 1, xl) {
    margin-right: 15px;
  }
}

.statusBullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.secretInput input {
  padding: 0 50px 0 5px;
  font-size: 14px;
}

.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
  cursor: not-allowed;
}

.tableContainer {
  max-width: 1300px;
  margin-bottom: 20px;
}

@import 'src/variables';
@import 'src/styles/common/mixins';

.itemContainer {
  position: relative;

  .deleteButton {
    width: 15px;
    height: 15px;
    position: absolute;

    top: -6px;
    right: -6px;
  }
}

.itemImageContainer {
  position: relative;
  border: 0.5px solid $gray-blue;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 14px;
  width: 80px;
  height: 80px;

  .quantity {
    left: -1px;
    right: unset;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (stroke-color: transparent) {
        right: unset;
        top: -0.5px;
      }
    }
  }

  .iconInner {
    flex-shrink: 0;

    width: 52px;
    height: 52px;

    .icon {
      width: 100%;
      height: 100%;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  @include max-w(xs) {
    width: 76px;
    height: 76px;
    padding: 12px;
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  position: relative;
}

.tooltip {
  padding: 0 !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  box-shadow: 0 4px 20px 0 #aeb1bc !important;
}

.colorInput {
  user-select: none;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 30px 20px 20px;
  border-radius: 10px;
}

.buttonWrapper {
  position: relative;
}

.clearButton {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: -10px;
  top: -10px;
  z-index: 1;
}

.readOnly {
  pointer-events: none;
  cursor: default;
}

.button {
  @include primary-font();
  @include make-responsive(
    'max-height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px,
    px
  );

  @include make-responsive(
    'height',
    (
      xxl: 50px,
      xxxl: 64px,
    ),
    64px
  );

  text-transform: uppercase;
  width: 144px;
  border-radius: 10px;
  color: $ebony;
  background-color: $white;

  @include max-w(xs) {
    width: 100%;
    height: 56px;
  }

  .emptyText {
    @include secondary-font();

    color: $gray-light;
    text-transform: capitalize;
  }

  .colorBar {
    @include make-responsive(
      'width',
      (
        xs: 15px,
        xxl: 20px,
        xxxl: 24px,
      ),
      24px,
      px
    );

    @include make-responsive(
      'height',
      (
        xs: 15px,
        xxl: 20px,
        xxxl: 24px,
      ),
      24px,
      px
    );

    margin-right: 10px;
    border: 1px solid rgba($black, 0.2);
    border-radius: 3px;
    flex-shrink: 0;
  }
}

.value {
  margin-top: 0.2em;
}

.label {
  @include label();

  &.dash {
    line-height: 1;
    margin: 3px 5px 0 0;
  }
}

.main {
  position: relative;
  display: flex;
  height: 196px;
  margin-bottom: 10px;

  .saturation {
    position: relative;
    width: 196px;
    height: 100%;
    margin-right: 10px;
  }

  .hue {
    position: relative;
    width: 15px;
    border-radius: 10px;
    overflow: hidden;
  }
}

.current {
  height: 36px;
  width: 36px;
  border-radius: 3px;
  margin-left: 5px;
}

.input {
  display: flex;
  align-items: center;
}

.pointer {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0);
  border: 2px solid $white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  &.pointerSaturation {
    transform: translate(-7px, -7px);
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  height: 100%;

  @include max-landscape-h(xs) {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .header {
    justify-content: center;
    margin-bottom: 20px;

    @include max-landscape-h(xs) {
      margin-bottom: 0;
    }
  }

  .title {
    text-align: center;
    color: $midnight-blue;
  }

  .closeModalButton {
    position: absolute;
    top: 40px;
    right: 40px;

    @include max-w(xxl) {
      top: 20px;
      right: 20px;
    }

    @include max-w(xs) {
      top: 15px;
      right: 15px;
    }
  }

  .description {
    text-align: center;
    color: $midnight-blue;

    @include make-responsive(
      'margin-bottom',
      (
        xxl: 10px,
        xxxl: 30px,
      ),
      25px,
      px
    );

    @include make-responsive(
      'font-size',
      (
        xs: 18px,
        xxl: 20px,
        xxxl: 24px,
      ),
      24px,
      px
    );

    @include max-landscape-h(xs) {
      margin-bottom: 0;
    }
  }

  .modalContent {
    display: flex;
    margin: 2em;
    justify-content: center;

    .picker {
      position: absolute;
      top: 10px;
    }

    @include max-landscape-h(xs) {
      padding: 15px 0 10px;
    }

    @include max-w(xs) {
      padding: 30px 10px;
      width: 100%;
      margin: 0;
    }
  }

  .modalFooter {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
}

.loaderContainer {
  border-radius: 20px;
  background: $gray-lighter;
}

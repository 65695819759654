@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  display: flex;
  position: relative;
  margin-top: 20px;

  @include max-w(md) {
    flex-direction: column;
    height: auto;
  }
}

.availableItemsContent {
  max-height: 50vh;
}

.addedItemsWrapper {
  display: flex;
  flex-flow: column;
  max-height: 100%;
  max-width: 550px;
  width: 100%;

  &.wide {
    flex: 1;
  }

  &.empty {
    height: 100%;

    .content {
      margin: auto;
    }
  }

  .content {
    padding: 10px 10px 20px 0;

    .addedItem {
      display: flex;
      align-items: center;

      @include max-w(xxl) {
        flex-direction: column;
        align-items: start;
      }

      @include max-w(lg) {
        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }

      .inventoryItem {
        flex: 1;

        @include max-w(xl) {
          margin-bottom: 0;
          flex-direction: row;
        }

        @include max-w(xs) {
          width: 100%;
          margin-bottom: 0;
        }

        .iconInner {
          margin-bottom: 0;
        }
      }

      & > div:not(:last-of-type) {
        @include make-responsive(
          'margin-right',
          (
            xl: 33px,
            xxxl: 60px,
          ),
          60px
        );

        @include max-w(xl) {
          margin: 0 0 15px;
        }
      }
    }

    @include max-w(xl) {
      max-height: 50vh;
      overflow: scroll;
      @include tiny-scrollbar;
    }
  }
}

.inventoryInfoContainer {
  display: flex;
  justify-content: center;
}

.addItemsBtn {
  align-self: flex-end;
  position: relative;
  width: 100%;
  max-width: 298px;
  flex-shrink: 0;
  margin-left: auto;

  @include max-w(xxl) {
    max-width: 231px;
  }

  @include max-w(xs) {
    margin-right: auto;
    max-width: 100%;
  }
}

$squaredElementAnimationValue: 3px;

// Default layout mixins
:root {
  --grid-items-per-row: 5;
  --grid-item-margin: 3.5vw;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    --grid-items-per-row: 6;
    --grid-item-margin: 40px;
  }

  @include max-w(xxl) {
    --grid-items-per-row: 4;
  }
  @include max-w(md) {
    --grid-items-per-row: 3;
  }
  @include max-w(xs) {
    --grid-items-per-row: 2;
    --grid-item-margin: 10px;
  }
}

@mixin container() {
  width: 100%;
  position: relative;
  padding: 30px 70px;
  display: flex;

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 30px 30px 20px 130px;
  }

  @include max-w(xxl) {
    padding: 20px 30px;
  }

  @include max-w(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include max-w(xs) {
    padding: 15px 10px;
  }
}

@mixin list() {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin: 0 auto;

  @include max-w(md) {
    padding: 0 15px;
  }

  @include max-w(xs) {
    padding: 0 10px;
    max-width: 350px;
  }
}

@mixin fluidRow($margin: var(--grid-item-margin), $fixAnimation: true) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 calc(#{$margin} / -2);
  flex: 1;
}

@mixin fluidCell($perRow: var(--grid-items-per-row), $margin: var(--grid-item-margin)) {
  margin-left: calc(#{$margin} / 2);
  margin-right: calc(#{$margin} / 2);
  margin-bottom: 20px;
  width: calc(100% / #{$perRow} - #{$margin} - 0.01px);
}

// Squared element mixins

@mixin squared-element($icon-max-size: false) {
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:hover:not(.disabled) .iconInner {
    transform: translate(0, -#{$squaredElementAnimationValue});
  }

  .iconInner {
    @if ($icon-max-size) {
      @include squared-element-icon($max-size: $icon-max-size);
    } @else {
      @include squared-element-icon();
    }
  }

  .title {
    @include squared-element-title();
  }

  .description {
    @include squared-element-desc();
  }
}

@mixin squared-element-icon($icon-w: 100%, $icon-h: 100%, $max-size: $squared-element-max-size) {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  background: $white;
  border-radius: 20px;
  margin-bottom: 0.75em;
  transition: 0.2s ease;
  max-width: $max-size;
  max-height: $max-size;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .icon {
    position: absolute;
    width: $icon-w;
    height: $icon-h;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@mixin squared-element-title() {
  @include make-fluid('font-size', 18px, 24px, get-breakpoint-value(xl), get-breakpoint-value(xxxl), 24px, px);

  width: 100%;
  font-weight: 500;
  line-height: 1.15;
  overflow: hidden;
  text-overflow: ellipsis;

  @include max-w(xs) {
    font-size: 24px;
  }
}

@mixin squared-element-desc() {
  @include make-fluid('font-size', 14px, 18px, get-breakpoint-value(xl), get-breakpoint-value(xxxl), 18px, px);

  line-height: 1.15;
  text-align: left;
  overflow: hidden;

  @include max-w(xs) {
    font-size: 16px;
  }
}

@mixin cropped-text($lines: false) {
  overflow: hidden;
  text-overflow: ellipsis;

  @if ($lines) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}

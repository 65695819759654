@import 'src/variables';
@import 'src/styles/common/mixins';

$added-items-scrollbar-height: 4px;

.container {
  padding-right: 20px;
}

.addedItemsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  height: 100px;
  flex-wrap: nowrap;
  overflow-x: scroll;

  @include tiny-horizontal-scrollbar($height: $added-items-scrollbar-height, $thumb-color: $gray-ebony);
}

.searchFormContainer {
  padding: 10px 0;

  .searchForm {
    max-width: unset;

    input {
      border: 1px solid $ebony;
      border-radius: 10px;
    }
  }
}

.paginatedListContainer {
  padding: 0;

  .itemsList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 10px;
    @include tiny-scrollbar($width: 0.5rem);

    padding: 20px 10px;
    max-height: 60vh;
    overflow-y: auto;

    @include max-w(xs) {
      padding-left: 10px;
      padding-right: 10px;
      max-height: unset;
      flex-wrap: unset;
    }
  }
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  padding: 20px 0;

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
    width: 100%;
    padding: 20px 0;

    .controlButton {
      height: 64px;
    }

    .cancelButton {
      flex: 1 1 0;
    }

    @include max-w(xs) {
      flex-direction: column;
      gap: 20px;

      .controlButton {
        flex: unset;
        height: 64px;
        width: 100%;
        max-width: unset;
      }
    }
  }
}

.item {
  margin: 10px 5px;
  padding: 5px;
  justify-content: start;
  border-radius: 10px;

  &:hover {
    background: $gray-blue-light;
  }

  @include max-w(xs) {
    align-items: center;
    padding-bottom: 20px;
    width: 100%;
    gap: 20px;
  }

  .iconInner {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
    background: white;
    border-radius: 10px;
    margin-bottom: 10px;

    @include max-w(xs) {
      width: $mobile-item-icon-width;
      min-width: $mobile-item-icon-width;
      margin: 0;
    }

    @include make-responsive(
      'width',
      (
        xxl: 113px,
        xxxl: 140px,
      ),
      140px,
      px
    );

    @include make-responsive(
      'height',
      (
        xxl: 113px,
        xxxl: 140px,
      ),
      140px,
      px
    );
  }
}

@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  height: fit-content;
  z-index: 1;

  &.warning {
    fill: $orange-sun;
  }

  &.error {
    fill: $error-red;
  }
}

.content {
  padding: 10px;
  text-align: left;
  max-width: 222px;
  white-space: initial;
}

.message {
  &.warning {
    color: $tangerine;
  }

  &.error {
    color: $error-red;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

:root {
  @include make-responsive(
    '--select-btn-size',
    (
      xxl: 30px,
      xxxl: 50px,
    ),
    30px
  );
}

.boxItem {
  @include squared-element();

  color: $ebony;
  max-width: 243px;

  &:hover:not(.disabled) {
    .hint {
      transform: translateY(-3px) scale(1);
    }
  }

  &.customBoxBtn {
    .iconInner {
      background: transparent;
      border: 2px dashed $gray-blue;
    }
  }

  &.disabled {
    opacity: unset;
  }

  .selectBtn {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: var(--select-btn-size);
    height: var(--select-btn-size);
    border-radius: 50%;
    background: $deep-sky-blue;
    box-shadow: 0 0 20px rgba($midnight-blue, 0.2);
    transition: all 0.25s ease;

    &:before {
      content: '+';
      color: $white;
      font-size: var(--select-btn-size);
      position: absolute;
      font-weight: 300;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.25s ease;
    }

    &.selected {
      background: rgbCl($global-accent-color);

      &:before {
        content: '✓';
        font-size: calc(var(--select-btn-size) / 1.5);
      }

      &:hover {
        &:before {
          content: '-';
          color: $black;
        }
      }
    }
  }

  .hint {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    transition: transform 0.25s ease;
    transform: translateY(0) scale(0.9);
  }

  button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    text-align: unset;
  }

  .iconInner {
    flex-shrink: 0;
    box-shadow: 1px 1px 10px rgba($black, 0.05);

    .icon {
      width: 59%;
      height: 59%;
    }

    .customSendIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32%;
      height: 32%;
      transform: translate(-50%, -50%);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .title {
    @include primary-font();

    margin-bottom: 6px;
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @include max-w(xs) {
      white-space: nowrap;
    }
  }

  .description {
    @include secondary-font();

    color: $gray-suit;
    margin-bottom: 5px;
    height: 2.3em;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .price {
    @include card-title-font();

    @include max-w(xs) {
      font-size: 20px;
    }

    line-height: 1.15;
    display: flex;
    align-items: center;
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include max-w(xs) {
    max-width: 45vw;
  }
}

.priceWithControls {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @include max-w(xs) {
    flex-flow: column;
    justify-content: flex-start;
  }
}

.meta {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: fit-content;
  margin-left: auto;

  @include max-w(xs) {
    margin-top: 5px;
    flex-direction: row;
    margin-left: unset;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.edit {
  @include secondary-font;
  @include hover-underline($gray-light, 2px);

  cursor: pointer;
  color: $gray-light;

  @include max-w(md) {
    margin-top: 2px;
  }

  @include max-w(xs) {
    margin-top: 0;
    margin-right: 20px;
  }
}

.delete {
  @include secondary-font;
  @include hover-underline($error-red, 2px);

  cursor: pointer;
  margin-top: auto;
  color: $error-red;

  @include min-w(get-breakpoint-value(xs) + 1) {
    margin-bottom: 5px;
  }

  @include max-w(md) {
    margin-top: 24px;
  }

  @include max-w(xs) {
    margin-top: 0;
  }
}

.mskuIcon {
  position: absolute;
  bottom: 0.6rem;
  left: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  fill: $gray-blue;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    cursor: not-allowed;
  }
}

.price {
  position: relative;
  display: flex;
}

.currency {
  margin-right: 1px;
}

.amount {
  letter-spacing: 0.01em;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  flex-flow: column;

  .controls {
    display: flex;
  }

  .toggle {
    width: unset;
  }

  .datePicker {
    margin-left: 20px;
  }

  .dateInput {
    @include max-w(xs) {
      left: 50%;
    }
  }
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

:root {
  @include make-responsive(
    '--day-size',
    (
      xs: 30px,
      xxl: 28px,
      xxxl: 35px,
    ),
    30px
  );
}

.header {
  display: flex;
  justify-content: center;
  padding: 22px 0 18px;

  @include max-w(xxl) {
    padding: 15px 0;
  }

  @include max-w(xs) {
    padding: 20px 0 18px;
  }
}

.headerSelect {
  border: none;

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  .dropdownLabel {
    @include make-responsive(
      'font-size',
      (
        xs: 14px,
        xxl: 18px,
        xxxl: 21px,
      ),
      18px
    );

    color: $ebony;

    svg {
      transition: transform 0.25s ease;
      margin-left: 5px;
    }

    .headerOpened {
      transform: rotate(180deg);
      margin-bottom: 2px;
    }
  }

  .dropdownContent {
    background: #676669;
    width: auto;
  }

  .optionsContainer {
    display: flex;
    flex-flow: column;
  }

  .option {
    position: relative;
    color: $white;
    margin: 0.2em;
    padding: 0.2em 0.3em 0.2em 1.5em;
    justify-content: flex-start;

    &.selectedOption {
      background: #4e97e7;
      border-radius: 2px;

      &:before {
        content: '✓';
        color: $white;
        position: absolute;
        top: 50%;
        left: 3px;
        transform: translate(0, -50%);
      }
    }
  }
}

.picker {
  position: relative;
}

.calendar {
  border: none;
  display: flex;
}

.day {
  margin-bottom: -0.2em;
  position: relative;
  z-index: 2;
}

@import '../../variables';
@import '../../styles/common/mixins';

.dashboardWrapper {
  @include container();

  .information {
    flex: 1;
    display: flex;
    width: 100%;

    @include max-w(xl) {
      flex-flow: column;
    }

    .summaryList {
      display: flex;
      width: 77.5%;
      margin-right: 30px;
      transition: width 0.25s ease;

      @include max-w(xl) {
        width: 100%;
        margin-right: 0;
      }
    }

    .news {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 300px;

      @include max-w(xl) {
        margin-top: 30px;
      }
    }
  }

  .noSupport {
    flex: 1;
    display: flex;
    flex-direction: column;

    .label {
      color: $gray-light;
      font-size: 14px;
      margin-bottom: 50px;

      @include min-w(get-breakpoint-value(xs) + 1) {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
      }
    }

    .image {
      margin: 0 auto;
    }
  }
}

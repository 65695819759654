@import '../../../../variables.scss';
@import '../../../../styles/common/mixins';

.container {
  display: flex;
  flex-flow: column;
  position: relative;
}

.inputContainer {
  display: flex;
  gap: 5px;
}

.spinner {
  position: static;
}

.icon {
  align-self: center;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.input {
  font-size: 16px;
  padding: 0.4em 0.6em;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconImage {
  width: 30px;
  height: 30px;
  margin-right: 10px;

  @include max-w(xl) {
    width: 18px;
    height: 18px;
  }
}

.nameWrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.name {
  @include cropped-text(1);

  font-weight: 500;
}

.price {
  padding: 0 5px;
}

.dropdown {
  @include tiny-scrollbar(4px);

  display: none;
  overflow-y: auto;
  width: 100%;
  flex-flow: column;
  position: absolute;
  left: 0;
  top: 100%;
  transform: translate(0, 5px);
  box-shadow: $tooltip-shadow;
  border-radius: 8px;
  background-color: white;

  & .option:not(:last-child) {
    border-bottom: 1px solid $gray-blue-light;
  }

  &.expanded {
    display: flex;
    min-width: 250px;
  }

  .option {
    @include primary-font;

    margin: 0 0.3em;
    text-align: left;
    display: inline;
    padding: 1em 0.5em;
    box-shadow: $transparent-shadow;
    transition: all 0.25s ease;
    transform: scale(0.97);
    transform-origin: center center;

    @include max-w(xl) {
      padding: 0.7em 0.5em;
    }

    :not(.empty) {
      cursor: pointer;
    }

    &:not(.empty):hover {
      transform: scale(1);
      background: $bg-color;
      box-shadow: $input-shadow;
    }
  }
}

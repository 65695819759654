@import '../../styles/common/mixins';

.userInformation {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  .username {
    margin-left: 10px;
    font-weight: 600;
  }

  .border {
    border: 4.7px solid #698dbc;
  }

  .organization {
    font-size: 14px;
  }
}

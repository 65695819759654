@import '../../variables';

.textAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  color: white;
  background-color: #1dbeee;
  font-size: 26px;
  font-family: $ff-gilroy;

  span {
    margin-top: 5px;
  }
}

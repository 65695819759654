@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  z-index: 20;
}

.picker {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 95;
  transform: translate(-50%, 0);
  box-shadow: $tooltip-shadow;
  border-radius: 10px;
  background: $white;

  &.modal {
    position: static;
    transform: none;
  }
}

.calendarsPanel {
  display: flex;
  position: relative;
}

.selected {
  color: $white;

  &:hover {
    color: $ebony;

    &:after {
      background: $deep-sky-blue !important;
    }
  }

  &:after {
    background: $deep-sky-blue;
  }
}

.controls {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $gray-aero;

  @include max-w(lg) {
    padding: 8px 10px 20px;
  }
}

.controlBtn {
  max-width: 137px;
  border-radius: 10px;

  &:not(:first-of-type) {
    margin-left: 10px;
  }

  @include max-w(lg) {
    max-width: 101px;
  }
}

@import '../../variables';
@import '../../styles/common/mixins';

:root {
  --grid-organizations-per-row: 3;
  --grid-organizations-margin: 0.75vw;

  @include max-w(xxxl) {
    --grid-organizations-per-row: 3;
  }
  @include max-w(xl) {
    --grid-organizations-per-row: 2;
  }
  @include max-w(md) {
    --grid-organizations-per-row: 1;
  }
}

.container {
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-flow: column;
}

.header {
  @include container();

  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 0;

  @include max-w(xxl) {
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  @include max-w(md) {
    flex-direction: column;
    align-items: flex-start;

    & > :not(:last-child) {
      margin-bottom: 10px;
    }
  }

  @include max-w(xs) {
    margin-bottom: 10px;
  }

  .filtersWrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 10px;

    @include max-w(xs) {
      flex-direction: column;
      margin-right: 0;
    }

    .filter {
      display: flex;
    }

    .search {
      width: 400px;
      margin-left: auto;

      @include max-w(xxl) {
        width: max-content;
      }

      @include max-w(md) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.addButton {
  margin-left: auto;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-left: 0;
    max-width: 100%;
  }
}

.listWrapper {
  @include tiny-scrollbar();

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    height: 9px;
  }

  width: 100%;
  margin-bottom: 20px;

  @include min-w(get-breakpoint-value(xs) + 1) {
    overflow-x: auto;
  }

  .list {
    @include container();
    @include fluidRow($margin: var(--grid-organizations-margin));

    @include max-w(xxl) {
      padding: 0 20px;
      margin: 0;
    }

    @include max-w(xs) {
      right: 0;
      padding: 0 10px;
    }

    .card {
      @include fluidCell($perRow: var(--grid-organizations-per-row), $margin: var(--grid-organizations-margin));

      justify-content: space-between;

      @include max-w(xxl) {
        margin-bottom: 10px;
      }

      @include max-w(xl) {
        max-width: 620px;
      }

      .logo {
        img {
          max-width: 50%;
          max-height: 45px;

          @include max-w(xs) {
            max-height: 30px;
          }
        }
      }

      .organizationName {
        @include card-title-font();
      }

      .controlsWrapper {
        @include max-w(xs) {
          align-items: flex-end;
          flex-flow: row-reverse;
        }

        .controls {
          display: flex;
          flex-flow: row;

          & > :not(:last-child) {
            margin-right: 30px;

            @include max-w(xs) {
              margin-right: 10px;
            }
          }
        }

        .actionBtn {
          @include secondary-font();
          @include hover-underline($gray-light, 2px);

          color: $gray-light;

          @include max-w(xs) {
            &::after {
              content: none;
            }
          }

          &.activate {
            @include hover-underline($budget-green, 2px);

            color: $budget-green;
          }

          &.deactivate {
            @include hover-underline($error-red, 2px);

            color: $error-red;
          }

          &.department {
            width: auto;
            height: auto;

            @include min-w(get-breakpoint-value(xs) + 1) {
              margin-left: auto;
            }

            @include max-w(xs) {
              margin-right: auto;
            }
          }
        }

        .toggle {
          width: 32px;
          height: 32px;
          border-radius: 4px;

          &.activate {
            background-color: $budget-green;
          }
          &.deactivate {
            background-color: $error-red;
          }

          label {
            width: 16px;
            height: 8px;
            margin: 0 auto;

            input {
              &:checked + .slider {
                cursor: default;

                &:before {
                  background-color: $budget-green;
                  transform: translateX(8px);
                }
              }
            }
          }

          .slider {
            width: 16px;
            height: 8px;

            &:before {
              height: 6px;
              width: 6px;
              left: 1px;
              bottom: 1px;
              background-color: $error-red;
              transform: inherit;
            }
          }
        }
      }

      .cardTopContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .actionBtn {
          @include secondary-font();

          max-width: 8rem;
          padding: 8px 0;
        }
      }
    }
  }
}

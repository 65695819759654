@import '../../variables';
@import '../../styles/common/mixins';

.container {
  @include tiny-scrollbar();
  @include container();

  flex-direction: column;
  padding: 20px 20px 20px 90px;
  overflow-y: auto;
  height: calc(100vh - 128px);
  width: 100vw;

  @include max-w(xs) {
    height: calc(100vh - 198px);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @include max-w(xs) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

.bucketTotal {
  align-self: flex-end;
}

.heading {
  @include primary-font();

  font-weight: 600;
}

.list {
  margin-bottom: auto;
}

.categoryTitle {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin: 20px;

  span {
    font-weight: normal;
  }
}

.categoryContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.continueButton {
  margin: auto 0 10px auto;

  @include max-w(xs) {
    margin: 10px auto;
  }
}

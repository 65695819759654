@import '../../variables';
@import '../../styles/common/mixins';

$table-min-height: 135px;
$table-max-height: $table-min-height + 190px;

.container {
  margin-top: 10px;
}

.header {
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 0;
  justify-content: space-between;
  max-width: 1300px;

  @include max-w(xl) {
    padding-bottom: 0;
  }

  @include max-w(md) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include max-w(xs) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.addButton {
  margin-right: 0;
  margin-bottom: 5px;
  max-width: 150px;

  @include min-max-w(get-breakpoint-value(xs) + 1, md) {
    margin-left: 0;
    max-width: 100%;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  & h2 {
    font-size: 32px;
    font-weight: 600;

    @include max-w(xs) {
      font-size: 24px;
    }
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    color: $gray-suit;

    @include max-w(md) {
      margin-bottom: 10px;
    }
  }
}

.statusWrapper {
  display: flex;
  align-items: center;
}

.statusBullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.secretInput input {
  padding: 0 50px 0 5px;
  font-size: 14px;
}

.tableBody {
  max-height: $table-max-height;
}

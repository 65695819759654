@import '../../variables';
@import '../../styles/common/mixins';

.container {
  position: relative;
  width: 100%;
  padding: 50px 10px 0 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include max-w(md) {
    flex-flow: column;
    padding: 0 10px 25px 10px;
  }

  @include min-w(get-breakpoint-value(xxl) + 1) {
    padding: 20px 55px 20px 140px;
  }
}

.title {
  @include primary-font();

  color: $gray-suit;
  margin-bottom: 10px;

  @include max-w(xs) {
    font-size: 14px;
  }
}

.upload {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 49%;

  @include max-w(md) {
    max-width: inherit;
    margin-bottom: 40px;
  }
}

.result {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 49%;

  @include max-w(md) {
    max-width: inherit;
  }
}

.uploadField {
  width: 100%;
  height: 270px;
  margin-bottom: 30px;

  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      margin-bottom: 30px;

      @include max-w(xs) {
        max-width: 41px;
      }
    }
  }
}

.clearIcon {
  @include close-btn;
}

.checkbox {
  margin-bottom: 0;
}

.actions {
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  margin-top: 20px;
  gap: 30px;

  @include max-w(md) {
    flex-flow: column;
    align-items: flex-start;
  }
}

.btn {
  @include max-w(md) {
    align-self: center;
    max-width: inherit;
    margin-top: 30px;
  }
}

.optionsList {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  .label {
    @include primary-font(
      (
        xs: 16px,
        xxl: 20px,
        xxxl: 24px,
      )
    );

    margin-top: 30px;
    font-weight: 500;

    @include max-w(xs) {
      display: none;
    }
  }
}

.exampleLink {
  .toggle {
    min-width: unset;
    min-height: unset;
    width: 15px;
    height: 15px;
  }
}

.selectorContainer {
  width: 100%;
}

.wrapper {
  gap: 30px;
  display: flex;
  width: 100%;

  @include max-w(xs) {
    flex-flow: column;
  }
}

.text {
  @include secondary-font;

  width: 100%;
  margin: 0 10px;
}

.toggleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  & :nth-child(2) > div:first-child {
    justify-content: center;

    @include max-w(md) {
      padding: 0 10px;
    }
  }
}

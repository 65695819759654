@import '../../styles/common/mixins';
@import '../../variables';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 81;
  padding: 40px 70px 30px 70px;
  font-size: 18px;
  line-height: 1.15;
  box-shadow: 0 4px 10px rgba(219, 222, 235, 0.8);

  @include min-w(get-breakpoint-value(xxl) + 1) {
    display: flex;
    align-items: stretch;
    padding: 14px 50px 14px 135px;
    height: min(80px);
  }

  @include max-w(xxl) {
    padding: 25px 30px 15px 40px;
  }

  @include max-w(md) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include max-w(xs) {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 20px 10px 15px;
  }
}

.information {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  @include max-w(xs) {
    width: 100%;
  }

  & + .controls {
    @include make-responsive(
      'margin-left',
      (
        xl: 20px,
        xxl: 30px,
        xxxl: 40px,
      ),
      40px
    );
  }

  .titleWrapper {
    display: flex;
    align-items: baseline;
    margin-right: auto;

    @include min-w(get-breakpoint-value(xxl) + 1) {
      margin-top: 6px;
    }

    .back {
      margin-right: 20px;
      margin-bottom: 6px;
    }

    .title {
      @include heading-font();

      line-height: 1.15;
      font-weight: 600;
    }
  }
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

@import '../../../../variables';
@import '../../../../styles/common/mixins';

.container {
  position: absolute;
  background-color: $error-red;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  z-index: inherit;

  // TODO: uncomment to add hover on desktop screens
  //@include min-w(get-breakpoint-value(xs) + 1) {
  //  opacity: 0.3;
  //
  //  &:hover {
  //    opacity: 1;
  //  }
  //}

  &.xs {
    height: 20px;
    width: 20px;

    &:before,
    &:after {
      height: 8px;
      width: 1px;
    }
  }

  &.l {
    height: 15px;
    width: 15px;

    &:before,
    &:after {
      height: 7px;
      width: 1px;
    }
  }

  &.xl {
    height: 32px;
    width: 32px;

    &:before,
    &:after {
      height: 10px;
      width: 2px;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    background-color: $white;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@import '../../../styles/common/mixins';
@import '../../../variables';

.container {
  @include make-responsive(
    'padding-top',
    (
      xxl: 40px,
      xxxl: 60px,
    ),
    60px
  );
  @include make-responsive(
    'padding-bottom',
    (
      xxl: 40px,
      xxxl: 60px,
    ),
    60px
  );
  @include make-responsive(
    'padding-right',
    (
      xs: 16px,
      xxl: 30px,
      xxxl: 40px,
    ),
    40px
  );
  @include make-responsive(
    'padding-left',
    (
      xs: 16px,
      xxl: 30px,
      xxxl: 40px,
    ),
    40px
  );

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin: 0;

  .closeBtn {
    @include make-responsive(
      'top',
      (
        xs: 20px,
        xxl: 30px,
        xxxl: 40px,
      ),
      40px
    );
    @include make-responsive(
      'right',
      (
        xs: 20px,
        xxl: 30px,
        xxxl: 40px,
      ),
      40px
    );

    position: absolute;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
}

.content {
  @include tiny-scrollbar;
  @include make-responsive(
    'margin-bottom',
    (
      xxl: 20px,
      xxxl: 30px,
    ),
    30px
  );

  flex: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-y: auto;
  color: $midnight-blue;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }

  .icon {
    @include make-responsive(
      'width',
      (
        xxl: 90px,
        xxxl: 120px,
      ),
      120px
    );

    @include make-responsive(
      'height',
      (
        xxl: 60px,
        xxxl: 80px,
      ),
      80px
    );

    @include max-w(xs) {
      margin-bottom: 10px;
    }
  }

  .title {
    @include make-responsive(
      'font-size',
      (
        xxl: 30px,
        xxxl: 48px,
      ),
      48px
    );

    font-weight: 500;
    line-height: 1.15;
  }

  .description {
    @include make-responsive(
      'font-size',
      (
        xxl: 18px,
        xxxl: 21px,
      ),
      21px
    );

    line-height: 1.15;
    text-align: center;

    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 20px;
  }
}

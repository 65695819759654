@import '../../../variables';
@import '../../../styles/common/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 450px;
  background: $bg-color;
  padding: 60px 0;
  overflow-y: auto;

  @include max-w(md) {
    padding: 30px 0;
    min-height: 350px;
  }

  @include max-w(xs) {
    padding: 15px 0;
  }

  @include max-landscape-h(xs) {
    padding: 15px 0 10px;
  }
}

.header,
.content,
.footer {
  padding: 0 30px 0 50px;
}

.header {
  margin: 0;

  .backBtn {
    margin-right: 20px;
    margin-bottom: 4px;
  }
}

.content {
  @include tiny-scrollbar;

  overflow-y: auto;
  flex: 1;
  display: flex;
  padding-top: 40px;
  padding-bottom: 30px;

  @include max-w(xs) {
    flex-direction: column;
    padding: 13px;
  }

  .loader {
    background-color: transparent;
  }

  .uploadedControls {
    width: 33%;
    margin-right: 20px;

    @include max-w(xs) {
      width: 100%;
      margin: 0 0 10px;
    }

    .uploadField {
      border: unset;

      .uploadBtn {
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          height: 25px;
          width: 25px;
          margin-right: 10px;
          margin-bottom: 0;

          path {
            fill: $midnight-blue;
          }
        }

        &:disabled {
          .icon path {
            fill: #dadfe9;
          }
        }
      }
    }

    .imgPreview {
      .imgItem {
        margin: 20px 0;
      }

      .delete {
        @include primary-font();

        margin-top: 10px;
        color: $error-red;
      }
    }
  }

  .list {
    @include tiny-scrollbar;

    overflow-y: auto;
    flex: 1;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    transition: all 0.25s ease;

    &.loading {
      overflow-y: unset;
    }

    @include max-w(xs) {
      @include fluidRow;
    }

    .imgItem {
      margin: 0 20px 20px 0;
      cursor: pointer;

      @include max-w(xs) {
        @include fluidCell;
        height: unset;
      }

      &.selectedImage {
        border: 1px solid rgbCl($global-accent-color);
      }
    }

    .noItems {
      font-size: 16px;
      color: $gray-suit;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconInner {
  position: relative;
  height: 140px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 10px;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .icon {
    position: absolute;
    width: 70%;
    height: 70%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@import '../../styles/common/mixins';
@import '../../variables';

.container {
  flex: 1 1 0;
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  position: relative;
}

.label {
  font-size: 16px;

  :global {
    span {
      font-size: 16px;

      @include max-w(xs) {
        font-size: 14px;
      }
    }
  }
}

.table {
  border: 1px solid $gray-table;
  border-radius: 10px;
  flex: 1 1 0;
  display: flex;
  flex-flow: column;
  overflow: auto;
}

.addButton {
  width: 32px;
  padding: 5px;
  border-radius: 6px;
}

.head {
  display: flex;
  height: 55px;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid $gray-table;
  gap: 20px;
  position: relative;

  @include max-w(xs) {
    height: 40px;
  }

  .headElement {
    flex: 1;
    font-size: 16px;
    position: static;
  }
}

.title {
  @include secondary-font;

  font-weight: 600;
}

.body {
  @include tiny-scrollbar($width: 4px, $thumb-color: $gray-table, $track-color: transparent);

  flex: 1;
  flex-basis: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 180px;
}

.row {
  @include primary-font();

  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  @include max-w(xs) {
    min-height: 50px;
  }
}

.placeholderRow {
  @include skeleton-placeholder;

  margin: 5px 20px;
  padding: 0;
  border-radius: 6px;
  min-height: 55px;

  @include max-w(xs) {
    min-height: 40px;
  }
}

.placeholder {
  line-height: 170px;
  margin: 0 auto;
}

.error {
  position: absolute;
  top: 100%;
  color: $error-red;
  margin-top: 0.5em;
}

.tooltip {
  margin-left: 5px;
}

.errorBorder {
  border: 1px solid $error-red;
}

@import '../../styles/common/mixins';
@import '../../variables';

.userBudget {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 15px;

    @include max-w(xs) {
      margin-right: 10px;
    }
  }

  .iconWrapper {
    position: relative;

    .icon > * {
      fill: $ebony;
    }

    .alert {
      position: absolute;
      height: 10px;
      width: 10px;
      right: -5px;
      bottom: 3px;
      border: 1px solid $white;
      border-radius: 50%;
    }
  }

  .info {
    @include secondary-font;

    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.15;

    .currency {
      font-weight: 600;

      &.warning {
        color: $error-red;
      }
    }
  }
}
